import { FormEvent, useContext } from "react"
import { useLocalObservable, inject, observer } from "mobx-react"
import { getSnapshot } from "mobx-state-tree"
import { pick, pickBy } from "lodash"
import { useNavigate } from "react-router"

import {
  IconId,
  IconLocation,
  IconFirstName,
  IconLastName,
  IconWorkplace,
} from "../../icons"
import { useFormRequest } from "../../hooks"
import PdfConditionsOfUse from "../../assets/docs/expert-conditions-of-use.pdf"
import Img from "../../assets/img/img_earcloud-devices.png"
import { TitleField } from "../../components/FormFields"
import {
  Link,
  ModelForm,
  ModelFormField,
  Input,
  Card,
  CardHeading,
  Heading,
  LoadingButton,
  Subheading,
} from "../../components"

import FeatureList from "./FeatureList"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const defaults = {
  title: "",
  first_name: "",
  last_name: "",
  professional_id: "",
  work_place: "",
  city: "",
  agreement: "",
}

const BecomeAnExpertScene = () => {
  const { user, fetchMe, users, toasts } = useContext(RootStoreContext)!

  const navigate = useNavigate()
  const store = useLocalObservable(() => ({
    model: {
      ...defaults,
      ...pickBy(
        pick(getSnapshot(user.user_profile), Object.keys(defaults)),
      ),
    },
    get canSubmit() {
      return store.model.agreement.toLocaleUpperCase() === "I AGREE"
    },
  }))

  const request = useFormRequest(
    async () => {
      navigate("/dashboard")
      toasts.show("You are now an expert.")
      await fetchMe()
      await user.user_profile.fetch()
    },
    (err: any) => {
      if (err) {
        toasts.show(err.message, { accent: "danger" })
      } else {
        toasts.show("An error occurred while upgrading your account.", {
          accent: "danger",
        })
      }
    },
  )

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    request.perform(users.upgrade(user.id, store.model))
  }

  return (
    <>
      <Heading>Become an expert</Heading>

      <div className="grid md:grid-cols-2 gap-12">
        <Card className="p-8">
          <ModelForm
            model={store.model}
            errors={request.errors}
            onSubmit={onSubmit}
          >
            <CardHeading className="mb-4">Identification</CardHeading>
            <ModelFormField name="title">
              <TitleField />
            </ModelFormField>

            <ModelFormField name="first_name">
              <Input
                type="text"
                autoComplete="given-name"
                icon={<IconFirstName />}
              />
            </ModelFormField>

            <ModelFormField name="last_name">
              <Input
                type="text"
                autoComplete="family-name"
                icon={<IconLastName />}
              />
            </ModelFormField>

            <CardHeading className="mb-4">Professional ID Number</CardHeading>

            <ModelFormField name="professional_id">
              <Input icon={<IconId />} />
            </ModelFormField>

            <CardHeading className="mb-4">Workplace</CardHeading>

            <ModelFormField name="work_place">
              <Input
                autoComplete="organization"
                placeholder="Workplace"
                icon={<IconWorkplace />}
              />
            </ModelFormField>

            <ModelFormField name="city" className="mb-8">
              <Input type="text" placeholder="City" icon={<IconLocation />} />
            </ModelFormField>

            <ModelFormField
              name="agreement"
              className="mb-8"
              label={
                <span>
                  If you agree to the{" "}
                  <Link href={PdfConditionsOfUse} target="_blank">
                    conditions of use
                  </Link>{" "}
                  and have read the{" "}
                  <Link
                    href="https://www.jacoti.com/support/listenapp/Jacoti_ListenApp_ExpertManual.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    expert manual
                  </Link>
                  , please type <span className="font-medium">"I AGREE"</span>{" "}
                  in the box below
                </span>
              }
            >
              <Input type="text" />
            </ModelFormField>

            <LoadingButton
              isLoading={request.isLoading}
              disabled={!store.canSubmit}
            >
              Upgrade Account
            </LoadingButton>
          </ModelForm>
        </Card>

        <div>
          <Subheading className="text-xl">
            What you can do as a Hearing Expert:
          </Subheading>

          <FeatureList className="leading-loose" />

          <img src={Img} />
        </div>
      </div>
    </>
  )
}

export default observer(BecomeAnExpertScene)
