import { useRef } from "react"
import { AxisBottom, AxisLeft } from "@visx/axis"
import { Group } from "@visx/group"
import { LinePath } from "@visx/shape"
import { Point } from "@visx/point"
import { scaleLinear } from "@visx/scale"

import { MAX_KP1, MAX_KP2, MIN_KP1, MIN_KP2 } from "../../models/GainFunction"
import { getRelativeSVGPosition } from "../../support/plotting"
import { formatFrequency } from "../../support/formatting"
import ReferenceLine from "../Audiogram/ReferenceLine"
import ResponsiveSVG from "../ResponsiveSVG"

import Handle from "./Handle"
import { Margin } from "../../support/types"

type AGCProps = {
  width?: number
  height?: number
  margin?: Margin
  primaryColor?: string
  lineColor?: string
  xDomain?: [number, number]
  yDomain?: [number, number]
  kneepoint1: number
  kneepoint2: number
  compressionRatio: number
  expansionRatio: number
  onChange: (prop: string, value: number) => void
}

const AGC = ({
  width = 400,
  height = 400,
  margin = {
    top: 10,
    left: 60,
    right: 10,
    bottom: 40,
  },
  lineColor = "#b9bbbd",
  primaryColor = "#292929",
  xDomain = [MIN_KP1, MAX_KP1],
  yDomain = [MIN_KP2, MAX_KP2],
  kneepoint1,
  kneepoint2,
  compressionRatio,
  expansionRatio,
 onChange,
}: AGCProps) => {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const x = (d: number[]) => d[0]
  const y = (d: number[]) => d[1]

  const xScale = scaleLinear({
    domain: xDomain,
    range: [0, xMax],
  })

  const yScale = scaleLinear({
    domain: yDomain,
    range: [yMax, 0],
  })

  const data = [
    [(xDomain[0] - kneepoint1) / expansionRatio + kneepoint1, yDomain[0]],
    [kneepoint1, kneepoint1],
    [kneepoint2, kneepoint2],
    [xDomain[1], (yDomain[1] - kneepoint2) / compressionRatio + kneepoint2],
  ]

  const groupRef = useRef<SVGGElement | null>()

  return (
    <ResponsiveSVG className="agc" width={width} height={height}>
      <AxisLeft
        scale={yScale}
        top={margin.top}
        left={margin.left}
        label="Output Gain [dB]"
        labelProps={{
          fill: lineColor,
          textAnchor: "middle",
          fontFamily: "inherit",
          fontSize: 10,
        }}
        stroke={lineColor}
        tickStroke={lineColor}
        tickLabelProps={() => ({
          dx: -4,
          fontFamily: "inherit",
          fontSize: 8,
          textAnchor: "end",
          verticalAnchor: "middle",
          fill: lineColor,
        })}
      />

      <AxisBottom
        scale={xScale}
        top={height - margin.bottom}
        left={margin.left}
        label="Input Gain [dB]"
        labelProps={{
          fill: lineColor,
          textAnchor: "middle",
          fontFamily: "inherit",
          fontSize: 10,
        }}
        stroke={lineColor}
        tickFormat={(value) => formatFrequency(value as any)}
        tickStroke={lineColor}
        tickLabelProps={() => ({
          dy: "0.25em",
          fontFamily: "inherit",
          fontSize: 8,
          textAnchor: "middle",
          fill: lineColor,
        })}
      />

      <Group
        top={margin.top}
        left={margin.left}
        innerRef={(ref) => (groupRef.current = ref)}
      >
        {xScale.ticks().map((tick) => (
          <ReferenceLine
            key={tick}
            from={
              new Point({
                x: xScale(tick),
                y: 0,
              })
            }
            to={
              new Point({
                x: xScale(tick),
                y: yMax,
              })
            }
            strokeDasharray="3,2"
          />
        ))}
        {yScale.ticks().map((tick) => (
          <ReferenceLine
            key={tick}
            from={
              new Point({
                x: 0,
                y: yScale(tick),
              })
            }
            to={
              new Point({
                x: xMax,
                y: yScale(tick),
              })
            }
            strokeDasharray="3,2"
          />
        ))}

        <LinePath
          x={(d) => xScale(x(d))}
          y={(d) => yScale(y(d))}
          data={[
            [xDomain[0], yDomain[0]],
            [xDomain[1], yDomain[1]],
          ]}
          stroke={lineColor}
        />

        <LinePath
          x={(d) => xScale(x(d))}
          y={(d) => yScale(y(d))}
          data={data}
          stroke={primaryColor}
        />

        <g>
          {data.map(
            (d, i) =>
              i > 0 &&
              i < 3 && (
                <Handle
                  key={i}
                  color={primaryColor}
                  x={xScale(x(d))}
                  y={yScale(y(d))}
                  onDragMove={(e) => {
                    if (!groupRef.current) return
                    const position = getRelativeSVGPosition(
                      groupRef.current,
                      e as MouseEvent,
                    )
                    onChange(`kneepoint${i}`, xScale.invert(position.x))
                  }}
                />
              ),
          )}
        </g>
      </Group>
    </ResponsiveSVG>
  )
}

export default AGC
