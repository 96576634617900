import { Instance, types as t } from "mobx-state-tree"

import { AGC } from "./AGC"
import { Equalizer } from "./Equalizer"

export const Program = t
  .model("Program", {
    name: t.string,
    fittingRule: t.string,
    equalizer: Equalizer,
    agc: AGC,
  })
  .actions((self) => ({
    setFittingRule(value: string) {
      self.fittingRule = value
    },
  }))

export interface IProgram extends Instance<typeof Program> {}
