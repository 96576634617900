import { FormEvent, useContext } from "react"
import { observer, useLocalObservable } from "mobx-react"
import { useNavigate } from "react-router-dom"

import { IconEmail, IconPassword } from "../../icons"
import { useFormRequest } from "../../hooks"
import {
  Input,
  Link,
  LoadingButton,
  ModelForm,
  ModelFormField,
} from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const LoginForm = () => {
  const { login, toasts } = useContext(RootStoreContext)!

  const navigate = useNavigate()

  const store = useLocalObservable(() => ({
    model: {
      username: "",
      password: "",
    },
  }))

  const request = useFormRequest(
    () => {
      navigate("/dashboard", { replace: true })
    },
    (err: any) => {
      if (err) {
        toasts.show(err.message, { accent: "danger" })
      } else {
        toasts.show("An unknown error occurred, please try again.", {
          accent: "danger",
        })
      }
    },
  )

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    request.perform(login(store.model))
  }

  return (
    <ModelForm
      model={store.model}
      errors={request.errors}
      onSubmit={onSubmit}
      noValidate
    >
      <ModelFormField name="username">
        <Input type="email" icon={<IconEmail />} placeholder="Email" />
      </ModelFormField>
      <ModelFormField name="password">
        <Input type="password" icon={<IconPassword />} placeholder="Password" />
      </ModelFormField>

      <div className="flex justify-center mb-8 mt-8">
        <LoadingButton isLoading={request.isLoading} className="w-full">
          SIGN IN WITH EMAIL
        </LoadingButton>
      </div>

      <div className="mb-8 text-center">
        <Link to="/forgot-password">Forgot password?</Link>
      </div>
    </ModelForm>
  )
}

export default observer(LoginForm)
