import { useContext } from "react"
import { observer } from "mobx-react"

import { IconPassword } from "../icons"

import Input from "./Input"
import PasswordStrength from "./PasswordStrength"
import ModelFormField, { ModelFormProps } from "./ModelFormField"
import ModelFormContext from "./ModelFormContext"

type PasswordModelFormFieldProps = {
  name: string
} & ModelFormProps

const PasswordModelFormField = ({
  label,
  name,
  ...props
}: PasswordModelFormFieldProps) => {
  const { model } = useContext(ModelFormContext)
  return (
    <ModelFormField
      name={name}
      label={
        <div className="flex items-center">
          <div className="mr-8">{label}</div>
          <PasswordStrength value={model[name]} />
        </div>
      }
      {...props}
    >
      <Input type="password" icon={<IconPassword />} />
    </ModelFormField>
  )
}

export default observer(PasswordModelFormField)
