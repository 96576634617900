import { types as t, flow, getRoot, Instance } from "mobx-state-tree"

import { Audiogram } from "../models/Audiogram"
import { fromHKAudiogram } from "../models/Audiogram"
import { RootStore } from "./RootStore"

export const UserProfileAudiogramStore = t
  .model("UserProfileAudiogramStore", {
    audiograms: t.optional(t.map(Audiogram), {}),
  })
  .views((self) => ({
    get(id: number | string) {
      return self.audiograms.get(id.toString())
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      fetch: flow(function* (id) {
        const res = yield rootStore.client.get(
          `/user_profiles/${id}/audiogram/`,
        )
        self.audiograms.set(id.toString(), fromHKAudiogram(res.data.audiogram))
      }),
    }
  })

export default UserProfileAudiogramStore
