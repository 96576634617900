import pkg from "../../../package.json"
import AudiogramChannel from "./AudiogramChannel"

import { useContext } from "react"
import { v4 as uuid } from "uuid"
import { useNavigate } from "react-router-dom"
import { ObservableMap } from "mobx"
import { observer, useLocalObservable } from "mobx-react"
import { flatten } from "lodash"
import { Selection } from "../../components/Audiogram"

import { toHKDate } from "../../support/formatting"
import { useFormRequest } from "../../hooks"
import {
  Button,
  LoadingButton,
  Heading,
  Card,
  FloatingToolbar,
} from "../../components"

import { IUserProfile } from "../../models/UserProfile"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import {
  toHKThreshold,
  toMeasurementArray,
  toMeasurementMap,
} from "../../models/Measurement"
import { HKMeasurement } from "../../models/Audiogram"
import { IMeasurementSession } from "../../models/MeasurementSession"
import { IMeasurementLabels } from "../../models/MeasurementData"

type MeasurementSessionPayload = Pick<IMeasurementSession, "data"> & {
  data: { measurements: HKMeasurement[] }
}

const NewAudiogram = ({ userProfile }: { userProfile: IUserProfile }) => {
  const { client, toasts, measurementSessions, userProfiles } =
    useContext(RootStoreContext)!

  const navigate = useNavigate()
  const store = useLocalObservable(() => ({
    selection: null as Selection,
    data: {
      left: toMeasurementMap(userProfile.audiogram.left),
      right: toMeasurementMap(userProfile.audiogram.right),
    },
    reset() {
      Object.assign(store.data, {
        left: toMeasurementMap(userProfile.audiogram.left),
        right: toMeasurementMap(userProfile.audiogram.right),
      })
    },
    clear() {
      Object.assign(store.data, {
        left: new ObservableMap(),
        right: new ObservableMap(),
      })
    },
    get measurements(): HKMeasurement[] {
      return flatten(
        ["left", "right"].map((channel) =>
          toMeasurementArray(
            store.data[channel as keyof typeof store.data],
          ).map((m) => ({
            datetime: toHKDate(new Date()),
            ear: channel,
            type: "hearingthreshold",
            frequency: m.frequency,
            threshold: toHKThreshold(m),
          })),
        ),
      )
    },
    get payload(): MeasurementSessionPayload[] {
      return [
        {
          data: {
            uuid: uuid(),
            labels: ["ManualInput"] as IMeasurementLabels,
            source: ["myJacoti", pkg.version].join("/"),
            measurements: store.measurements,
          },
        },
      ]
    },
    get canSave() {
      return ["left", "right"].every(
        (channel) => store.data[channel as keyof typeof store.data].size >= 1,
      )
    },
  }))

  const request = useFormRequest(
    async () => {
      await Promise.all([
        userProfiles.fetch(userProfile.id),
        userProfiles.audiograms.fetch(userProfile.id),
        measurementSessions.fetchAllByUserProfileId(userProfile.id),
      ])
      navigate(`/patients/${userProfile.id}/`)
      toasts.show("Manual input successfully submitted.")
    },
    () => {
      toasts.show("An error occurred while submitting the request.", {
        accent: "danger",
      })
    },
  )

  const onSubmit = async () => {
    request.perform(
      client.post(
        `/user_profiles/${userProfile.id}/measurement_sessions/`,
        store.payload,
      ),
    )
  }

  return (
    <div className="mb-20">
      <Heading>Manual Input</Heading>
      <Card className="flex p-8">
        <AudiogramChannel
          title="Right Ear"
          channel="right"
          data={store.data}
          selection={store.selection}
          onSelect={(selection) => {
            store.selection = selection
          }}
        />

        <AudiogramChannel
          title="Left Ear"
          channel="left"
          data={store.data}
          selection={store.selection}
          onSelect={(selection) => {
            store.selection = selection
          }}
        />
      </Card>
      <FloatingToolbar>
        <div className="flex space-x-4">
          <Button
            outline
            shape="rect"
            accent="danger"
            className="px-16"
            onClick={() => {
              navigate(`/patients/${userProfile.id}/`)
            }}
          >
            Cancel
          </Button>
        </div>

        <div className="flex space-x-4">
          <Button outline shape="rect" className="px-16" onClick={store.clear}>
            Clear
          </Button>
          <Button outline shape="rect" className="px-16" onClick={store.reset}>
            Reset
          </Button>

          <LoadingButton
            className="px-16"
            shape="rect"
            disabled={!store.canSave}
            isLoading={request.isLoading}
            onClick={onSubmit}
          >
            Save
          </LoadingButton>
        </div>
      </FloatingToolbar>
    </div>
  )
}

export default observer(NewAudiogram)
