import { observer } from "mobx-react"
import { ComponentPropsWithoutRef } from "react"

import Draggable, { DraggableProps } from "../../../../components/Draggable"

type HandleProps = {
  color: string
  x: number
  y: number
  selected: boolean
} & DraggableProps &
  ComponentPropsWithoutRef<"g">

const Handle = ({
  onDragStart,
  onDragMove,
  onDragEnd,
  x,
  y,
  color,
  selected,
  ...props
}: HandleProps) => (
  <Draggable
    {...{
      onDragStart,
      onDragMove,
      onDragEnd,
    }}
  >
    {selected ? (
      <g {...props}>
        <circle cx={x} cy={y} fill={color} r={3} />
        <circle cx={x} cy={y} fill="none" stroke={color} r={5} />
      </g>
    ) : (
      <circle cx={x} cy={y} fill={color} r={3} {...props} />
    )}
  </Draggable>
)

export default observer(Handle)
