import { AppStoreButton } from "../../components"
import ImgLogin from "../../assets/img/img_login-screen.jpg"

import AppItemList from "./AppItemList"

const UserJumbotron = () => (
  <div className="flex flex-auto flex-col">
    <div
      className="flex-auto p-12 bg-bondi"
      style={{
        backgroundImage: `url(${ImgLogin})`,
        backgroundSize: "cover",
      }}
    >
      <h1 className="text-center lg:text-left lg:text-5xl">
        Linking users <br /> with hearing experts
      </h1>
    </div>

    <div className="flex flex-col items-center p-8 bg-bondi">
      <h3 className="mb-8 font-medium text-xl">
        By creating a Jacoti Account you have access to:
      </h3>
      <AppItemList />
      <AppStoreButton href="https://itunes.apple.com/us/developer/jacoti-bvba/id521727239?mt=8" />
    </div>
  </div>
)

export default UserJumbotron
