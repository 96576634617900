import { useRef } from "react"
import { BarStack } from "@visx/shape"
import { scaleBand, scaleLinear } from "@visx/scale"
import { AxisLeft, AxisBottom } from "@visx/axis"
import { Group } from "@visx/group"
import { countBy } from "lodash"
import { GridRows } from "@visx/grid"

import { Gender } from "../../models/Gender"
import { AgeRanges, findAgeRange } from "../../models/AgeRange"
import { useHover } from "../../hooks"
import { getSVGPointAsFloatingRef } from "../../support/plotting"

import { Popover, ResponsiveSVG } from "../../components"
import { getColor, getName } from "../../models/Gender"
import { Color, Margin } from "../../support/types"
import { BarGroupBar, SeriesPoint } from "@visx/shape/lib/types"

type AgeGenderDatum = { age: number; gender: string }

type AgeDistByGenderDatum = {
  key: string
  U: number
  M: number
  F: number
}

type AgeDistByGender = { [range: string]: AgeGenderDatum[] }

const groupByAgeRange = (ranges: string[], ages: AgeGenderDatum[]) => {
  const rangeMap = ranges.reduce<AgeDistByGender>(
    (result, range) => ({ ...result, [range]: [] }),
    {},
  )

  const data = ages.reduce((result, x) => {
    const range = findAgeRange(ranges, x.age)
    if (range) result[range as keyof typeof result].push(x)
    return result
  }, rangeMap)

  return Array.from(Object.keys(data)).map((key: string) => ({
    key,
    U: 0,
    M: 0,
    F: 0,
    ...countBy(data[key as keyof typeof data], (x: AgeGenderDatum) => x.gender),
  }))
}

type BarProps = {
  bar: Omit<BarGroupBar<string>, "key" | "value"> & {
    bar: SeriesPoint<{ key: string; U: number; M: number; F: number }>
    key: string
  }
}

const Bar = ({ bar }: BarProps) => {
  const ref = useRef(null)
  const isHovered = useHover(ref)
  const refPoint = getSVGPointAsFloatingRef(
    ref.current,
    bar.x + bar.width / 2,
    bar.y,
  )

  return (
    <>
      <rect
        ref={ref}
        x={bar.x}
        y={bar.y}
        height={bar.height}
        width={bar.width}
        fill={bar.color}
      />
      <Popover
        open={isHovered}
        placement="top"
        className="flex p-2 text-sm pointer-events-none"
        referenceElement={refPoint}
      >
        <div className="mr-2">{getName(bar.key)}</div>
        <div className="font-medium">
          {bar.bar.data[bar.key as keyof typeof bar.bar.data]}
        </div>
      </Popover>
    </>
  )
}

type AgeDistributionProps = {
  width?: number
  height?: number
  lineColor?: Color
  margin?: Margin
  ranges?: typeof AgeRanges
  data?: AgeGenderDatum[]
}

const AgeDistribution = ({
  width = 600,
  height = 300,
  lineColor = "#b9bbbd",
  margin = {
    top: 40,
    left: 40,
    bottom: 40,
    right: 0,
  },
  ranges = AgeRanges,
  data: ages = [],
}: AgeDistributionProps) => {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const data = groupByAgeRange(ranges, ages)

  const x = (d: AgeDistByGenderDatum) => d.key
  const keys = Object.keys(Gender)
  const totals = data.map((d: AgeDistByGenderDatum) =>
    keys.reduce((result, k) => result + d[k as Gender], 0),
  )

  const xScale = scaleBand<string>({
    domain: data.map(x),
    padding: 0.2,
  }).rangeRound([0, xMax])

  const yScale = scaleLinear<number>({
    domain: [0, Math.max(...totals)],
    nice: true,
  }).range([yMax, 0])

  return (
    <ResponsiveSVG width={width} height={height}>
      <GridRows
        top={margin.top}
        left={margin.left}
        scale={yScale}
        width={xMax}
        stroke={lineColor}
        strokeOpacity={0.4}
      />

      <Group left={margin.left} top={margin.top}>
        <BarStack
          data={data}
          keys={keys}
          x={x}
          xScale={xScale}
          yScale={yScale}
          color={(key) => getColor(key)}
        >
          {(barStacks) =>
            barStacks.map((barStack) =>
              barStack.bars.map((bar) => (
                <Bar
                  key={`bar-stack-${barStack.index}-${bar.index}`}
                  bar={bar}
                />
              )),
            )
          }
        </BarStack>
      </Group>

      <AxisLeft
        scale={yScale}
        top={margin.top}
        left={margin.left}
        hideTicks
        labelProps={{
          fill: lineColor,
          textAnchor: "middle",
          fontFamily: "inherit",
          fontSize: 10,
        }}
        stroke="none"
        tickStroke={lineColor}
        tickLabelProps={() => ({
          fontFamily: "inherit",
          fontSize: 10,
          textAnchor: "end",
          verticalAnchor: "middle",
          fill: lineColor,
        })}
      />

      <AxisBottom
        left={margin.left}
        top={yMax + margin.top}
        scale={xScale}
        hideTicks
        labelProps={{
          fill: lineColor,
          textAnchor: "middle",
          fontFamily: "inherit",
          fontSize: 10,
        }}
        tickStroke={lineColor}
        tickLabelProps={() => ({
          fontFamily: "inherit",
          fontSize: 10,
          textAnchor: "middle",
          verticalAnchor: "middle",
          fill: lineColor,
        })}
        stroke={lineColor}
      />
    </ResponsiveSVG>
  )
}

export default AgeDistribution
