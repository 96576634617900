import { useState, useRef, ComponentProps } from "react"
import { saveAs } from "file-saver"
import { getSnapshot } from "mobx-state-tree"
import { MdOpenInNew } from "react-icons/md"

import { useHover } from "../hooks"
import { formatDate } from "../support/formatting"

import Spinner from "./Spinner"
import Audiogram from "./Audiogram"
import Tooltip from "./Tooltip"
import { IAudiogram } from "../models/Audiogram"
import { IUserProfile } from "../models/UserProfile"
import { MeasurementSessionListMode } from "./MeasurementSessionList"
import clsx from "clsx"
import { observer } from "mobx-react"

/* istanbul ignore next */
const ExportButton = ({
  audiogram,
  userProfile,
  className,
  ...props
}: {
  audiogram: IAudiogram
  userProfile: IUserProfile
} & ComponentProps<"button">) => {
  const [isLoading, setLoading] = useState(false)

  const onClick = async () => {
    try {
      setLoading(true)
      const { generatePDF } = await import(
        "../support/pdf" /* webpackChunkName: "pdf" */
      )

      const blob = await generatePDF(audiogram, userProfile)
      const filename = `${[
        userProfile.full_name,
        "Audiogram",
        formatDate(Date.now()),
      ].join(" ")}.pdf`

      saveAs(blob, filename)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Tooltip
      style={{ width: 220 }}
      className="px-4 py-2 italic text-center"
      content="Export audiogram as PDF"
    >
      <button
        className={clsx(
          "block p-1 text-white bg-gray-light hover:bg-picton rounded cursor-pointer export-button",
          className,
        )}
        onClick={onClick}
        {...props}
      >
        {isLoading ? (
          <Spinner color="white" size={22} />
        ) : (
          <MdOpenInNew size={22} />
        )}
      </button>
    </Tooltip>
  )
}

/* istanbul ignore next */
const ExportableAudiogram = ({
  audiogram,
  userProfile,
  className,
  mode = "joint",
  ...props
}: {
  audiogram: IAudiogram
  userProfile: IUserProfile
  mode?: MeasurementSessionListMode
} & ComponentProps<"div">) => {
  const ref = useRef<HTMLDivElement>(null)
  const isHovered = useHover(ref)

  return (
    <div
      ref={ref}
      className={clsx("relative exportable-audiogram", className)}
      {...props}
    >
      {mode === "joint" ? (
        <Audiogram data={getSnapshot(audiogram) as IAudiogram} />
      ) : (
        <div className="flex flex-auto flex-wrap">
          <div className="w-full lg:w-1/2">
            <Audiogram data={audiogram.rightOnly} title="Right Ear" />
          </div>
          <div className="w-full lg:w-1/2">
            <Audiogram data={audiogram.leftOnly} title="Left Ear" />
          </div>
        </div>
      )}
      {!audiogram.isEmpty && isHovered && (
        <div className={clsx("absolute right-0 top-0 m-3 bg-white")}>
          <ExportButton audiogram={audiogram} userProfile={userProfile} />
        </div>
      )}
    </div>
  )
}

export default observer(ExportableAudiogram)
