import { observer } from "mobx-react"
import {
  Children,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"

export type DraggableProps = {
  children?: any
  onDragEnd?(e: MouseEvent): void
  onDragStart?(e: MouseEvent): void
  onDragMove?(e: MouseEvent, ref: any): void
}

/* istanbul ignore next */
const Draggable = forwardRef<any, DraggableProps>(
  (
    {
      children,
      onDragMove = () => {},
      onDragStart = () => {},
      onDragEnd = () => {},
    },
    fwdRef,
  ) => {
    const ref = useRef()
    useImperativeHandle(fwdRef, () => ref.current, [fwdRef])

    let isMouseDown = false

    const handleMove = (e: MouseEvent) => {
      if (isMouseDown) {
        e.preventDefault()
        onDragMove(e, ref.current)
      }
    }

    const handleUp = (e: MouseEvent) => {
      e.preventDefault()
      isMouseDown = false
      document.removeEventListener("pointermove", handleMove)
      document.removeEventListener("pointerup", handleUp)
      onDragEnd(e)
    }

    const handleDown = (e: MouseEvent) => {
      isMouseDown = true
      document.addEventListener("pointermove", handleMove)
      document.addEventListener("pointerup", handleUp)
      onDragStart(e)
    }

    return cloneElement(Children.only(children), {
      ref,
      onPointerDown: handleDown,
    })
  },
)

export default observer(Draggable)
