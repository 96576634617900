import { useEffect, useCallback, useRef, useState, useContext } from "react"
import { observer, useLocalObservable } from "mobx-react"
import { isEqual, orderBy } from "lodash"
import { getSnapshot, applySnapshot } from "mobx-state-tree"
import { autorun, toJS } from "mobx"
import { IoPaperPlane } from "react-icons/io5"
import { FaRegTrashAlt } from "react-icons/fa"
import { MdRestore } from "react-icons/md"

import { useFormRequest } from "../../hooks"
import {
  Button,
  Subheading,
  LoadingButton,
  Card,
  Expandable,
  FloatingToolbar,
  Popover,
} from "../../components"
import { formatDateWithTime } from "../../support/formatting"

import AGCCard from "./AGCCard"
import CompositeAudiogramCard from "./CompositeAudiogramCard"
import GainCard from "./GainCard"
import FittingRuleListbox from "./FittingRuleListbox"
import { IUserProfile } from "../../models/UserProfile"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { IProgram } from "../../models/Program"
import { IHistoryPoint } from "../../models/HistoryPoint"

export type IFittingRuleRuleGains = Map<number, number>

export type ISplitFittingRuleGains = {
  left: IFittingRuleRuleGains
  right: IFittingRuleRuleGains
}

const Fitting = ({ userProfile }: { userProfile: IUserProfile }) => {
  const { programProfiles, toasts } = useContext(RootStoreContext)!

  const store = useLocalObservable(() => ({
    fittingRuleGains: {
      left: new Map(),
      right: new Map(),
    },
    workingCopy: {},
    get audiogram() {
      return userProfile?.audiogram
        ? userProfile.audiogram
        : { left: [], right: [] }
    },
    get defaultProgram(): IProgram {
      return userProfile?.profile_program?.data.programs.find(
        (p: IProgram) => p.name === "default",
      )!
    },
    get isDirty() {
      return !isEqual(
        toJS(this.workingCopy),
        this.defaultProgram ? getSnapshot(this.defaultProgram) : {},
      )
    },
    reload() {
      this.workingCopy = getSnapshot(this.defaultProgram)
    },
    setWorkingCopy(snapshot: {}) {
      this.workingCopy = snapshot
    },
    setFittingRuleGains(gains: ISplitFittingRuleGains) {
      Object.assign(this.fittingRuleGains, gains)
    },
  }))

  const fetchGains = useCallback(async () => {
    const res = await userProfile.computeGains({
      fitting_rule: store.defaultProgram.fittingRule,
    })

    store.setFittingRuleGains(
      Object.keys(res.data.gains).reduce<ISplitFittingRuleGains>(
        (result, channel) => ({
          ...result,
          [channel]: new Map(
            res.data.gains[channel].map(([freq, value]: [string, string]) => [
              Number(freq),
              Math.round(Number(value)),
            ]),
          ),
        }),
        {} as ISplitFittingRuleGains,
      ),
    )
  }, [store, userProfile])

  const onReset = () => {
    applySnapshot(store.defaultProgram, store.workingCopy)
  }

  const request = useFormRequest(
    () => {
      store.reload()
      toasts.show("The program profile was successfully updated.")
    },
    () => {
      toasts.show("An error occurred while updating the program profile.", {
        accent: "danger",
      })
    },
  )

  const onSave = async () => {
    userProfile.profile_program!.data.programs.forEach(
      ({ equalizer }: IProgram) => equalizer.clamp(store.fittingRuleGains),
    )
    request.perform(
      programProfiles
        .updateByUserProfileId(
          userProfile.id,
          getSnapshot(userProfile.profile_program),
        )
        .then(() => userProfile.fetchHistory()),
    )
  }

  const onSaveDraft = async () => {
    userProfile.profile_program.data.programs.forEach(
      ({ equalizer }: IProgram) => equalizer.clamp(store.fittingRuleGains),
    )
    request.perform(
      programProfiles
        .createDraftByUserProfileId(
          userProfile.id,
          getSnapshot(userProfile.profile_program),
        )
        .then(() => userProfile.fetchHistory()),
    )
  }

  const updateFittingRule = async (fittingRule: string) => {
    store.defaultProgram.equalizer.reset()
    store.defaultProgram.setFittingRule(fittingRule)
  }

  const onChangeFittingRule = (value: string) => {
    if (
      confirm(
        "Changing the fitting rule will discard your current adjustments, continue?",
      )
    ) {
      updateFittingRule(value)
    }
  }

  const [isLoadPopoverOpen, setLoadPopoverOpen] = useState(false)
  const loadButtonRef = useRef(null)

  useEffect(() => {
    store.setWorkingCopy(getSnapshot(store.defaultProgram))
  }, [store])

  useEffect(() => {
    return autorun(fetchGains)
  }, [fetchGains])

  const handleLoadHistoryPoint = async (point: IHistoryPoint) => {
    const profile = await userProfile.fetchHistoryPoint(point.id)
    const program = profile.data.programs.find(
      (p: IProgram) => p.name === "default",
    )
    applySnapshot(store.defaultProgram, program)
  }

  const handleDeleteHistoryPoint = async (point: IHistoryPoint) => {
    await userProfile.deleteHistoryPoint(point.id)
    await userProfile.fetchHistory()
  }

  return (
    <div className="mb-20">
      <Card className="flex flex-col divide-y">
        <CompositeAudiogramCard
          userProfile={userProfile}
          audiogram={store.audiogram}
        />
        <Expandable
          expanded
          className="p-4"
          title={<Subheading className="mb-0">Fitting Rule</Subheading>}
        >
          <FittingRuleListbox
            value={store.defaultProgram.fittingRule}
            onChange={onChangeFittingRule}
          />
        </Expandable>
        <GainCard
          equalizer={store.defaultProgram.equalizer}
          fittingRuleGains={store.fittingRuleGains}
          agc={store.defaultProgram.agc}
        />
        <AGCCard agc={store.defaultProgram.agc} />
      </Card>

      <FloatingToolbar>
        <div className="flex space-x-4">
          <Button
            ref={loadButtonRef}
            outline
            shape="rect"
            className="space-x-2 w-40"
            onClick={() => setLoadPopoverOpen(true)}
            disabled={userProfile.history.length === 0}
          >
            <MdRestore size="1.25em" />
            <span>History</span>
          </Button>

          <Popover
            open={isLoadPopoverOpen}
            onDismiss={() => setLoadPopoverOpen(false)}
            referenceElement={loadButtonRef.current}
            className="flex flex-col px-0 py-2 text-sm"
          >
            <table>
              <tbody className="divide-y">
                {orderBy(userProfile.history, "creation_time", "desc").map(
                  (point) => (
                    <tr key={point.id}>
                      <td className="pl-4 pr-2 py-2">
                        {point.isDraft ? (
                          <span className="text-gray">Draft</span>
                        ) : (
                          <span className="text-teal-500">Sent</span>
                        )}
                      </td>
                      <td className="px-2 py-2 text-right">
                        <a
                          className="hover:underline cursor-pointer"
                          onClick={() => handleLoadHistoryPoint(point)}
                        >
                          {formatDateWithTime(point.creation_time)}
                        </a>
                      </td>
                      <td className="pl-2 pr-4 py-2 text-right">
                        {point.isDraft && (
                          <a
                            className="flex items-center justify-center text-red-600 cursor-pointer"
                            onClick={() => handleDeleteHistoryPoint(point)}
                          >
                            <FaRegTrashAlt size={14} />
                          </a>
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </Popover>
        </div>
        <div className="flex space-x-4">
          <Button
            outline
            shape="rect"
            accent="danger"
            disabled={!store.isDirty}
            className="w-40"
            onClick={onReset}
          >
            Reset
          </Button>
          <LoadingButton
            shape="rect"
            className="w-40"
            isLoading={request.isLoading}
            disabled={!store.isDirty}
            onClick={onSaveDraft}
          >
            Save
          </LoadingButton>
          <LoadingButton
            shape="rect"
            accent="teal"
            className="space-x-2 w-40"
            isLoading={request.isLoading}
            disabled={!store.isDirty}
            onClick={onSave}
          >
            <IoPaperPlane size="1.25em" />
            <span>Apply</span>
          </LoadingButton>
        </div>
      </FloatingToolbar>
    </div>
  )
}

export default observer(Fitting)
