import { types as t, flow, getRoot, Instance } from "mobx-state-tree"

import { ProgramProfile } from "../models/ProgramProfile"
import { RootStore } from "./RootStore"

export const ProgramProfileStore = t
  .model("ProgramProfileStore", {
    items: t.optional(t.map(ProgramProfile), {}),
  })
  .views((self) => ({
    get(id: number | string) {
      return self.items.get(id.toString())
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      fetchByUserProfileId: flow(function* (id) {
        const res = yield rootStore.client.get(
          `/user_profiles/${id}/program_profile/`,
        )

        self.items.put(res.data)
      }),
      updateByUserProfileId: flow(function* (userProfileId, props) {
        const res = yield rootStore.client.post(
          `/user_profiles/${userProfileId}/program_profile/`,
          props,
        )

        self.items.put(res.data)
        yield rootStore.userProfiles.fetch(userProfileId)
      }),
      createDraftByUserProfileId: flow(function* (userProfileId, props) {
        yield rootStore.client.post(
          `/user_profiles/${userProfileId}/program_profile/history/`,
          props,
        )
      }),
      deleteHistoryPointByUserProfileId: flow(function* (userProfileId, id) {
        yield rootStore.client.delete(
          `/user_profiles/${userProfileId}/program_profile/history/${id}`,
        )
      }),
    }
  })

export default ProgramProfileStore
