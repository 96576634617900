import { get as _get } from "lodash"

export const HearingLossSince = Object.freeze({
  hls_unspecified: { name: "Unspecified" },
  hls_no_hl: { name: "I don't have a hearing loss" },
  hls_less_than_one_year: { name: "less than one year" },
  hls_between_one_and_five_years: { name: "1 to 5 years" },
  hls_further_back: { name: "more than 5 years" },
})

export function get(key: any, defaultValue = {}) {
  return _get(HearingLossSince, key, defaultValue)
}
export function getName(key: string, defaultValue = "") {
  return get([key, "name"], defaultValue)
}
