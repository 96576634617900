import { ComponentProps } from "react"

import { Title } from "../../models/Title"
import { IconTitle } from "../../icons"
import { Select } from ".."

export const TitleField = (props: ComponentProps<typeof Select>) => (
  <Select {...props} icon={<IconTitle />}>
    <option value="" />
    {Object.keys(Title).map((key) => (
      <option key={key} value={key}>
        {Title[key as keyof typeof Title]}
      </option>
    ))}
  </Select>
)
