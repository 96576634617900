import { ComponentProps, useRef } from "react"
import { observer } from "mobx-react"
import { action } from "mobx"

import { useOnClickOutside } from "../../hooks"

import Audiogram, {
  OnChangeEvent,
  OnRemoveEvent,
  OnSelectEvent,
  Selection,
} from "../../components/Audiogram"
import { IAudiogram } from "../../models/Audiogram"
import { ChannelSide } from "../../support/types"

import {
  AudiogramMeasurementMap,
  MeasurementType,
  toMeasurementArray,
} from "../../models/Measurement"

type AudiogramChannelProps = {
  data: AudiogramMeasurementMap
  channel: ChannelSide
  selection?: Selection
  onSelect?: (selection: Selection) => void
} & Omit<ComponentProps<typeof Audiogram>, "data">

const AudiogramChannel = ({
  data,
  channel,
  selection,
  onSelect = () => {},
  ...props
}: AudiogramChannelProps) => {
  const ref = useRef(null)

  const audiogramData =
    channel === "left"
      ? { right: [] as unknown, left: toMeasurementArray(data.left) }
      : { right: toMeasurementArray(data.right), left: [] as unknown }

  useOnClickOutside(
    ref.current,
    action((e) => {
      if (e.target && !(e.target as Element).closest(".popover")) {
        onSelect({
          channel: null,
          frequency: null,
        })
      }
    }),
  )

  const handleSelect: OnSelectEvent = action(({ frequency }) => {
    onSelect({ frequency, channel })
  })

  const handleRemove: OnRemoveEvent = action(({ frequency }) => {
    data[channel].delete(frequency)
  })

  const handleChange: OnChangeEvent = action(({ frequency, value, type }) => {
    const existing = data[channel].get(frequency)

    data[channel].set(frequency, {
      value,
      type: type || (existing ? existing.type : MeasurementType.Value),
    })
  })

  return (
    <Audiogram
      {...props}
      editable
      data={audiogramData as IAudiogram}
      className="flex flex-auto"
      selection={selection}
      onChange={handleChange}
      onSelect={handleSelect}
      onRemove={handleRemove}
    />
  )
}

export default observer(AudiogramChannel)
