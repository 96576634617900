import { types as t } from "mobx-state-tree"
import { Program } from "./Program"

export const HearingLoss = t.model("HearingLoss", {
  lossLeft: t.array(t.array(t.number)),
  lossRight: t.array(t.array(t.number)),
})

export const ProgramProfile = t.model("ProgramProfile", {
  id: t.identifierNumber,
  name: t.string,
  creation_time: t.maybeNull(t.string),
  data: t.model({
    programs: t.optional(t.array(Program), []),
    hearingLoss: t.optional(HearingLoss, { lossLeft: [], lossRight: [] }),
    version: t.optional(t.number, 8),
  }),
})
