import { ComponentProps, useContext } from "react"
import { observer } from "mobx-react"

import { CaretLink, UserTypeImage } from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { IUserProfile } from "../../models/UserProfile"
import { getName } from "../../models/Gender"
import clsx from "clsx"

const Td = ({ className, ...props }: ComponentProps<"td">) => (
  <td className={clsx("p-4", className)} {...props} />
)

const NotAvailable = ({ className, ...props }: ComponentProps<"span">) => (
  <span className={clsx("italic text-gray", className)} {...props}>
    –
  </span>
)

type PatientRowProps = {
  patient: IUserProfile
} & ComponentProps<"tr">

const PatientRow = ({ patient }: PatientRowProps) => {
  const rootStore = useContext(RootStoreContext)!

  return (
    <tr
      className={clsx("whitespace-nowrap", {
        "bg-blue-100": rootStore.user.user_profile === patient,
      })}
    >
      <Td className="flex justify-center">
        <UserTypeImage userProfile={patient} />
      </Td>
      <Td>
        <CaretLink to={`/patients/${patient.id}`}>
          {patient.full_name}
        </CaretLink>
      </Td>
      <Td>{getName(patient.gender)}</Td>
      <Td>{patient.age || <NotAvailable />}</Td>
      <Td>{patient.total_measurement_session}</Td>
      <Td>{patient.total_fitting}</Td>
    </tr>
  )
}

export default observer(PatientRow)
