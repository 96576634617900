import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps } from "react"

type ResponsiveSVGProps = {
  xOrigin?: number
  yOrigin?: number
} & ComponentProps<"svg">

const ResponsiveSVG = ({
  width,
  height,
  xOrigin = 0,
  yOrigin = 0,
  preserveAspectRatio = "xMinYMin meet",
  className,
  ...props
}: ResponsiveSVGProps) => (
  <div className={clsx(className, "overflow-hidden default:w-full")}>
    <svg
      preserveAspectRatio={preserveAspectRatio}
      viewBox={`${xOrigin} ${yOrigin} ${width} ${height}`}
      {...props}
    />
  </div>
)

export default observer(ResponsiveSVG)
