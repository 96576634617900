import "./polyfills"
import "./stylesheets/index.css"

import { BrowserRouter } from "react-router-dom"
import { IconContext } from "react-icons"
import { createRoot } from "react-dom/client"
import { Globals } from "@react-spring/web"

import RootContainer from "./containers/RootContainer"
import { createRootStore } from "./stores/RootStore"
import { RootStoreContext } from "./contexts/RootStoreContext"

let rootStore = createRootStore()

Globals.assign({ skipAnimation: !!process.env.CI })

const iconDefaultProps = { className: "align-middle" }

const renderApp = () => {
  const root = createRoot(document.getElementById("root")!)
  root.render(
    <IconContext.Provider value={iconDefaultProps}>
      <RootStoreContext.Provider value={rootStore}>
        <BrowserRouter>
          <RootContainer />
        </BrowserRouter>
      </RootStoreContext.Provider>
    </IconContext.Provider>,
  )
}

renderApp()

/* istanbul ignore next */
if (import.meta.hot) {
  import.meta.hot.accept("stores", () => {
    rootStore = createRootStore()
    renderApp()
  })
}
