import { ComponentProps, ReactNode } from "react"

type HearingLossMessageProps = {
  title: ReactNode
} & Omit<ComponentProps<"div">, "title">
const HearingLossMessage = ({ title, children }: HearingLossMessageProps) => (
  <div className="px-8 py-4">
    <div className="mb-4 text-2xl">{title}</div>
    <p className="text-gray-dark">{children}</p>
  </div>
)

export default HearingLossMessage
