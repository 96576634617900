import { observer } from "mobx-react"
import { useContext, useState } from "react"

import { Button } from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

import ExportModal from "./ExportModal"

const SubHeader = () => {
  const { user } = useContext(RootStoreContext)!

  const [isExportModalOpen, setExportModalOpen] = useState(false)

  return (
    <div className="flex flex-auto items-center mb-6 mt-4 space-x-4 divide-x subheader">
      <div className="flex flex-auto mb-8 md:mb-0">
        <h2 className="flex-auto">My Account</h2>
        <div className="ml-auto space-x-4">
          {!user.isExpertOrTester && (
            <Button
              className="inline-flex"
              to="/become-an-expert"
              outline
              shape="rect"
            >
              Become an Expert
            </Button>
          )}

          <Button
            className="inline-flex"
            shape="rect"
            onClick={() => setExportModalOpen(true)}
          >
            Export My Data
          </Button>

          {/* istanbul ignore next */}
          {isExportModalOpen && (
            <ExportModal
              isOpen={isExportModalOpen}
              onClose={() => {
                setExportModalOpen(false)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(SubHeader)
