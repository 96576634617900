import { animated, useSpring } from "@react-spring/web"
import { createPortal } from "react-dom"
import { observer } from "mobx-react"
import Backdrop from "./Backdrop"
import clsx from "clsx"
import { ComponentProps } from "react"

const Modal = ({
  isOpen,
  onClose,
  className,
  ...props
}: {
  isOpen: boolean
  onClose: () => void
} & ComponentProps<typeof animated.div>) => {
  const modalSpring = useSpring({
    transform: `scale(${isOpen ? 1 : 0.8})`,
    opacity: isOpen ? 1 : 0,
  })

  return createPortal(
    <>
      <Backdrop onClick={onClose} isOpen={isOpen} />

      <div
        style={{
          pointerEvents: "none",
        }}
        className="flex overflow-y-auto absolute z-40 items-center justify-center py-24 min-h-full w-full"
      >
        <animated.div
          className={clsx("flex flex-auto justify-center modal", className)}
          style={{
            willChange: "opacity, transform",
            pointerEvents: isOpen ? "all" : "none",
            ...modalSpring,
          }}
          {...props}
        />
      </div>
    </>,
    document.querySelector("#root")!,
  )
}

export default observer(Modal)
