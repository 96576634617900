import { Children, ReactNode } from "react"
import { animated, useTransition } from "@react-spring/web"
import { observer } from "mobx-react"

type TransitionProps = {
  children: ReactNode
}

const Transition = ({ children }: TransitionProps) => {
  const items: any[] = Children.toArray(children)

  const transition = useTransition(items, {
    keys: (item) => item.key,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <div className="flex-auto relative">
      {transition((style, item) => (
        <animated.div
          key={item.key}
          className="flex absolute inset-0"
          style={{ willChange: "opacity", ...style }}
        >
          {item}
        </animated.div>
      ))}
    </div>
  )
}

export default observer(Transition)
