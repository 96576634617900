import { useRef, useEffect, useCallback, DependencyList } from "react"

export function useInterval(
  callback: () => void,
  { delay = 10000, immediate = true },
  deps: DependencyList = [],
) {
  const ref = useRef(callback)
  const handle = useRef<NodeJS.Timeout | string | number | undefined>()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const start = useCallback(() => {
    handle.current = setInterval(ref.current, delay)
  }, [delay])

  const stop = useCallback(() => {
    if (handle.current) {
      clearInterval(handle.current)
    }
  }, [])

  useEffect(() => {
    if (immediate) {
      ref.current()
    }
    start()
    return stop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [immediate, start, stop, ...deps])

  return { start, stop }
}
