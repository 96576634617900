import { range } from "lodash"
import { ComponentProps } from "react"

import Select from "./Select"

type RangeSelectProps = {
  min: number
  max: number
  includeBlank?: boolean
} & ComponentProps<typeof Select>

const RangeSelect = ({
  min,
  max,
  includeBlank,
  ...props
}: RangeSelectProps) => (
  <Select {...props}>
    {includeBlank && <option key="" />}
    {range(min, max).map((key) => (
      <option key={key} value={key}>
        {key}
      </option>
    ))}
  </Select>
)

export default RangeSelect
