import { observer } from "mobx-react"
import { useState, useRef, ComponentProps } from "react"
import { MdHelpOutline } from "react-icons/md"

import Popover from "./Popover"

/* istanbul ignore next */
const Tip = ({ children, className }: ComponentProps<"span">) => {
  const ref = useRef<HTMLSpanElement>(null)
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <span ref={ref} className={className}>
        <MdHelpOutline
          className="inline"
          onPointerEnter={() => {
            setOpen(true)
          }}
          onPointerLeave={() => {
            setOpen(false)
          }}
        />
      </span>

      <Popover
        open={isOpen}
        referenceElement={ref.current}
        className="px-4 py-3 max-w-prose italic leading-tight text-xs prose"
      >
        {children}
      </Popover>
    </>
  )
}
export default observer(Tip)
