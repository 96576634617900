import { sortBy, first, isNumber } from "lodash"
import getYear from "date-fns/getYear"
import {
  types as t,
  getRoot,
  getSnapshot,
  Instance,
  IAnyModelType,
} from "mobx-state-tree"
import { now } from "mobx-utils"

import { User } from "./User"
import { ProgramProfile } from "./ProgramProfile"
import { Title } from "./Title"
import { RootStore } from "../stores/RootStore"
import { IMeasurementSession } from "./MeasurementSession"
import { HistoryPoint, IHistoryPoint } from "./HistoryPoint"
import { flow } from "mobx"

const LateUser = t.late((): IAnyModelType => User)
export const UserProfile = t
  .model("UserProfile", {
    id: t.identifier,
    user: t.maybeNull(t.reference(LateUser)),
    email: t.maybeNull(t.string),
    birth_year: t.maybeNull(t.number),
    experts: t.optional(t.array(t.reference(LateUser)), []),
    first_name: t.string,
    gender: t.maybeNull(t.string),
    hl_since: t.maybeNull(t.string),
    last_name: t.string,
    locale: t.string,
    phone_number: t.maybeNull(t.string),
    profile_program: t.maybeNull(
      t.reference(ProgramProfile, {
        get(id, self) {
          return getRoot<Instance<typeof RootStore>>(self).programProfiles.get(
            id.toString(),
          )
        },
        set(obj, self) {
          return getRoot<Instance<typeof RootStore>>(self).programProfiles.put(
            obj,
          )
        },
      }),
    ),
    history: t.array(HistoryPoint),
    title: t.maybeNull(t.enumeration(Object.keys(Title))),
    professional_id: t.maybeNull(t.string),
    work_place: t.maybeNull(t.string),
    city: t.maybeNull(t.string),
    total_audiometry: t.number,
    total_manual_input: t.number,
    total_measurement_session: t.number,
    total_screening: t.number,
    total_fitting: t.number,
    hearing_loss: t.maybeNull(t.number),
  })
  .views((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      get full_name() {
        return [
          self.title && Title[self.title as keyof typeof Title],
          self.first_name,
          self.last_name,
        ]
          .filter(Boolean)
          .join(" ")
          .trim()
      },
      get age() {
        return self.birth_year && getYear(now()) - self.birth_year
      },
      get initials() {
        if (self.first_name && self.last_name) {
          return (self.first_name[0] + self.last_name[0]).toLocaleUpperCase()
        }

        return ""
      },
      get audiogram() {
        const audiogram = rootStore.userProfiles.audiograms.get(
          self.id.toString(),
        )

        if (!audiogram) {
          rootStore.userProfiles.audiograms.fetch(self.id)
        }

        return audiogram
      },

      get measurementSessions() {
        return sortBy(
          rootStore.measurementSessions.results.filter(
            (mesurementSession: IMeasurementSession) =>
              mesurementSession.user_profile === self,
          ),
          "-date",
        )
      },

      get lastMeasurementSession() {
        return first(this.measurementSessions)
      },

      get canFitListenApp() {
        const { user } = getSnapshot(self)

        return isNumber(user) && self.total_measurement_session > 0
      },
      get isProAccount() {
        const { user } = getSnapshot(self)
        return !isNumber(user)
      },
    }
  })
  .actions((self) => ({
    setHistory(data: IHistoryPoint[]) {
      self.history.replace(data)
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      fetchHistory: flow(function* () {
        const history = yield rootStore.client.get(
          `/user_profiles/${self.id}/program_profile/history/`,
        )
        self.setHistory(history.data)
        return history
      }),
      async fetchHistoryPoint(id: number) {
        return (
          await rootStore.client.get(
            `/user_profiles/${self.id}/program_profile/history/${id}/`,
          )
        )?.data
      },
      deleteHistoryPoint(id: number) {
        rootStore.programProfiles.deleteHistoryPointByUserProfileId(self.id, id)
      },
      fetch() {
        return rootStore.userProfiles.fetch(self.id)
      },
      update(props: IUserProfile) {
        return rootStore.userProfiles.update(self.id, props)
      },
      computeGains(params: { fitting_rule: string }) {
        return rootStore.client.get(
          `/user_profiles/${self.id}/audiogram/gains/`,
          {
            params,
          },
        )
      },
    }
  })

export interface IUserProfile extends Instance<typeof UserProfile> {}
