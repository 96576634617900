import { ComponentProps } from "react"

const Work = (props: ComponentProps<"svg">) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 19"
    fill="currentColor"
    {...props}
  >
    <path d="M21.083 2.85h-4.791V1.9c0-1.048-.86-1.9-1.917-1.9h-5.75a1.91 1.91 0 00-1.917 1.9v.95H1.917A1.91 1.91 0 000 4.75V17.1C0 18.148.86 19 1.917 19h19.166A1.91 1.91 0 0023 17.1V4.75c0-1.048-.86-1.9-1.917-1.9zM7.667 1.9c0-.524.43-.95.958-.95h5.75c.528 0 .958.426.958.95v.95H7.667V1.9zm14.375 15.232c0 .506-.43.918-.959.918H1.917c-.529 0-.959-.412-.959-.918V10.45c.283.158.608.255.959.255h7.666v1.377c0 .254.215.46.48.46h2.874a.47.47 0 00.48-.46v-1.377h7.666c.351 0 .676-.097.959-.255v6.682zm-11.5-5.732V9.5h1.916v1.9h-1.916zm11.5-2.85c0 .524-.43.95-.959.95h-7.666v-.475a.477.477 0 00-.48-.475h-2.875a.477.477 0 00-.479.475V9.5H1.917a.955.955 0 01-.959-.95v-3.8c0-.524.43-.95.959-.95h19.166c.529 0 .959.426.959.95v3.8z" />
  </svg>
)

export default Work
