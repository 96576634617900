import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps, MouseEvent } from "react"

type StepButtonProps = ComponentProps<"button">

const StepButton = ({ className, ...props }: StepButtonProps) => (
  <button
    type="button"
    className={clsx(
      "flex flex-auto z-0 items-center justify-center w-4 leading-none disabled:text-neutral-300 outline-none",
      className,
      {
        "cursor-pointer hover:bg-neutral-200": !props.disabled,
      },
    )}
    {...props}
  />
)

const StepUpButton = (props: StepButtonProps) => (
  <StepButton {...props}>
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="7 7 12 12"
      className="-mb-1 align-middle"
      height="0.6em"
      width="0.6em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 14l5-5 5 5z" />
    </svg>
  </StepButton>
)

const StepDownButton = (props: StepButtonProps) => (
  <StepButton {...props}>
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="7 7 12 12"
      className="-mt-1 align-middle"
      height="0.6em"
      width="0.6em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 10l5 5 5-5z" />
    </svg>
  </StepButton>
)

type StepperProps = {
  onStepUp: (e: MouseEvent) => void
  onStepDown: (e: MouseEvent) => void
}
const Stepper = ({ onStepUp, onStepDown }: StepperProps) => (
  <div className="flex flex-col leading-none bg-neutral-100 rounded-lg">
    <StepUpButton className="h-5 w-5 rounded-t-lg" onClick={onStepUp} />
    <StepDownButton className="h-5 w-5 rounded-b-lg" onClick={onStepDown} />
  </div>
)

export default observer(Stepper)

export { StepUpButton, StepDownButton }
