import { get as _get } from "lodash"

import { colors } from "../support/constants"
import { Color } from "../support/types"

export enum Gender {
  Female = "F",
  Male = "M",
  Unspecified = "U",
}

type GenderProp = {
  name: string
  color: Color
  symbol: string
}

export const GenderProps: { [g: string]: GenderProp } = Object.freeze({
  M: { name: "Male", color: "#008ab6", symbol: "♂️" },
  F: { name: "Female", color: "#fe67ac", symbol: "♀️" },
  U: { name: "Unspecified", color: "#c8c8c8", symbol: "⚪" },
})

export function get(key: any, defaultValue: any = {}) {
  if (!key) return defaultValue
  return _get(GenderProps, key, defaultValue)
}

export function getName(
  key: any,
  defaultValue: string = "Unspecified",
): string {
  if (!key) return defaultValue
  return get([key, "name"], defaultValue)
}

export function getColor(
  key: any,
  defaultValue: string = colors["gray-light"],
): string {
  if (!key) return defaultValue
  return _get([key, "color"], defaultValue) ?? defaultValue
}

export function getSymbol(key: any, defaultValue = "") {
  if (!key) return defaultValue
  return get([key, "symbol"], defaultValue)
}
