import clsx from "clsx"
import { ComponentProps } from "react"

const NavBar = ({ className, style, ...props }: ComponentProps<"div">) => (
  <div
    className={clsx(
      "flex top-0 z-20 md:px-6 bg-white shadow navbar",
      className,
    )}
    style={{ ...style, position: "sticky" }}
    {...props}
  />
)

export default NavBar
