import { useEffect } from "react"

export function useResizeObserver(
  target: Element | null,
  options: ResizeObserverOptions,
  callback: ResizeObserverCallback,
) {
  useEffect(() => {
    let resizeObserver: ResizeObserver
    if (target) {
      resizeObserver = new ResizeObserver(callback)
      resizeObserver.observe(target, options)
    }
    return () => {
      target && resizeObserver?.unobserve(target)
      resizeObserver?.disconnect()
    }
  }, [callback, target])
}
