import { Instance, types as t } from "mobx-state-tree"
import { ToastAccents } from "../components/Toast"

export const Toast = t.model("Toast", {
  key: t.identifier,
  message: t.string,
  accent: t.optional(t.enumeration(ToastAccents), "primary"),
})

export interface IToast extends Instance<typeof Toast> {}
