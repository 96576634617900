import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps } from "react"

export const LegendItem = ({
  color,
  children,
  className,
  ...props
}: ComponentProps<"div">) => (
  <div className={clsx("flex items-center mx-2 my-1", className)} {...props}>
    <span
      className="inline-block flex-none mr-2 h-3 w-3 rounded-full"
      style={{ backgroundColor: color }}
    />
    {children}
  </div>
)

export type ILegend = {
  name: string
  color: string
}

type LegendProps = {
  items: ILegend[]
} & ComponentProps<"div">

const Legend = ({ items, className }: LegendProps) => (
  <div
    className={clsx(
      className,
      "flex default:flex-wrap justify-center -mx-2 -my-1 text-gray-dark text-sm",
    )}
  >
    {items.map((x) => (
      <LegendItem color={x.color} key={x.name}>
        {x.name}
      </LegendItem>
    ))}
  </div>
)

export default observer(Legend)
