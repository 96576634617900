import clsx from "clsx"
import { ComponentProps, forwardRef, ReactNode, useRef, useState } from "react"
import { animated, useTransition } from "@react-spring/web"
import { observer } from "mobx-react"

import { useToggle } from "../../hooks"

type DropdownOption = {
  key: string
  name: string
  description: ReactNode
}

type DropDownItemProps = {
  option: DropdownOption
  selected: boolean
} & ComponentProps<"li">

const DropDownItem = ({ option, selected, ...props }: DropDownItemProps) => {
  return (
    <li
      {...props}
      className="px-4 py-2 hover:text-white hover:bg-picton cursor-pointer"
    >
      {option.name}
    </li>
  )
}

type DropDownProps = {
  value: string
  onChange: (value: string) => void
  options?: DropdownOption[]
  onDismiss?: () => void
} & Omit<ComponentProps<typeof animated.div>, "onChange">

const DropDown = forwardRef<HTMLDivElement, DropDownProps>(
  ({ style, options = [], value, onChange, onDismiss = () => {} }, ref) => {
    const [hovered, setHovered] = useState<DropdownOption | null>(null)

    return (
      <animated.div
        ref={ref}
        className="absolute p-0 popover"
        data-popper-placement="bottom"
        style={style}
      >
        <div
          className={clsx({
            "lg:grid lg:grid-cols-[auto,65ch] lg:divide-x": hovered,
          })}
        >
          <ul className="divide-y">
            {options.map((option) => (
              <DropDownItem
                key={option.key}
                option={option}
                selected={option.key === value}
                onClick={() => {
                  onChange(option.key)
                  onDismiss()
                }}
                onMouseOver={() => {
                  setHovered(option)
                }}
                onMouseOut={() => {
                  setHovered(null)
                }}
              >
                {option.name}
              </DropDownItem>
            ))}
          </ul>
          {hovered && (
            <div className="hidden lg:block p-4 max-w-prose italic text-sm prose">
              {hovered.description}
            </div>
          )}
        </div>
        <div
          className="arrow"
          style={{
            marginLeft: 70,
          }}
        />
      </animated.div>
    )
  },
)

const defaultOptions: DropdownOption[] = [
  {
    key: "half gain",
    name: "One-Half Gain",
    description: (
      <>
        <p>
          Half fitting rule prescribes a linear gain. This gain value was
          derived by observation of the level in test populations rendering
          conversational speech audible and comfortable.
        </p>

        <p>
          A gain equal to half the hearing loss threshold resulted in the
          appropriate amount of gain at each frequency, according to this
          prescription rule.
        </p>
      </>
    ),
  },
  {
    key: "third gain",
    name: "One-Third Gain",
    description: (
      <p>
        One third fitting prescription is a linear rule where a gain of ⅓ of the
        hearing thresholds is applied for each tested frequency in the PTA. This
        is an evolution of the half gain introducing modifications coming from
        studies concluding that this level of gain renders conversational speech
        audible and comfortable.
      </p>
    ),
  },
  {
    key: "custom NAL RP",
    name: "Jacoti® NAL RP",
    description: (
      <>
        <p>
          NAL (National Acoustic Laboratories, Australia) has developed multiple
          fitting rules over the time, available for use for hearing
          professionals. NAL-RP stands for Revised for Profound hearing losses.
        </p>
        <p>
          This fitting prescription follows the general principles proposed by
          NAL (e.g. equal loudness across all frequencies for a same input
          level).
        </p>
        <p>
          This rule was originally designed by NAL for Linear fittings (same
          gain applied, whatever the input level at any frequency). However,
          NAL-RP is also applicable to non linear fittings (different gains for
          different input levels) by taking into consideration the different
          input levels.
        </p>
        Jacoti computes NAL-RP fitting curves by:
        <ul>
          <li>
            Establishing the gain-frequency response at an input level of 65 dB
            SPL
          </li>
          <li>
            Adding compression to raise the gain for lower input levels and
            lower it for higher input levels.
          </li>
        </ul>
      </>
    ),
  },
  {
    key: "NAL RP",
    name: "NAL RP",
    description:
      "NAL-R (which stands for NAL-Revised) is a prescription procedure that indicates how to fit linear hearing aids to people with mild or moderate hearing loss.",
  },
]

type FittingRuleListboxProps = {
  onChange: (value: string) => void
} & Omit<DropDownProps, "onChange">

const FittingRuleListbox = ({
  value,
  options = defaultOptions,
  onChange,
}: FittingRuleListboxProps) => {
  const [isVisible, toggleVisible, setVisible] = useToggle(false)
  const selected = options.find((option) => option.key === value)

  const ref = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const transition = useTransition(isVisible, {
    enter: {
      opacity: 1,
      transform: `translateY(0%)`,
    },
    leave: {
      opacity: 0,
      transform: `translateY(5%)`,
    },
    from: {
      opacity: 0,
      transform: `translateY(5%)`,
    },
  })

  return (
    <div>
      <button
        type="button"
        ref={ref}
        className="relative mt-4 font-medium text-left input input-select"
        onClick={toggleVisible}
      >
        <span>{selected?.name}</span>
        <span className="flex absolute inset-y-0 right-0 items-center ml-3 pr-5 pointer-events-none">
          <svg
            className="h-4 w-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </span>
      </button>
      {transition(
        (style, visible) =>
          visible && (
            <DropDown
              ref={dropdownRef}
              style={style}
              value={value}
              options={options}
              onDismiss={() => setVisible(false)}
              onChange={onChange}
            />
          ),
      )}
    </div>
  )
}

export default observer(FittingRuleListbox)
