import { IconNotification } from "../../../icons"
import { Badge } from "../../../components"
import { ComponentProps } from "react"

export type NotificationIconProps = {
  count: number
} & ComponentProps<"a">

const NotificationIcon = ({ count = 0, ...props }: NotificationIconProps) => (
  <a className="flex relative mx-8 cursor-pointer notification-icon" {...props}>
    {count > 0 && (
      <Badge
        className="absolute font-semibold text-white bg-red-500"
        style={{
          fontSize: 12,
          textShadow: "0px 0px 1px rgba(0,0,0,0.5)",
          right: "-50%",
          top: "-25%",
        }}
      >
        {count}
      </Badge>
    )}
    <IconNotification width="1.5em" height="1.5em" className="text-imperial" />
  </a>
)

export default NotificationIcon
