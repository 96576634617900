import clsx from "clsx"
import { ComponentProps, forwardRef } from "react"
import { Link } from "react-router-dom"

const SHAPES = {
  rect: "rounded",
  pill: "rounded-full",
}

const SIZES = {
  xs: "default:py-1 default:px-2 default:text-xs",
  sm: "default:py-1 default:px-4 default:text-sm",
  md: "default:py-2 default:px-6",
  lg: "default:py-3 default:px-8",
  xl: "default:py-4 default:px-10 default:text-lg",
}

const ACCENTS = {
  primary: "text-white bg-bondi enabled:hover:bg-picton",
  teal: "text-white bg-teal-500 enabled:hover:bg-teal",
  secondary: "text-white bg-gray-dark enabled:hover:bg-gray",
  danger: "text-white bg-red-500 enabled:hover:bg-red-400",
}

const OUTLINE_ACCENTS = {
  primary:
    "text-bondi border border-bondi enabled:hover:text-white enabled:hover:bg-bondi enabled:hover:border-transparent",
  teal: "text-teal border border-teal enabled:hover:text-white enabled:hover:bg-teal enabled:hover:border-transparent",
  secondary:
    "text-gray-dark border border-gray-dark enabled:hover:text-white enabled:hover:bg-gray-dark enabled:hover:border-transparent",
  danger:
    "text-red-500 border-red-500 border enabled:hover:bg-red-500 enabled:hover:text-white",
}

export type ButtonProps = {
  to?: string
  href?: string
  outline?: boolean
  shape?: keyof typeof SHAPES
  accent?: keyof typeof ACCENTS
  size?: keyof typeof SIZES
} & Omit<ComponentProps<"button">, "ref"> &
  Omit<ComponentProps<"a">, "ref"> &
  Omit<ComponentProps<typeof Link>, "ref" | "to">

const Button = forwardRef<"button" | "a" | typeof Link, ButtonProps>(
  (
    {
      to,
      href,
      outline = false,
      shape = "pill",
      accent = "primary",
      size = "lg",
      className,
      ...props
    },
    ref,
  ) => {
    let Component: any = "button"

    if (to) {
      Component = Link
    } else if (href) {
      Component = "a"
    }

    return (
      <Component
        ref={ref}
        className={clsx(
          "button",
          "flex items-center justify-center font-semibold text-center no-underline disabled:opacity-50 disabled:cursor-default focus:outline-none",
          outline ? OUTLINE_ACCENTS[accent] : ACCENTS[accent],
          SHAPES[shape],
          SIZES[size],
          className,
        )}
        {...{ ...props, href, to }}
      />
    )
  },
)

export default Button
