import { ComponentProps, useLayoutEffect, useRef } from "react"
import { animated, useSpring } from "@react-spring/web"
import {
  arrow,
  autoUpdate,
  flip,
  Middleware,
  offset,
  Placement,
  shift,
  useFloating,
} from "@floating-ui/react-dom"

import { FloatingPortal } from "@floating-ui/react"

import { useOnClickOutside } from "../hooks"
import clsx from "clsx"
import { observer } from "mobx-react"

type PopoverProps = {
  referenceElement: any
  open?: boolean
  placement?: Placement
  middleware?: Middleware[]
  onDismiss?: () => void
} & ComponentProps<typeof animated.div>

const Popover = ({
  referenceElement,
  children,
  className,
  style,
  middleware = [],
  open: isOpen = false,
  placement: targetPlacement = "bottom",
  onDismiss = () => {},
  ...props
}: PopoverProps) => {
  const arrowRef = useRef(null)

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    placement,
    middlewareData,
    refs,
  } = useFloating({
    placement: targetPlacement,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip(),
      shift(),
      arrow({ element: arrowRef }),
      ...middleware,
    ],
  })

  useOnClickOutside(refs.floating.current, onDismiss)
  useLayoutEffect(() => {
    reference(referenceElement)
  }, [referenceElement])

  const spring = useSpring(
    isOpen
      ? {
          opacity: 1,
          transform: `translateY(0%)`,
        }
      : {
          opacity: 0,
          transform: `translateY(5%)`,
        },
  )

  if (!isOpen) return null

  return (
    <FloatingPortal>
      <animated.div
        {...{
          ref: floating,
          className: clsx("popover", className),
          "data-placement": placement,
          style: {
            ...style,
            position: strategy,
            left: x ?? 0,
            top: y ?? 0,
            ...spring,
          },
          ...props,
        }}
      >
        <>
          {children}
          <div
            className="arrow"
            ref={arrowRef}
            style={{
              left: middlewareData.arrow?.x,
              top: middlewareData.arrow?.y,
            }}
          />
        </>
      </animated.div>
    </FloatingPortal>
  )
}

export default observer(Popover)
