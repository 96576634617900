import { Rect } from "../../../support/types"

export const SelectionRect = ({ x0, x1, y0, y1 }: Rect) => {
  const xMin = Math.min(x0, x1)
  const yMin = Math.min(y0, y1)

  return (
    <rect
      x={xMin}
      y={yMin}
      width={Math.max(x0, x1) - xMin}
      height={Math.max(y0, y1) - yMin}
      fill="rgba(54, 191, 232, 0.1)"
      stroke="rgba(54, 191, 232, 0.2)"
      strokeWidth="1px"
    />
  )
}
