import { AxiosResponse } from "axios"
import { types as t, flow, getRoot, Instance } from "mobx-state-tree"

import { Audiogram } from "../models/Audiogram"
import { fromHKAudiogram } from "../models/Audiogram"
import { IAudiogram } from "../models/Audiogram"
import { RootStore } from "./RootStore"

export const MeasurementSessionAudiogramStore = t
  .model("MeasurementSessionAudiogramStore", {
    audiograms: t.optional(t.map(Audiogram), {}),
  })
  .views((self) => ({
    get(id: number): IAudiogram | undefined {
      return self.audiograms.get(id.toString())
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      fetch: flow(function* (id: number) {
        const res = (yield rootStore.client.get(
          `/measurement_sessions/${id}/audiogram/`,
        )) as AxiosResponse

        self.audiograms.set(id.toString(), fromHKAudiogram(res.data.audiogram))
      }),
    }
  })

export interface IMeasurementSessionAudiogramStore
  extends Instance<typeof MeasurementSessionAudiogramStore> {}
