import { types as t, flow, getRoot } from "mobx-state-tree"

import { MeasurementSession } from "../models/MeasurementSession"

import { MeasurementSessionAudiogramStore } from "./MeasurementSessionAudiogramStore"

export const MeasurementSessionStore = t
  .model("MeasurementSessionStore", {
    results: t.optional(t.array(MeasurementSession), []),
    audiograms: t.optional(MeasurementSessionAudiogramStore, {}),
  })
  .actions((self) => ({
    fetchAllByUserProfileId: flow(function* (id) {
      const res = yield getRoot<any>(self).client.get(
        `/user_profiles/${id}/measurement_sessions/`,
      )

      self.results = res.data
    }),
    clear() {
      self.results.clear()
    },
  }))
