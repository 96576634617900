import ImgListenApp from "../../assets/img/listenapp_icon_rounded.svg"
import ImgHCenter from "../../assets/img/hcenter_icon_rounded.svg"

import AppItem from "./AppItem"

const AppItemList = () => (
  <div className="flex flex-auto justify-between mb-8">
    <AppItem icon={ImgListenApp} name="Jacoti Listenapp" />
    <AppItem icon={ImgHCenter} name="Jacoti Hearing Center" />
  </div>
)

export default AppItemList
