import { useLocalObservable } from "mobx-react"
import { useEffect } from "react"

export function useFormRequest(
  onSuccess: () => void,
  onError: (error?: string | {}) => void,
) {
  const state = useLocalObservable(() => ({
    isLoading: false,
    errors: {} as { [key: string]: string },
    onSuccess,
    onError,
    clearErrors() {
      this.errors = {}
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    setErrors(value: {}) {
      this.errors = value
    },
    async perform(promise: Promise<any>) {
      try {
        this.clearErrors()
        this.setLoading(true)
        await promise
        this.onSuccess()
      } catch (err: any) {
        if (err.response?.status === 400) {
          // { error: "Response error"}
          if (err.response.data.error) {
            this.onError(err.response.data.error)
          } else {
            // { errors: { field: ["is not valid"]}}
            this.setErrors(err.response.data)
            this.onError()
          }
        } else {
          // not an user-facing error
          throw err
        }
      } finally {
        this.setLoading(false)
      }
    },
  }))

  useEffect(() => {
    state.onSuccess = onSuccess
  }, [state, onSuccess])

  useEffect(() => {
    state.onError = onError
  }, [state, onError])

  return state
}
