import clsx from "clsx"
import { ComponentProps, useRef } from "react"
import { range } from "lodash"
import zxcvbn from "zxcvbn"

import { useHover } from "../hooks"

import Popover from "./Popover"
import { observer } from "mobx-react"

const Bar = ({ style, ...props }: ComponentProps<"div">) => (
  <div
    {...props}
    className="flex-auto bg-gray-light"
    style={{
      borderRadius: 2,
      height: 4,
      marginLeft: 2,
      marginRight: 2,
      ...style,
    }}
  />
)

/* istanbul ignore next */
function humanScore(value: number) {
  switch (value) {
    case 4:
      return "Strong"
    case 3:
      return "Good"
    case 2:
      return "Fair"
    case 1:
      return "Weak"
    case 0:
      return "Very Weak"
    default:
      return ""
  }
}

/* istanbul ignore next */
function colorScore(value: number) {
  switch (value) {
    case 4:
      return "#44bba4"
    case 3:
      return "#36BFE8"
    case 2:
      return "#f5a624"
    case 1:
      return "#F2D024"
    case 0:
      return "#e0483e"
    default:
      return ""
  }
}

type PasswordStrengthProps = { value: string } & ComponentProps<"div">

const PasswordStrength = ({
  value,
  className,
  ...props
}: PasswordStrengthProps) => {
  const {
    score,
    feedback: { warning, suggestions },
  } = zxcvbn(value)

  const ref = useRef<HTMLDivElement>(null)
  const isPopoverVisible = useHover(ref)

  return (
    <>
      <div ref={ref} className="flex flex-auto items-center justify-end">
        <div
          className="mr-2 font-medium text-sm"
          style={{ color: colorScore(score) }}
        >
          {value && humanScore(score)}
        </div>
        <div className={clsx("flex w-1/2", className)} {...props}>
          {range(5).map((i) => (
            <Bar
              key={i}
              style={
                value && i < score + 1
                  ? { backgroundColor: colorScore(score) }
                  : {}
              }
            />
          ))}
        </div>
      </div>
      {/* istanbul ignore next */}
      <Popover
        open={(warning || suggestions.length > 0) && isPopoverVisible}
        referenceElement={ref.current}
        style={{
          width: 300,
          margin: 20,
        }}
        className="p-4 italic text-sm"
        placement="right"
      >
        {warning && <div className="mb-4">{warning}</div>}
        <ul>
          {suggestions.map((suggestion, i) => (
            <li key={i}>{suggestion}</li>
          ))}
        </ul>
      </Popover>
    </>
  )
}

export default observer(PasswordStrength)
