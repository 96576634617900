import { ComponentProps } from "react"

import AppStoreImg from "../assets/img/available-on-the-app-store.svg"

const AppStoreButton = (props: ComponentProps<"a">) => (
  <a target="_blank" rel="noopener noreferrer" {...props}>
    <img width={150} src={AppStoreImg} />
  </a>
)

export default AppStoreButton
