import { observer } from "mobx-react"
import { ComponentProps, useMemo } from "react"

import Form from "./Form"
import ModelFormContext from "./ModelFormContext"

const ModelForm = ({
  model = {},
  errors = {},
  ...props
}: {
  model: {}
  errors: {}
} & ComponentProps<typeof Form>) => {
  const context = useMemo(() => ({ model, errors }), [model, errors])

  return (
    <ModelFormContext.Provider value={context}>
      <Form {...props} />
    </ModelFormContext.Provider>
  )
}

export default observer(ModelForm)
