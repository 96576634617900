import clsx from "clsx"
import { ComponentProps, forwardRef, useContext } from "react"
import { observer } from "mobx-react"
import { useLocation } from "react-router-dom"

import { IconEmail, IconRepeatPassword } from "../../icons"
import {
  Input,
  ModelFormField,
  ModelFormContext,
  LoadingButton,
  PasswordModelFormField,
  TabBar,
  Tip,
} from "../../components"

import { StepProps } from "./Wizard"
import { RegisterSceneModel } from "."
import { UserType } from "../../stores/UserStore"
import { IModelFormContext } from "../../components/ModelFormContext"

type TabProps = {
  model: RegisterSceneModel
  value: string
} & ComponentProps<"label">

const Tab = forwardRef<HTMLLabelElement, TabProps>(
  ({ className, model, value, children }, ref) => {
    const checked = model.type_user === value

    return (
      <label
        ref={ref}
        className={clsx(
          "flex flex-auto items-center justify-center px-4 py-2 font-medium text-center text-gray cursor-pointer",
          {
            "text-glacier": checked,
          },
          className,
        )}
      >
        <input
          name="type_user"
          value={value}
          className="hidden"
          type="radio"
          checked={checked}
          onChange={(e) => {
            model.update({ type_user: e.target.value as UserType })
          }}
        />
        {children}
      </label>
    )
  },
)

const StepOne = ({ isLoading }: StepProps) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isTester = params.get("as") === "tester"
  const { model } =
    useContext<IModelFormContext<RegisterSceneModel>>(ModelFormContext)

  return (
    <>
      <TabBar
        className="mb-8"
        active={(x) => model.type_user === x.props.value}
      >
        <Tab model={model} value="user">
          As a user
          <Tip className="ml-2">
            Get a free earCloud® account and free access to Jacoti’s hearing
            apps.
          </Tip>
        </Tab>
        {isTester && (
          <Tab model={model} value="tester">
            As a tester
          </Tab>
        )}

        <Tab model={model} value="expert">
          As an expert
          <Tip className="ml-2">
            Professional account for Hearing Experts. Get free access to
            Jacoti’s hearing apps, and a free earCloud® account which enables
            you to manage your Users hearing needs.
          </Tip>
        </Tab>
      </TabBar>

      <ModelFormField name="email" label="Email">
        <Input type="text" icon={<IconEmail />} />
      </ModelFormField>

      <PasswordModelFormField name="password" label="Password" />

      <ModelFormField name="password_confirm" label="Password Confirmation">
        <Input type="password" icon={<IconRepeatPassword />} />
      </ModelFormField>

      <div className="flex justify-end mb-8 mt-8">
        <LoadingButton isLoading={isLoading} className="w-1/2">
          NEXT STEP
        </LoadingButton>
      </div>
    </>
  )
}

export default observer(StepOne)
