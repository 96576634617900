import { uniq } from "lodash"
import { types as t, flow, getRoot, Instance } from "mobx-state-tree"

import { RootStore } from "./RootStore"
import { ExpertLink, IExpertLink } from "../models/ExpertLink"

export const ExpertLinkStore = t
  .model("ExpertLinkStore", {
    links: t.optional(t.array(ExpertLink), []),
  })
  .volatile(() => ({
    isFetching: false,
  }))
  .views((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      get patientLinks() {
        return self.links
          .filter((link) => link.accepted === true)
          .filter((link) => link.expert === rootStore.user)
      },
      get expertLinks() {
        return self.links
          .filter((link) => link.accepted === true)
          .filter(
            (link) => link.user_profile === rootStore.user.user_profile,
          )
      },
      get isLoading() {
        return self.isFetching && !(self.links.length > 0)
      },
    }
  })
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      fetchAll: flow(function* () {
        try {
          self.isFetching = true
          const res = yield rootStore.client.get("/expert_links/")
          const expertIds = uniq(
            res.data.map((link: IExpertLink) => link.expert),
          )

          yield Promise.all(expertIds.map((id) => rootStore.users.fetch(id)))
          yield rootStore.userProfiles.fetchAll()

          self.links = res.data
        } finally {
          self.isFetching = false
        }
      }),
      invite: flow(function* (email) {
        yield rootStore.client.post("/expert_links/", { email })
      }),
      remove: flow(function* (link) {
        yield rootStore.client.delete(`/expert_links/${link.id}/`)
        self.links.remove(link)
      }),
      accept: flow(function* (props) {
        yield rootStore.client.post("/expert_links/activate/", props)
      }),
      clear() {
        self.links.clear()
      },
    }
  })

export interface IExpertLinkStore extends Instance<typeof ExpertLinkStore> {}
