import { useState } from "react"
import { observer } from "mobx-react"

import { formatDate } from "../support/formatting"

import Expandable from "./Expandable"
import ExportableAudiogram from "./ExportableAudiogram"
import LoadingPlaceholder from "./LoadingPlaceholder"
import { IMeasurementSession } from "../models/MeasurementSession"
import { IAudiogram } from "../models/Audiogram"
import { MeasurementSessionListMode } from "./MeasurementSessionList"

export type MeasurementSessionListItemProps = {
  measurementSession: IMeasurementSession
  onRequestAudiogram: (measurementSession: IMeasurementSession) => IAudiogram
  mode: MeasurementSessionListMode
}

const MeasurementSessionListItem = ({
  measurementSession,
  mode,
  onRequestAudiogram,
}: MeasurementSessionListItemProps) => {
  const [wasRequested, setWasRequested] = useState(false)

  const audiogram = wasRequested ? onRequestAudiogram(measurementSession) : null

  const onExpand = () => {
    setWasRequested(true)
  }

  return (
    <Expandable
      title={
        <div className="flex">
          <span className="mr-4 text-gray">
            {formatDate(measurementSession.date_start)}
          </span>
          <span>{measurementSession.labels.join(", ")}</span>
        </div>
      }
      className="p-4"
      onExpand={onExpand}
    >
      <div className="mt-8">
        <LoadingPlaceholder isLoading={!audiogram}>
          {() => (
            <ExportableAudiogram
              mode={mode}
              className="flex flex-auto"
              audiogram={audiogram!}
              userProfile={measurementSession.user_profile}
            />
          )}
        </LoadingPlaceholder>
      </div>
    </Expandable>
  )
}

export default observer(MeasurementSessionListItem)
