import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps } from "react"
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom"

type LinkProps = Partial<RouterLinkProps> & Partial<ComponentProps<"a">>

const Link = ({ className, href, to, ...props }: LinkProps) => {
  let Component: any = RouterLink

  if (href) {
    Component = "a"
  }

  return (
    <Component
      className={clsx(
        "text-picton hover:text-imperial whitespace-nowrap cursor-pointer link",
        className,
      )}
      {...{ to, href, ...props }}
    />
  )
}

export default observer(Link)
