import { useContext, useState } from "react"
import { observer } from "mobx-react"

import { Button, LoadingButton, Indicator } from "../../components"
import { formatRelativeDate } from "../../support/formatting"
import { INotification } from "../../models/Notification"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import clsx from "clsx"

const NOTIFICATION_TITLES = {
  expert_link: "Link invitation",
}

const NOTIFICATION_BODIES = {
  expert_link: "%SENDER% sent a request to connect with you as a user.",
}

type NotificationProps = {
  notification: INotification
}

export type NotificationType = "expert_link"

const Notification = ({ notification }: NotificationProps) => {
  const [isLoading, setLoading] = useState(false)
  const { expertLinks } = useContext(RootStoreContext)!

  const onAcceptInvitation = async () => {
    try {
      setLoading(true)
      await expertLinks.accept({
        ...notification.data,
        id_user: notification.sender.id,
      })
      await expertLinks.fetchAll()
      notification.remove()
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  const onRejectInvitation = () => {
    notification.remove()
  }

  return (
    <div className="flex mb-3 p-4 border-b notification">
      <Indicator
        className={clsx(
          "flex-none mr-2",
          notification.isNew ? "bg-green-500" : "bg-gray",
        )}
      />
      <div className="mr-6">
        <div className="mb-2 font-medium">
          {NOTIFICATION_TITLES[notification.type as NotificationType]}
        </div>
        <div className="mb-6">
          {NOTIFICATION_BODIES[notification.type as NotificationType].replace(
            "%SENDER%",
            notification.sender.email,
          )}
        </div>
        {notification.type === "expert_link" && (
          <div className="flex mb-6">
            <LoadingButton
              isLoading={isLoading}
              className="mr-4 w-1/2"
              onClick={onAcceptInvitation}
            >
              Accept
            </LoadingButton>
            <Button
              disabled={isLoading}
              className="w-1/2"
              accent="danger"
              onClick={onRejectInvitation}
            >
              Decline
            </Button>
          </div>
        )}
        <div className="flex text-gray">
          <div>
            {notification.sender.user_profile
              ? notification.sender.user_profile.full_name
              : notification.sender.email}
          </div>
          <div className="ml-auto">
            {formatRelativeDate(notification.date_created)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Notification)
