import { clamp } from "lodash"

export function textAnchorFromPosition(position: number) {
  if (Math.round(position) === 0) return "middle"
  if (position > 0) return "start"
  return "end"
}
export function polarToCartesian(
  x: number,
  y: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
  return {
    x: x + radius * Math.cos(angleInRadians),
    y: y + radius * Math.sin(angleInRadians),
  }
}

export type ScreenCoords = [number, number]

export function svgPointToScreenCoords(
  ref: SVGGraphicsElement | null | undefined,
  x: number,
  y: number,
): ScreenCoords {
  if (!ref || !ref.ownerSVGElement)
    throw new Error("could not find owner svg element")
  const source = ref.ownerSVGElement.createSVGPoint()

  Object.assign(source, { x, y })

  const target = source.matrixTransform(ref.getScreenCTM()!)

  return [target.x, target.y]
}

export function getRelativeSVGPosition(
  node: SVGGraphicsElement,
  event: MouseEvent,
) {
  let source = node.ownerSVGElement!.createSVGPoint()
  Object.assign(source, { x: event.clientX, y: event.clientY })
  source = source.matrixTransform(node.getScreenCTM()!.inverse())
  return { x: source.x, y: source.y }
}

export function clamper(...range: number[]) {
  return (x: number) => clamp(x, Math.min(...range), Math.max(...range))
}

export function closest(goal: number, counts: number[]) {
  return counts.reduce((prev, curr) =>
    Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev,
  )
}

function createReferenceElementFromPoint(fn: () => ScreenCoords) {
  return {
    getBoundingClientRect() {
      const [x, y] = fn()

      return {
        left: x,
        right: x,
        top: y,
        bottom: y,
        width: 0,
        height: 0,
      }
    },
    clientWidth: 0,
    clientHeight: 0,
  }
}

export function getSVGPointAsFloatingRef(
  node: SVGGraphicsElement | null | undefined,
  x: number,
  y: number,
) {
  return createReferenceElementFromPoint(() =>
    svgPointToScreenCoords(node, x, y),
  )
}
