import { AxiosResponse } from "axios"
import { types as t, flow, getRoot, Instance } from "mobx-state-tree"

import { UserProfile } from "../models/UserProfile"
import { IUserProfile } from "../models/UserProfile"
import { RootStore } from "./RootStore"

import { UserProfileAudiogramStore } from "./UserProfileAudiogramStore"

export const UserProfileStore = t
  .model("UserProfileStore", {
    audiograms: t.optional(UserProfileAudiogramStore, {}),
    userProfiles: t.optional(t.map(UserProfile), {}),
  })
  .views((self) => ({
    get(id: string | number) {
      return self.userProfiles.get(id.toString())
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)
    const fetch = flow(function* (id: number | string) {
      const res: AxiosResponse = yield rootStore.client.get(
        `/user_profiles/${id}/`,
      )

      self.userProfiles.put(res.data)

      return self.get(id.toString())
    })

    return {
      fetch,
      fetchAll: flow(function* () {
        const res: AxiosResponse = yield rootStore.client.get("/user_profiles/")
        return res.data.map((data: IUserProfile) => self.userProfiles.put(data))
      }),
      fetchByUserId: flow(function* (id: number | string) {
        const res: AxiosResponse = yield rootStore.client.get(
          `/users/${id}/user_profile/`,
        )

        self.userProfiles.put(res.data)

        return self.get(id.toString())
      }),
      update: flow(function* (id: number | string, props: IUserProfile) {
        yield rootStore.client.put(`/user_profiles/${id}/`, props)
        return fetch(id)
      }),
    }
  })

export interface IUserProfileStore extends Instance<typeof UserProfileStore> {}
