import { useState, useEffect, DependencyList } from "react"

export function useScrollHeight(
  node: Element | null | undefined,
  deps: DependencyList = [],
) {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    // eslint-disable-next-line babel/no-unused-expressions
    node && setHeight(node.scrollHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, ...deps])

  return height
}
