import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps } from "react"

type TableProps = ComponentProps<"table">

const Table = ({ className, ...props }: TableProps) => (
  <div className={clsx("overflow-x-auto", className)}>
    <table className="max-w-full w-full border-collapse" {...props} />
  </div>
)

export default observer(Table)
