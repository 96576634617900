import { useState } from "react"

export function useToggle(initialValue = false) {
  const [state, setState] = useState(initialValue)
  const toggle = () => {
    setState((x) => !x)
  }

  return [state, toggle, setState] as const
}
