import { ComponentProps } from "react"
import { observer } from "mobx-react"
import { Outlet } from "react-router"

import { Switch } from "../../components"
import RootLayout from "../RootLayout"

import SubHeader from "./SubHeader"
import SubNavigation from "./SubNavigation"

const AccountLayout = ({ ...props }: ComponentProps<typeof RootLayout>) => {
  return (
    <RootLayout
      subheader={<SubHeader />}
      subnavigation={<SubNavigation />}
      {...props}
    >
      <Switch>
        <Outlet />
      </Switch>
    </RootLayout>
  )
}

export default observer(AccountLayout)
