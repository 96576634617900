import clsx from "clsx"
import { forwardRef, ComponentProps } from "react"

type CardProps = ComponentProps<"div">

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={clsx(
        "overflow-hidden bg-white rounded border shadow card",
        className,
      )}
      {...props}
    />
  ),
)

export default Card
