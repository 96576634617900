import { types as t, Instance } from "mobx-state-tree"

export const HistoryPoint = t
  .model("HistoryPoint", {
    id: t.identifierNumber,
    name: t.string,
    creation_time: t.string,
    was_active: t.boolean,
  })
  .views((self) => ({
    get isDraft() {
      return self.was_active
    },
  }))

export interface IHistoryPoint extends Instance<typeof HistoryPoint> {}
