import { get as _get, PropertyPath } from "lodash"

import { colors } from "../support/constants"

const UnknownHearingLossRank = {
  name: "Unknown",
  color: colors["gray-light"],
}

export type HearingLossRankProp = {
  start: number
  end: number
  color: string
  name: string
  short: string
  description: string
}

export const HearingLossRankProps: { [key: string]: HearingLossRankProp } = {
  normal: {
    start: -Infinity,
    end: 25,
    color: "#44bba4",
    name: "Normal hearing",
    short: "Normal",
    description: `You don’t usually have problems following conversations, even in many
    noisy situations. Sometimes you have trouble hearing speech clearly in
    specific environments such as large rooms with an echo or spaces with
    excessive reverberation (such as the back of a church during a
    sermon).`,
  },
  mild: {
    start: 25,
    end: 40,
    name: "Mild hearing loss",
    short: "Mild",
    color: "#f9ef74",
    description: `Although you usually will be able to follow conversations in quiet
    environments with one or two people, you may experience difficulties
    if they speak softly or when trying to follow conversations in larger
    groups, especially in noisy situations such as crowded restaurants.`,
  },
  moderate: {
    start: 40,
    end: 60,
    name: "Moderate hearing loss",
    short: "Moderate",
    color: "#f5a624",
    description: `You may find yourself asking people to repeat themselves during a
    conversation several times. Following conversations in noisy
    situations may be very challenging. Also, you may need higher volume
    levels than the rest of your family in order to hear the TV.`,
  },
  severe: {
    start: 60,
    end: 80,
    name: "Severe hearing loss",
    short: "Severe",
    color: "#e0483e",
    description: `You find it impossible to hear what people are saying without some
    kind of hearing assistance solution. Even with hearing assistance and
    in quiet rooms, speech needs to be louder than normal and group
    conversations are very challenging.`,
  },
  profound: {
    start: 80,
    end: Infinity,
    name: "Profound hearing loss",
    short: "Profound",
    color: "#BB3D35",
    description: `Even if you are using a hearing solution, it is still difficult to
    hear or understand speech in nearly every environment. To help, you
    rely to a great extent on speech reading and/or sign language.You may
    be unable to hear very loud sounds like airplane engines, heavy
    traffic or fire alarms.`,
  },
}

export function get(key: any, defaultValue: any = UnknownHearingLossRank) {
  if (!key) return defaultValue
  return _get(HearingLossRankProps, key, defaultValue)
}
export function getName(key: any, defaultValue = "Unknown") {
  if (!key) return defaultValue
  return _get([key, "name"], defaultValue)
}
export function getColor(key: any, defaultValue = colors["gray-light"]) {
  if (!key) return defaultValue
  return _get([key, "color"], defaultValue)
}

export function getHearingLossRankKey(value: number | null) {
  if (!value) return null
  return Object.keys(HearingLossRankProps).find(
    (key) =>
      value > HearingLossRankProps[key].start && value <= HearingLossRankProps[key].end,
  )
}

export function getHearingLossColor(value: number) {
  return getColor(getHearingLossRankKey(value))
}
