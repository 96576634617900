import { observer } from "mobx-react"
import { MdOutlineLink, MdOutlineLinkOff } from "react-icons/md"
import { uniq } from "lodash"

import Stepper from "../../components/Stepper"
import { Subheading, Expandable, Tip } from "../../components"
import { colors } from "../../support/constants"
import { displayedGainClamper, FREQS, IEqualizer } from "../../models/Equalizer"

import Equalizer from "./GainCard/Equalizer"
import EqualizerTable from "./GainCard/EqualizerTable"
import { IAGC } from "../../models/AGC"
import { ISplitFittingRuleGains } from "./Fitting"
import { ChannelSide, ChannelSides } from "../../support/types"

const GainCard = ({
  equalizer,
  agc,
  fittingRuleGains,
}: {
  equalizer: IEqualizer
  agc: IAGC
  fittingRuleGains: ISplitFittingRuleGains
}) => {
  const { compressionRatio, expansionRatio, kneepoint1, kneepoint2 } =
    agc.gainFunction

  const sideProps = (side: ChannelSide) => {
    const data = equalizer.get(side)
    const x = (d: number) => d || 0
    const y = (d: number) => {
      const val = data.get(d) || 0
      const extra =
        fittingRuleGains[side as keyof typeof fittingRuleGains].get(d) || 0
      return displayedGainClamper(val + extra)
    }
    const y40 = (freq: number) =>
      y(freq) - (kneepoint1 - 40) * (1 - 1 / expansionRatio)
    const y90 = (freq: number) =>
      y(freq) - (90 - kneepoint2) * (1 - 1 / compressionRatio)

    return {
      x,
      y,
      y40,
      y90,
      onChange: (freq: number, gain: number) => {
        const normalizedGain = gain - (fittingRuleGains[side].get(freq) || 0)
        const delta = normalizedGain - equalizer.get(side).get(freq)

        equalizer.set(side, freq, normalizedGain)
        if (equalizer.channelLinking) {
          const mirrorSide = side === "right" ? "left" : "right"

          equalizer.set(
            mirrorSide,
            freq,
            equalizer.get(mirrorSide).get(freq) + delta,
          )
        }
      },
      selection: equalizer.selection[side],
      onSelection: (s: number[]) => {
        equalizer.selection.update(side, s)
        const mirrorSide = side === "right" ? "left" : "right"

        if (equalizer.channelLinking) {
          equalizer.selection.update(mirrorSide, equalizer.selection[side])
        } else {
          equalizer.selection.update(mirrorSide, [])
        }
      },
    }
  }

  const handleStepChannelChange = (side: ChannelSide, delta: number) => () => {
    const y = (d: number) =>
      (equalizer.get(side).get(d) || 0) + (fittingRuleGains[side].get(d) || 0)

    let selectedFreqs = equalizer.selection[side] as number[]

    if (!equalizer.selection[side].length) {
      selectedFreqs = FREQS
    }

    selectedFreqs.forEach((freq: number) => {
      const gain = y(freq) + delta
      const g = gain - (fittingRuleGains[side].get(freq) || 0)
      equalizer.set(side, freq, g)
    })
  }

  const handleToggleChannelLinking = () => {
    equalizer.toggleChannelLinking()
    if (equalizer.channelLinking) {
      const allFreqs = uniq(
        ChannelSides.flatMap((side) => equalizer.selection[side]),
      )
      equalizer.selection.update("left", allFreqs)
      equalizer.selection.update("right", allFreqs)
    } else {
      equalizer.selection.update("left", [])
      equalizer.selection.update("right", [])
    }
  }

  const handleStepRightUpAll = handleStepChannelChange("right", 1)
  const handleStepRightDownAll = handleStepChannelChange("right", -1)
  const handleStepLeftUpAll = handleStepChannelChange("left", 1)
  const handleStepLeftDownAll = handleStepChannelChange("left", -1)

  const handleStepAllUp = () => {
    handleStepRightUpAll()
    handleStepLeftUpAll()
  }

  const handleStepAllDown = () => {
    handleStepRightDownAll()
    handleStepLeftDownAll()
  }

  return (
    <Expandable
      expanded
      className="p-4"
      title={
        <Subheading className="mb-0">
          Equalizer
          <Tip className="ml-2">
            <p>Adjust the default program (Natural Sound).</p>
            Speech, Music and Movie programs will be adjusted automatically
            based on the default program.
          </Tip>
        </Subheading>
      }
    >
      <div className="grid grid-cols-2">
        <Equalizer
          title="Right Ear"
          primaryColor={colors["right-channel"]}
          onStepUp={handleStepRightUpAll}
          onStepDown={handleStepRightDownAll}
          {...sideProps("right")}
        />

        <Equalizer
          title="Left Ear"
          primaryColor={colors["left-channel"]}
          onStepUp={handleStepLeftUpAll}
          onStepDown={handleStepLeftDownAll}
          {...sideProps("left")}
        />
      </div>
      <div className="flex flex-auto">
        <EqualizerTable
          className="flex-auto mr-2 mt-4"
          {...sideProps("right")}
        />

        <div className="flex flex-col flex-none items-center justify-center mt-8 w-12">
          <div className="flex relative items-center">
            <span className="font-medium text-red-500">R</span>
            <button
              type="button"
              className="mx-0.5"
              onClick={handleToggleChannelLinking}
            >
              {equalizer.channelLinking ? (
                <MdOutlineLink size="1.25em" />
              ) : (
                <MdOutlineLinkOff size="1.25em" />
              )}
            </button>
            <span className="font-medium text-bondi">L</span>
          </div>

          <div className="flex space-x-2">
            {equalizer.channelLinking ? (
              <Stepper
                onStepUp={handleStepAllUp}
                onStepDown={handleStepAllDown}
              />
            ) : (
              <>
                <Stepper
                  onStepUp={handleStepRightUpAll}
                  onStepDown={handleStepRightDownAll}
                />
                <Stepper
                  onStepUp={handleStepLeftUpAll}
                  onStepDown={handleStepLeftDownAll}
                />
              </>
            )}
          </div>
        </div>

        <EqualizerTable
          className="flex-auto ml-2 mt-4"
          {...sideProps("left")}
        />
      </div>
    </Expandable>
  )
}

export default observer(GainCard)
