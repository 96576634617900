import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps } from "react"
import { Size } from "../support/types"

type IndicatorProps = {
  size?: Size
} & ComponentProps<"span">

const Indicator = ({
  className,
  style,
  size = "1em",
  ...props
}: IndicatorProps) => (
  <span
    className={clsx("inline-block rounded-full", className)}
    style={{
      width: size,
      height: size,
      ...style,
    }}
    {...props}
  />
)

export default observer(Indicator)
