import clsx from "clsx"
import { ComponentProps, ElementType } from "react"

type DropdownItemProps<T extends ElementType> = {
  tagName: ElementType
} & ComponentProps<T>

function DropdownMenuItem<T extends ElementType>({
  tagName: Component = "a",
  className,
  ...props
}: DropdownItemProps<T>) {
  return (
    <Component
      className={clsx(
        "px-6 py-2 no-underline cursor-pointer dropdown-menu-item",
        className,
        { bordered: "border-b border-gray-light" },
      )}
      {...props}
    />
  )
}

export default DropdownMenuItem
