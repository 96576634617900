import { observer } from "mobx-react"
import { useState, useEffect, useContext } from "react"

import { formatRelativeDate } from "../../support/formatting"
import ImgHCenter from "../../assets/img/hcenter_icon_rounded.svg"
import ImgListenApp from "../../assets/img/listenapp_icon_rounded.svg"
import ImgLola from "../../assets/img/lola_icon_rounded.svg"
import {
  Heading,
  Subheading,
  Card,
  CaretLink,
  LoadingPlaceholder,
  FilteredMeasurementSessionList,
  Metric,
  ExportableAudiogram,
  CompositeAudiogramTip,
} from "../../components"
import HearingLossGauge from "../../components/HearingLossGauge"

import HearingLossLevel from "./HearingLossLevel"
import AppItem from "./AppItem"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const DashboardScene = () => {
  const { user, measurementSessions, applications } =
    useContext(RootStoreContext)!

  const currentUserProfile = user.user_profile
  const [isFetching, setFetching] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        setFetching(true)
        await measurementSessions.fetchAllByUserProfileId(
          user.user_profile.id,
        )
        await applications.fetch()
      } finally {
        setFetching(false)
      }
    }
    fetch()
  }, [applications, measurementSessions, user.user_profile.id])

  return (
    <div>
      <Heading>My Area</Heading>

      <div className="grid md:grid-cols-2 gap-12">
        <div className="flex flex-col">
          <Subheading>
            Composite Audiogram
            <CompositeAudiogramTip className="ml-2" />
          </Subheading>
          <Card className="flex flex-auto flex-col p-4">
            <LoadingPlaceholder isLoading={!currentUserProfile.audiogram}>
              {() => (
                <div className="flex flex-auto flex-col">
                  <ExportableAudiogram
                    className="flex flex-auto items-center justify-center"
                    audiogram={currentUserProfile.audiogram}
                    userProfile={currentUserProfile}
                  />

                  {currentUserProfile.audiogram.isEmpty && (
                    <>
                      <div className="-mx-4 my-4 border-b" />
                      <div className="flex">
                        <div className="flex-auto italic text-gray">
                          Your Composite Audiogram is empty
                        </div>
                        <CaretLink
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://itunes.apple.com/us/app/jacoti-hearing-center/id1001095418?mt=8"
                        >
                          Take your first test
                        </CaretLink>
                      </div>
                    </>
                  )}
                </div>
              )}
            </LoadingPlaceholder>
          </Card>
        </div>

        <div className="flex flex-col">
          <Subheading>
            <span>Connect with a hearing expert in </span>
            <CaretLink to="/expert">My Hearing Expert</CaretLink>
          </Subheading>
          <Card className="flex flex-auto flex-col items-center justify-center p-4">
            <HearingLossGauge
              value={
                currentUserProfile.audiogram &&
                currentUserProfile.audiogram.hearingLoss
              }
            />

            <HearingLossLevel
              value={
                currentUserProfile.audiogram &&
                currentUserProfile.audiogram.hearingLoss
              }
            />
          </Card>
        </div>

        <div className="flex flex-col">
          <FilteredMeasurementSessionList
            isLoading={isFetching}
            measurementSessions={currentUserProfile.measurementSessions}
            onRequestAudiogram={(x) => x.audiogram}
          />
        </div>

        <div>
          <Subheading>Statistics</Subheading>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            <Metric
              name="Best ear"
              value={
                currentUserProfile.audiogram &&
                currentUserProfile.audiogram.bestEar
              }
            />

            <Metric
              name="Test taken"
              value={currentUserProfile.measurementSessions.length}
            />

            <Metric
              name="Last test"
              value={
                currentUserProfile.lastMeasurementSession &&
                currentUserProfile.lastMeasurementSession.date_start
                  ? formatRelativeDate(
                      currentUserProfile.lastMeasurementSession.date_start,
                    )
                  : "never"
              }
            />
          </div>

          <Subheading className="mt-8">Jacoti Apps</Subheading>

          <Card className="app-item-list">
            <AppItem
              icon={ImgHCenter}
              appKey="HearingCenter"
              appLink="https://itunes.apple.com/us/app/jacoti-hearing-center/id1001095418?mt=8"
              name="Jacoti Hearing Center"
              description="Test your hearing"
            />

            <AppItem
              icon={ImgListenApp}
              appKey="ListenApp"
              appLink="https://itunes.apple.com/us/app/jacoti-listenapp/id720203800?ls=1&mt=8"
              name="Jacoti Listenapp"
              description="Optimize your hearing experience"
            />

            <AppItem
              icon={ImgLola}
              name="Jacoti Lola"
              appKey="Lola"
              appLink="https://itunes.apple.com/us/app/jacoti-lola-classroom/id845225391?mt=8"
              description="Transform your phone into an assistive listening device"
            />
          </Card>
        </div>
      </div>
    </div>
  )
}

export default observer(DashboardScene)
