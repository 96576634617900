import { ComponentProps } from "react"

const Loss = (props: ComponentProps<"svg">) => (
  <svg width="1em" height="1em" viewBox="0 0 17 24" {...props}>
    <path
      d="M15.789 4.066c-1-2.2-3.1-3.4-4.7-3.7-3.8-.8-6.7-.3-8.6 1.6-3.2 3.1-2.4 8.7-2.4 8.9 0 .2.3.4.5.4s.4-.3.4-.5c0-.1-.8-5.4 2.1-8.2 1.7-1.6 4.3-2.1 7.9-1.3 1.3.2 3.1 1.2 4 3.1 1.1 2.5.6 5.9-1.6 9.9-.6 1.1-.8 2-1.1 3-.3 1.1-.6 2.3-1.5 4.1-.5 1.1-2.3 1.8-4.1 1.7-.7 0-2.7-.4-3.4-2.4-.8-1.4-1.9-4.1-1.2-5.1.4-.5 1.4-.5 2.2-.3.2.1.4 1 .5 1.5.3 1.2.7 2.5 1.7 2.5.1 0 1.5.1 2.1-.9.5-.8.2-1.9-.7-3.4.7-.7 2.7-2.8 1.8-4.6-.4-.6-1.3-1.4-2.4-1.4-.7 0-1.6.3-2.3 1.4-.3-1-.8-2.9-.2-4.2.3-.8 1-1.3 1.9-1.6.7-.2 3-.4 4.3.8 1.1 1 1.3 2.8.8 5.2-.1.2.1.5.3.5.2.1.5-.1.5-.3.7-2.8.3-4.8-1-6.1-1.8-1.6-4.5-1.2-5.2-1-1.2.4-2 1.1-2.4 2.1-1 2.4.4 5.6.5 5.8.1.2.2.3.4.3s.3-.1.4-.2c.5-1.1 1.2-1.7 2-1.8.7 0 1.4.5 1.6.9.7 1.3-1.1 3.3-1.8 3.9-.2.1-.2.4-.1.6 1.1 1.7 1 2.4.9 2.7-.3.5-1.3.4-1.3.4-.4 0-.7-1.1-.8-1.8-.3-1-.5-1.9-1.2-2.1-1.6-.3-2.6-.1-3.2.6-1.3 1.7.9 5.6 1.1 5.9.6 1.7 2.1 2.8 4.2 2.9h.5c2 0 3.8-.9 4.5-2.1.9-1.9 1.2-3.1 1.5-4.3.2-1 .4-1.8 1-2.8 2.3-4.2 2.9-7.9 1.6-10.6z"
      fill="currentColor"
    />
  </svg>
)

export default Loss
