import clsx from "clsx"
import { ComponentProps } from "react"
import { observer } from "mobx-react"

import { formatFrequency } from "../../../support/formatting"
import { FREQS, displayedGainClamper } from "../../../models/Equalizer"
import Table from "../../../components/Table"
import NumberInput from "../../../components/NumberInput"

const Td = ({ className, ...props }: ComponentProps<"td">) => (
  <td
    className={clsx(
      className,
      "p-0 text-center text-xxs whitespace-nowrap border",
    )}
    {...props}
  />
)

const Th = ({ className, ...props }: ComponentProps<typeof Td>) => (
  <Td className={clsx(className, "px-1 font-light")} {...props} />
)

type EqualizerTableProps = {
  y: (freq: number) => number
  y40: (freq: number) => number
  y90: (freq: number) => number
  onChange: (freq: number, gain: number) => void
  selection?: number[]
  onSelection: (sel: number[]) => void
} & Omit<ComponentProps<typeof Table>, "onChange">

const EqualizerTable = ({
  className,
  y,
  y40,
  y90,
  onChange,
  selection = [],
  onSelection,
}: EqualizerTableProps) => {
  const props = (freq: number) => ({
    className: clsx({
      "bg-neutral-100": selection.length && !selection.includes(freq),
    }),
  })

  return (
    <Table className={clsx("text-xs", className)}>
      <tbody>
        <tr>
          <td />
          {FREQS.map((freq) => (
            <Th key={freq}>{formatFrequency(freq)}hz</Th>
          ))}
        </tr>

        <tr>
          <Th title="Gain at 40dB SPL Input">40dB</Th>
          {FREQS.map((freq) => (
            <Td key={freq} {...props(freq)}>
              <NumberInput
                disabled
                value={Math.round(displayedGainClamper(y40(freq)))}
              />
            </Td>
          ))}
        </tr>

        <tr>
          <Th title="Gain at 60dB SPL Input">60dB</Th>
          {FREQS.map((freq) => (
            <Td key={freq} {...props(freq)}>
              <NumberInput
                value={Math.round(displayedGainClamper(y(freq)))}
                onFocus={() => {
                  onSelection([freq])
                }}
                onChange={(value: number) => {
                  onSelection([freq])
                  onChange(freq, value)
                }}
              />
            </Td>
          ))}
        </tr>

        <tr>
          <Th title="Gain at 90dB SPL Input">90dB</Th>
          {FREQS.map((freq) => (
            <Td key={freq} {...props(freq)}>
              <NumberInput
                disabled
                value={Math.round(displayedGainClamper(y90(freq)))}
              />
            </Td>
          ))}
        </tr>
      </tbody>
    </Table>
  )
}

export default observer(EqualizerTable)
