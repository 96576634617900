import {
  AppleLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

import ImgLogo from "../../assets/img/img_logo_earcloud.svg";
import { Link } from "../../components";
import LoginForm from "./LoginForm";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { RootStoreContext } from "../../contexts/RootStoreContext";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import AppleSignin from "react-apple-signin-auth";
import * as uuid from "uuid";

type ProviderResponse = {
  client_id: string;
  provider: string;
  server_client_id: string;
  scope: string;
  redirect_uri: string;
};

type ProviderState = { string: Omit<ProviderResponse, "provider"> };

const WrappedGoogleButton = ({
  scope,
  redirect_uri,
}: {
  scope: string;
  redirect_uri: string;
}) => {
  const rootStore = useContext(RootStoreContext)!;
  const nonce = uuid.v4();
  const callback = useGoogleLogin({
    flow: "auth-code",
    scope,
    nonce,
    redirect_uri,
    onSuccess: async ({ code }) => {
      const res = await rootStore.client.get("/session/google/callback/", {
        params: {
          code,
          nonce,
        },
      });

      rootStore.setAuthToken(res.data.token);
      rootStore.setUser(res.data.user);
    },
  });

  return (
    <GoogleLoginButton
      align="center"
      text="Sign in with Google"
      onClick={callback}
    />
  );
};

const GoogleButton = ({
  client_id,
  redirect_uri,
  scope,
}: {
  redirect_uri: string;
  client_id: string;
  scope: string;
}) => {
  return (
    <GoogleOAuthProvider clientId={client_id}>
      <WrappedGoogleButton redirect_uri={redirect_uri} scope={scope} />
    </GoogleOAuthProvider>
  );
};

const AppleButton = ({
  client_id,
  scope,
  redirect_uri,
}: {
  client_id: string;
  scope: string;
  redirect_uri: string;
}) => {
  const rootStore = useContext(RootStoreContext)!;
  const nonce = uuid.v4();

  const callback = async ({ authorization }) => {
    const res = await rootStore.client.get("/session/apple/callback/", {
      params: {
        code: authorization.code,
        nonce,
      },
    });

    rootStore.setAuthToken(res.data.token);
    rootStore.setUser(res.data.user);
  };

  return (
    <AppleSignin
      authOptions={{
        usePopup: true,
        clientId: client_id,
        scope,
        redirectURI: redirect_uri,
        nonce,
        // state: 'state',
      }}
      uiType="light"
      onSuccess={callback}
      onError={() => {}}
      render={({ onClick }) => (
        <AppleLoginButton
          align="center"
          text="Sign in with Apple"
          onClick={onClick}
        />
      )}
    />
  );
};

const useOauthProviders = () => {
  const rootStore = useContext(RootStoreContext)!;
  const [providers, setProviders] = useState<ProviderState | undefined>(
    undefined
  );

  async function fetchProviders() {
    const res = await rootStore.client.get(`/session/providers/?client=web`);
    const body: ProviderResponse[] = res.data;
    setProviders(
      body.reduce((prev, { provider, ...rest }) => {
        prev[provider as keyof ProviderState] = rest;
        return prev;
      }, {} as ProviderState)
    );
  }

  useEffect(() => {
    fetchProviders();
  }, []);

  return providers;
};

const LoginScene = () => {
  const providers = useOauthProviders();

  if (!providers) {
    return null;
  }

  return (
    <div className="flex flex-auto flex-col items-center justify-center">
      <div className="flex flex-auto items-end">
        <img className="my-8" src={ImgLogo} />
      </div>

      <div className="px-8 w-full md:w-3/4 sm:w-2/3 xxl:w-1/3">
        <h3 className="mb-6">SIGN IN</h3>

        <div className="flex flex-col space-y-1">
          <GoogleButton {...providers["google" as keyof ProviderState]} />
          <AppleButton {...providers["apple" as keyof ProviderState]} />
        </div>

        <div className="text-center italic text-gray my-4">or</div>

        <LoginForm />
      </div>

      <p className="flex flex-auto flex-col justify-end p-8 text-center">
        <span className="mb-4">Don't have an account?</span>
        <Link to="/register">Sign up</Link>
      </p>
    </div>
  );
};

export default observer(LoginScene);
