import { observer } from "mobx-react"
import Button, { ButtonProps } from "./Button"
import Spinner from "./Spinner"

type LoadingButtonProps = {
  isLoading: boolean
} & ButtonProps

const LoadingButton = ({
  isLoading,
  children,
  disabled,
  ...props
}: LoadingButtonProps) => (
  <Button {...props} disabled={isLoading || disabled}>
    {isLoading && <Spinner size={18} color="white" />}
    {!isLoading && children}
  </Button>
)

export default observer(LoadingButton)
