import clsx from "clsx"
import { observer } from "mobx-react"

import { IconEmail, IconLocation, IconWorkplace } from "../../icons"
import { Avatar, UserTypeImage } from "../../components"
import { getSymbol } from "../../models/Gender"
import { IUserProfile } from "../../models/UserProfile"
import { ComponentProps } from "react"

type LabelWithIconProps = {
  icon: any
} & ComponentProps<"span">

const LabelWithIcon = ({
  className,
  icon: Icon,
  children,
}: LabelWithIconProps) => (
  <span className={clsx("flex items-center space-x-2 text-sm", className)}>
    <Icon />
    <span>{children}</span>
  </span>
)

type UserProfileProps = {
  userProfile: IUserProfile
}

const UserProfile = ({ userProfile }: UserProfileProps) => {
  const genderIcon = () => (
    <span className="text-xl">{getSymbol(userProfile.gender)}</span>
  )

  return (
    <div className="flex flex-auto">
      <div className="flex flex-col items-center">
        <Avatar size={64} className="mb-4 text-2xl">
          {userProfile.initials}
        </Avatar>
        <UserTypeImage className="h-5 w-auto" userProfile={userProfile} />
      </div>
      <div className="flex flex-auto flex-col ml-6 space-y-2 leading-none">
        <LabelWithIcon className="font-medium text-lg" icon={genderIcon}>
          {userProfile.title} {userProfile.first_name} {userProfile.last_name}
        </LabelWithIcon>
        <div className="flex flex-col space-y-1 text-neutral-500">
          <LabelWithIcon icon={IconEmail}>{userProfile.email}</LabelWithIcon>
          <LabelWithIcon icon={IconLocation}>{userProfile.city}</LabelWithIcon>
          <LabelWithIcon icon={IconWorkplace}>
            {userProfile.work_place}
          </LabelWithIcon>
        </div>
      </div>
    </div>
  )
}

export default observer(UserProfile)
