import { ComponentProps } from "react"

import { IconLoss } from "../../icons"
import { Select } from ".."
import { HearingLossSince } from "../../models/HearingLossSince"
import { getName } from "../../models/HearingLossSince"

export const HearingLossSinceField = (props: ComponentProps<typeof Select>) => (
  <Select {...props} icon={<IconLoss />}>
    <option value="" />
    {Object.keys(HearingLossSince).map((key) => (
      <option key={key} value={key}>
        {getName(key)}
      </option>
    ))}
  </Select>
)
