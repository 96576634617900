/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useRef } from "react"

export function useDisposable(fn: () => void, deps = []) {
  const ref = useRef<any>(undefined)

  useMemo(() => {
    ref.current = fn()
  }, [fn, ...deps])

  useEffect(
    () => () => {
      if (ref.current && typeof ref.current === "function") {
        ref.current()
      }
    },
    deps,
  )

  return ref.current
}
