import { Menu, MenuItem } from "../../components"
import { IUserProfile } from "../../models/UserProfile"

type SubNavigationProps = {
  userProfile: IUserProfile
}

const SubNavigation = ({ userProfile }: SubNavigationProps) => (
  <Menu
    className="overflow-hidden overflow-x-auto -mt-12 mb-8 uppercase subnavigation"
    position="top"
  >
    <MenuItem to={`/patients/${userProfile.id}`}>
      Profile
    </MenuItem>
    {userProfile.canFitListenApp && (
      <MenuItem to={`/patients/${userProfile.id}/fitting`}>
        Fitting
      </MenuItem>
    )}
  </Menu>
)

export default SubNavigation
