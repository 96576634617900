import { useContext } from "react"
import { observer } from "mobx-react"
import { useParams } from "react-router"

import { useInterval } from "../../hooks"
import {
  Button,
  Subheading,
  Card,
  LoadingPlaceholder,
  ExportableAudiogram,
  FilteredMeasurementSessionList,
} from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const PatientAudiogram = () => {
  const { userProfiles, measurementSessions, user } =
    useContext(RootStoreContext)!

  const { id } = useParams()
  const patient = userProfiles.get(id)

  const userAudiogram =
    patient && patient.audiogram ? patient.audiogram : { left: [], right: [] }
  const userMeasurementSessions = patient ? patient.measurementSessions : []

  useInterval(
    () => {
      userProfiles.fetch(id)
      userProfiles.audiograms.fetch(id)
      measurementSessions.fetchAllByUserProfileId(id)
    },
    {},
    [id, measurementSessions, userProfiles],
  )

  return (
    <LoadingPlaceholder isLoading={!patient}>
      {() => (
        <>
          <div className="flex items-end">
            <Subheading className="mb-0">Composite Audiogram</Subheading>
            {user.isExpert && (
              <div className="flex ml-auto">
                <Button
                  to={`/patients/${patient.id}/audiograms/new`}
                  className="flex-none ml-auto"
                >
                  Manual Input
                </Button>
              </div>
            )}
          </div>
          <Card className="flex mb-12 p-8">
            <ExportableAudiogram
              className="flex flex-auto"
              mode="split"
              audiogram={userAudiogram}
              userProfile={patient}
            />
          </Card>

          <FilteredMeasurementSessionList
            mode="split"
            measurementSessions={userMeasurementSessions}
            onRequestAudiogram={(mesurementSession) =>
              mesurementSession.audiogram
            }
          />
        </>
      )}
    </LoadingPlaceholder>
  )
}

export default observer(PatientAudiogram)
