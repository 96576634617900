import JSZip from "jszip"
import { useState, useEffect, useMemo, useContext } from "react"
import { saveAs } from "file-saver"

import { formatDate } from "../../support/formatting"
import { Card, CardHeading, Modal, Spinner } from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { IUser } from "../../models/User"
import { IMeasurementSession } from "../../models/MeasurementSession"
import clsx from "clsx"

/* istanbul ignore next */
const ExportModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const rootStore = useContext(RootStoreContext)!
  const { applications, expertLinks, measurementSessions, user } = rootStore

  const steps = useMemo(
    () => [
      {
        name: "Fetching Applications",
        run: () => applications.fetch(),
      },
      {
        name: "Fetching Expert Links",
        run: () => expertLinks.fetchAll(),
      },
      {
        name: "Fetching Measurement Sessions",
        run: async () => {
          await measurementSessions.fetchAllByUserProfileId(
            user.user_profile.id,
          )
          await Promise.all(
            user.user_profile.measurementSessions.map(
              ({ uuid }: IMeasurementSession) =>
                measurementSessions.audiograms.fetch(uuid),
            ),
          )
        },
      },
      {
        name: "Preparing download",
        run: async () => {
          const zip = new JSZip()

          zip.file("applications.json", JSON.stringify(applications.results))
          zip.file(
            "expert-links.json",
            JSON.stringify(
              user.user_profile.experts.map((x: IUser) => x.asSnapshot),
            ),
          )
          zip.file(
            "measurement-sessions.json",
            JSON.stringify(
              user.user_profile.measurementSessions.map(
                (x: IUser) => x.asSnapshot,
              ),
            ),
          )
          const content = await zip.generateAsync({ type: "blob" })
          const filename = `${[
            user.user_profile.full_name,
            "Export",
            formatDate(Date.now()),
          ].join(" ")}.zip`

          saveAs(content, filename)
        },
      },
    ],
    [applications, expertLinks, measurementSessions, user.user_profile],
  )

  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const doExport = async () => {
      try {
        setLoading(true)
        // eslint-disable-next-line guard-for-in
        for (const index in steps) {
          const step = steps[index]
          setCurrentStepIndex(Number(index))
          // eslint-disable-next-line no-await-in-loop
          await step.run()
        }
      } finally {
        setLoading(false)
        onClose()
      }
    }

    doExport()
  }, [onClose, steps])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card className="p-8">
        <CardHeading className="flex flex-auto -mt-8 -mx-8 mb-8 px-8 py-4 border-b">
          Preparing data...
          <span className="ml-auto font-normal text-gray">
            {currentStepIndex + 1}/{steps.length}
          </span>
        </CardHeading>

        <div className="flex">
          <Spinner />
          <ul className="ml-8">
            {steps.map(({ name }, index) => (
              <li
                key={name}
                className={clsx("mb-2", {
                  "font-medium": isLoading && currentStepIndex === index,
                  "text-gray": currentStepIndex < index,
                })}
              >
                {name}
              </li>
            ))}
          </ul>
        </div>
      </Card>
    </Modal>
  )
}

export default ExportModal
