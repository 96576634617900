import { FC, ReactNode } from "react"
import { useLocation } from "react-router"
import { animated, useTransition } from "@react-spring/web"

/* istanbul ignore next */
const AnimatedSwitch = ({ children }: { children: ReactNode }) => {
  const location = useLocation()

  const transition = useTransition(children, {
    key: location.pathname,
    config: { stiffness: 300, damping: 30 },
    from: {
      opacity: 0,
      transform: "translateX(25%)",
      zIndex: 0,
      position: "absolute",
    },
    enter: {
      transform: "translateX(0%)",
      opacity: 1,
      zIndex: 1,
      position: "relative",
    },
    leave: {
      transform: "translateX(-25%)",
      opacity: 0,
      zIndex: 2,
      position: "absolute",
    },
  })

  return (
    <div className="flex flex-auto flex-col relative mw-full">
      {transition((style, item) => {
        return (
          <animated.div
            key={location.pathname}
            className="flex flex-auto flex-col left-0 top-0 h-full w-full"
            style={{
              willChange: "transform",
              ...(style as {}),
            }}
          >
            {item}
          </animated.div>
        )
      })}
    </div>
  )
}

const Switch: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>


/* istanbul ignore next */
export default process.env.CI ? Switch : AnimatedSwitch
