import { polarToCartesian } from "../../support/plotting"
import { Color } from "../../support/types"

function describeArc(radius: number, startAngle: number, endAngle: number) {
  const start = polarToCartesian(radius, radius, radius, endAngle)
  const end = polarToCartesian(radius, radius, radius, startAngle)
  const arcSweep = endAngle - startAngle <= 180 ? "0" : "1"

  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
  ].join(" ")
}

const Arc = ({
  radius,
  strokeWidth,
  color,
  start,
  end,
}: {
  radius: number
  start: number
  end: number
  color: Color
  strokeWidth: number
}) => (
  <path
    d={describeArc(radius, start, end)}
    stroke={color}
    strokeWidth={strokeWidth}
    fill="none"
  />
)

export default Arc
