import { ComponentProps } from "react"

import { IconGender } from "../../icons"
import { Select } from ".."
import { Gender, getName } from "../../models/Gender"

export const GenderField = (props: ComponentProps<typeof Select>) => (
  <Select {...props} icon={<IconGender />}>
    <option value="" />
    {Object.values(Gender).map((key) => (
      <option key={key} value={key}>
        {getName(key)}
      </option>
    ))}
  </Select>
)
