import { useContext, useEffect } from "react"
import { observer } from "mobx-react"

import { Card, LoadingPlaceholder } from "../../components"
import { useInterval } from "../../hooks"

import EmptyExpertLinksPlaceholder from "./EmptyExpertLinksPlaceholder"
import ExpertLink from "./ExpertLink"
import { IExpertLink } from "../../models/ExpertLink"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const LeftColumn = () => {
  const { expertLinks } = useContext(RootStoreContext)!

  useEffect(() => {
    expertLinks.fetchAll()
  }, [expertLinks])

  useInterval(
    () => {
      expertLinks.fetchAll()
    },
    { immediate: false, delay: 5000 },
    [expertLinks],
  )

  return (
    <div>
      <p className="mb-4">You are connected to</p>
      <Card className="flex flex-col mb-12 divide-y expert-links">
        <LoadingPlaceholder isLoading={expertLinks.isLoading}>
          {expertLinks.expertLinks.length > 0 ? (
            expertLinks.expertLinks.map((link: IExpertLink) => (
              <ExpertLink key={link.id} link={link} />
            ))
          ) : (
            <EmptyExpertLinksPlaceholder />
          )}
        </LoadingPlaceholder>
      </Card>
    </div>
  )
}

export default observer(LeftColumn)
