import { useLocalObservable, observer } from "mobx-react"

import { useFormRequest } from "../../hooks"
import {
  Modal,
  Card,
  CardHeading,
  ModelForm,
  ModelFormField,
  Input,
  LoadingButton,
} from "../../components"

import { FormEvent, useContext } from "react"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const DeleteAccountModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { logout, users, toasts } = useContext(RootStoreContext)!

  const store = useLocalObservable(() => ({
    model: {
      agreement: "",
    },
    get canSubmit() {
      return store.model.agreement.toLocaleUpperCase() === "I AGREE"
    },
  }))

  const request = useFormRequest(
    async () => {
      await logout(false)
      toasts.show("Your account has been deleted.")
    },
    (err: any) => {
      if (err) {
        toasts.show(err.message, { accent: "danger" })
      } else {
        toasts.show("An error occurred while deleting your account.", {
          accent: "danger",
        })
      }
    },
  )

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    request.perform(users.deleteMe())
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card className="p-8">
        <ModelForm
          model={store.model}
          errors={request.errors}
          onSubmit={onSubmit}
        >
          <CardHeading className="flex flex-auto -mt-8 -mx-8 mb-8 px-8 py-4 border-b">
            Read before deleting you account
          </CardHeading>

          <div className="max-w-prose text-sm">
            <p className="mb-4">
              As you know, Jacoti is a medical device company with European CE
              approval and US FDA registration, and Jacoti ListenApp, Jacoti
              HearingCenter and Jacoti Hearing Center Pro are
              medically-certified applications.
            </p>
            <p className="mb-4">
              As a result, Jacoti has the legal obligation to process your data
              to inform you about important product updates or in case of
              problems with the applications.
            </p>

            <p className="mb-4">
              We understand your request to erase your data but want to
              underline that this means that all your account data will be
              erased and that as a result:
            </p>
            <ul className="mb-4 list-ordered">
              <li>
                We will no longer be able to inform you about problems with the
                application, and will also no longer be able to review any
                incidents or problems that you may have experienced and that you
                inform us about; as a consequence, we will assume that you will
                stop all usage of the application;
              </li>
              <li>
                if you would like to use our apps again in the future, you will
                have to create a new user profile.
              </li>
            </ul>
            <p className="mb-4">
              This will, you understand, also mean that you waive the right to
              invoke any of Jacoti’s possible legal responsibilities in
              reference to your usage of the applications because we will no
              longer have any means to retrace information about your use of
              these applications.
            </p>
            <p className="mb-4">
              In compliance with{" "}
              <a href="https://www.jacoti.com/privacy/">
                Jacoti's privacy policy
              </a>
              , we can continue using anonymised data for research and
              development.
            </p>
          </div>

          <p className="mb-4 font-medium">
            Once you delete your account, there's no going back.
          </p>
          <ModelFormField
            name="agreement"
            className="mb-8"
            label={
              <span>
                Please type <span className="font-medium">"I AGREE"</span> to
                confim:
              </span>
            }
          >
            <Input type="text" />
          </ModelFormField>

          <div className="flex items-center">
            <LoadingButton
              accent="danger"
              isLoading={request.isLoading}
              disabled={!store.canSubmit}
            >
              DELETE ACCOUNT
            </LoadingButton>

            <a
              className="px-12 py-3 font-medium cursor-pointer"
              onClick={onClose}
            >
              Cancel
            </a>
          </div>
        </ModelForm>
      </Card>
    </Modal>
  )
}

export default observer(DeleteAccountModal)
