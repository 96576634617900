import { types as t, getSnapshot, Instance } from "mobx-state-tree"
import { ISplitFittingRuleGains } from "../scenes/PatientFittingScene/Fitting"

import { clamper } from "../support/plotting"
import { ChannelSide, ChannelSides, Range } from "../support/types"

// data ranges

export const MIN_DISPLAYED_EQ_GAIN = 0
export const MIN_EQ_GAIN = -120
export const MAX_EQ_GAIN = 120

export const MIN_FREQ = 64
export const MAX_FREQ = 16000

// display ranges

export const FREQ_DOMAIN: Range = [MIN_FREQ, MAX_FREQ]
export const GAIN_DOMAIN: Range = [MIN_EQ_GAIN, MAX_EQ_GAIN]
export const DISPLAYED_GAIN_DOMAIN: Range = [MIN_DISPLAYED_EQ_GAIN, MAX_EQ_GAIN]

// freq data points

export const FREQS = [
  125, 250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000, 8000, 12000,
]

export const isValidFreq = (freq: number) => FREQS.includes(freq)

export const isValidGain = (gain: number) => {
  if (gain > MAX_EQ_GAIN) return false
  if (gain < MIN_EQ_GAIN) return false
  return true
}

export const gainClamper = clamper(...GAIN_DOMAIN)
export const displayedGainClamper = clamper(...DISPLAYED_GAIN_DOMAIN)

const BLANK_EQ = Object.freeze({
  left: FREQS.map((freq) => [freq, 0]),
  right: FREQS.map((freq) => [freq, 0]),
})

const EqualizerSelection = t
  .model("EqualizerSelection", {
    left: t.array(t.number),
    right: t.array(t.number),
  })
  .actions((self) => ({
    update(side: ChannelSide, values: number[]) {
      self[side].replace(values)
    },
  }))

export const Equalizer = t.snapshotProcessor(
  t
    .model("Equalizer", {
      channelLinking: false,
      right: t.array(t.array(t.number)),
      left: t.array(t.array(t.number)),
      selection: t.optional(EqualizerSelection, {
        left: [],
        right: [],
      }),
    })
    .views((self) => ({
      get(side: ChannelSide) {
        return getSnapshot(self[side]).reduce((result, [freq, value]) => {
          result.set(freq, value)
          return result
        }, new Map())
      },
    }))
    .actions((self) => ({
      reset() {
        Object.assign(self, BLANK_EQ)
      },
      set(side: ChannelSide, freq: number, gain: number) {
        const map = self.get(side)
        map.set(freq, gain)
        self[side as keyof typeof self].replace(Array.from(map.entries()))
      },
      toggleChannelLinking() {
        self.channelLinking = !self.channelLinking
      },
      clamp(gains: ISplitFittingRuleGains) {
        ChannelSides.forEach((side: string) => {
          self[side as keyof typeof self].replace(
            self[side as keyof typeof self].map(
              ([freq, gain]: [number, number]) => {
                const extra = gains[side as keyof typeof gains].get(freq) ?? 0
                return [freq, displayedGainClamper((gain ?? 0) + extra) - extra]
              },
            ),
          )
        })
      },
    })),
  {
    postProcessor({ right, left }) {
      // discard selection and channelLinking from snapshots
      return { right, left }
    },
  },
)

export interface IEqualizer extends Instance<typeof Equalizer> {}
