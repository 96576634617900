import { useState, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import { Navigate, Routes } from "react-router"
import { Route, useLocation } from "react-router-dom"
import { fromPairs } from "lodash"

import { Backdrop, ToastList, Link, Button } from "../components"
import LoginLayout from "../layouts/LoginLayout"
import RootLayout from "../layouts/RootLayout"
import PatientLayout from "../layouts/PatientLayout"
import AccountLayout from "../layouts/AccountLayout"
import AccountScene from "../scenes/AccountScene"
import AccountPasswordScene from "../scenes/AccountPasswordScene"
import BecomeAnExpertScene from "../scenes/BecomeAnExpertScene"
import DashboardScene from "../scenes/DashboardScene"
import ExpertDashboardScene from "../scenes/ExpertDashboardScene"
import ExpertScene from "../scenes/ExpertScene"
import NotFoundScene from "../scenes/NotFoundScene"
import PatientNewAudiogramScene from "../scenes/PatientNewAudiogramScene"
import PatientFittingScene from "../scenes/PatientFittingScene"
import PatientScene from "../scenes/PatientScene"
import PatientsScene from "../scenes/PatientsScene"
import ActivateScene from "../scenes/ActivateScene"
import ForgotPasswordScene from "../scenes/ForgotPasswordScene"
import LoginScene from "../scenes/LoginScene"
import RegisterScene from "../scenes/RegisterScene"
import ResetPasswordScene from "../scenes/ResetPasswordScene"
import { RootStoreContext } from "../contexts/RootStoreContext"
import { IToast } from "../models/Toast"

declare global {
  interface Window {
    _paq?: any
  }
}

// eslint-disable-next-line
const _paq = (window._paq = window._paq || [])

const RootContainer = () => {
  const location = useLocation()
  const rootStore = useContext(RootStoreContext)!
  const [shouldDisplayCookiePopup, setDisplayCookiePopup] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    /* istanbul ignore next */
    if (!(process.env.CI || process.env.NO_COOKIE_WARNING)) {
      const cookies = fromPairs(
        document.cookie.split("; ").map((x) => x.split("=")),
      )
      setDisplayCookiePopup(
        !(cookies.mtm_consent || cookies.mtm_consent_removed),
      )
    }
  }, [])

  /* istanbul ignore next */
  const onConsentGiven = () => {
    _paq.push(["rememberConsentGiven"])
    _paq.push(["rememberCookieConsentGiven"])
    setDisplayCookiePopup(false)
  }

  /* istanbul ignore next */
  const onConsentRejected = () => {
    _paq.push(["forgetConsentGiven"])
    _paq.push(["forgetCookieConsentGiven"])
    setDisplayCookiePopup(false)
  }

  useEffect(() => {
    /* istanbul ignore next */
    if (!process.env.CI) {
      _paq.push(["setCustomUrl", location.pathname])
      _paq.push(["setDocumentTitle", document.title])
      _paq.push(["requireCookieConsent"])
      _paq.push(["trackPageView"])
    }
  }, [location])

  return (
    <>
      {shouldDisplayCookiePopup && (
        <>
          <Backdrop isOpen />
          <div className="fixed inset-0 top-auto z-50 bg-white">
            <div className="container grid-cols-[75%,1fr] lg:grid mx-auto px-4 py-8">
              <div className="mb-4 lg:mb-0 leading-normal">
                <h2 className="font-medium">
                  Jacoti respects the privacy of your data
                </h2>
                We use cookies to offer the basic functionality of the website,
                to generate user statistics on an anonymous basis to make our
                website more useful and user friendly. We process personal data
                in compliance with GDPR.
                <br /> To find out more, read our{" "}
                <Link className="underline" href="https://jacoti.com/privacy/">
                  privacy policy
                </Link>{" "}
                and{" "}
                <Link className="underline" href="https://jacoti.com/cookies/">
                  cookie policy
                </Link>
                .
              </div>
              <div className="flex items-center justify-center lg:justify-end space-x-4">
                <Button onClick={onConsentGiven}>Accept</Button>
                <Button outline onClick={onConsentRejected}>
                  Opt-out
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      <ToastList
        toasts={rootStore.toasts.items}
        onDismiss={(toast: IToast) => {
          /* istanbul ignore next */
          rootStore.toasts.remove(toast)
        }}
      />

      {process.env.NODE_ENV === "development" && (
        <div className="fixed bottom-0 left-0 z-50 p-4 text-xs">
          <div className="hidden xs:hidden xxs:block px-2 py-1 font-medium text-neutral-100 bg-neutral-600 rounded">
            XXS
          </div>
          <div className="hidden sm:hidden xs:block px-2 py-1 font-medium text-neutral-100 bg-neutral-600 rounded">
            XS
          </div>
          <div className="hidden md:hidden sm:block px-2 py-1 font-medium text-neutral-100 bg-neutral-600 rounded">
            SM
          </div>
          <div className="hidden lg:hidden md:block sm:hidden px-2 py-1 font-medium text-neutral-100 bg-neutral-600 rounded">
            MD
          </div>
          <div className="hidden lg:block xl:hidden px-2 py-1 font-medium text-neutral-100 bg-neutral-600 rounded">
            LG
          </div>
          <div className="hidden 2xl:hidden xl:block px-2 py-1 font-medium text-neutral-100 bg-neutral-600 rounded">
            XL
          </div>
          <div className="hidden 2xl:block px-2 py-1 font-medium text-neutral-100 bg-neutral-600 rounded">
            2XL
          </div>
        </div>
      )}

      <Routes>
        <Route element={<RootLayout />}>
          <Route
            path="/dashboard"
            element={
              rootStore.user && rootStore.user.isExpertOrTester ? (
                <ExpertDashboardScene />
              ) : (
                <DashboardScene />
              )
            }
          />
          <Route path="/expert" element={<ExpertScene />} />
          <Route path="/patients" element={<PatientsScene />} />

          {rootStore.user && !rootStore.user.isExpertOrTester && (
            <Route path="/become-an-expert" element={<BecomeAnExpertScene />} />
          )}
        </Route>

        <Route path="/patients/*" element={<PatientLayout />}>
          <Route path=":id" element={<PatientScene />} />
          <Route
            path=":id/audiograms/new"
            element={<PatientNewAudiogramScene />}
          />
          <Route path=":id/fitting" element={<PatientFittingScene />} />
        </Route>

        <Route element={<AccountLayout />}>
          <Route path="/account" element={<AccountScene />} />
          <Route path="/account/password" element={<AccountPasswordScene />} />
        </Route>

        <Route element={<LoginLayout />}>
          <Route path="/login" element={<LoginScene />} />
          <Route path="/register" element={<RegisterScene />} />
          <Route path="/forgot-password" element={<ForgotPasswordScene />} />
          <Route
            path="/password/reset/confirm/:uid/:token"
            element={<ResetPasswordScene />}
          />
          <Route path="/activate/:uid/:token" element={<ActivateScene />} />
        </Route>

        <Route
          path="/"
          element={
            <Navigate to={rootStore.isLoggedIn ? "/dashboard" : "/login"} />
          }
        />
        <Route path="*" element={<NotFoundScene />} />
      </Routes>
    </>
  )
}

export default observer(RootContainer)
