/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { MdAdd, MdSearch } from "react-icons/md"
import { escapeRegExp } from "lodash"

import { IconInfo } from "../../icons"
import { useInterval, useToggle } from "../../hooks"
import {
  Button,
  Heading,
  Input,
  LoadingPlaceholder,
  Popover,
} from "../../components"

import NoResultsPlaceholder from "./NoResultsPlaceholder"
import PatientsTable from "./PatientsTable"
import InviteForm from "./InviteForm"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { IExpertLink } from "../../models/ExpertLink"

const EmptyPlaceholder = () => (
  <div className="flex flex-auto flex-col items-center py-8 space-y-8 text-gray">
    <div>
      <IconInfo width={50} height={50} />
    </div>
    <h3 className="max-w-prose font-medium text-center">
      You don’t have any user linked to you through earCloud® yet.
      <br /> If you would like to connect with a User through earCloud®, send
      them a request.
    </h3>
    <div className="mx-auto w-full lg:w-1/2">
      <p className="mb-4 text-gray" />

      <InviteForm />
    </div>
  </div>
)

const InviteButton = () => {
  const ref = useRef<any>(null)
  const [isDropdownVisible, toggleDropdown] = useToggle(false)

  return (
    <>
      <Button ref={ref} className="flex-none ml-auto" onClick={toggleDropdown}>
        <MdAdd className="mr-2" />
        Invite User
      </Button>

      <Popover
        open={isDropdownVisible}
        placement="bottom"
        className="flex flex-col"
        referenceElement={ref.current}
        onDismiss={toggleDropdown}
      >
        <InviteForm />
      </Popover>
    </>
  )
}

const PatientsScene = () => {
  const { expertLinks } = useContext(RootStoreContext)!

  const [filter, setFilter] = useState("")
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true)
        await expertLinks.fetchAll()
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, [expertLinks])

  useInterval(
    () => {
      expertLinks.fetchAll()
    },
    { immediate: false, delay: 5000 },
    [expertLinks],
  )

  const regex = new RegExp(escapeRegExp(filter), "i")
  const matches = expertLinks.patientLinks
    .filter((link: IExpertLink) => link.user_profile.full_name.match(regex))
    .map((link: IExpertLink) => link.user_profile)

  return (
    <div className="flex flex-auto flex-col">
      <div className="flex flex-wrap mb-8">
        <Heading className="flex flex-auto lg:mb-0 w-full lg:w-1/2">
          My Users
        </Heading>
        <div className="flex flex-none space-x-4">
          {expertLinks.patientLinks.length > 0 && (
            <Input
              icon={<MdSearch size={22} />}
              placeholder="Search user...."
              type="search"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value)
              }}
            />
          )}
          <InviteButton />
        </div>
      </div>

      <LoadingPlaceholder
        isLoading={expertLinks.patientLinks.length === 0 && isLoading}
      >
        {expertLinks.patientLinks.length > 0 ? (
          matches.length > 0 ? (
            <PatientsTable patients={matches} />
          ) : (
            <NoResultsPlaceholder />
          )
        ) : (
          <EmptyPlaceholder />
        )}
      </LoadingPlaceholder>
    </div>
  )
}

export default observer(PatientsScene)
