import { SVGIcon } from "../support/types"

const Calendar: SVGIcon = (props) => (
  <svg
    viewBox="0 0 22 24"
    fill="currentColor"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M22 1.6h-2.8V.4c0-.2-.2-.4-.4-.4H16c-.2 0-.4.2-.4.4v1.2H6.8V.4c0-.2-.2-.4-.4-.4H3.6c-.2 0-.4.2-.4.4v1.2H.4c-.2 0-.4.2-.4.4v21.6c0 .2.2.4.4.4H22c.2 0 .4-.2.4-.4V2c0-.2-.2-.4-.4-.4zM16.4.8h2v2.4h-2V.8zM4 .8h2v2.4H4V.8zM.8 2.4h2.4v1.2c0 .2.2.4.4.4h2.8c.2 0 .4-.2.4-.4V2.4h8.8v1.2c0 .2.2.4.4.4h2.8c.2 0 .4-.2.4-.4V2.4h2.4V6H.8V2.4zm0 20.8V6.8h20.8v16.4H.8zm13.6-14H3.6v11.6h15.2V9.2h-4.4zm-2.8.8h2.8v2.8h-2.8V10zm2.8 6.4h-2.8v-2.8h2.8v2.8zM8 13.6h2.8v2.8H8v-2.8zM8 10h2.8v2.8H8V10zm-3.6 0h2.8v2.8H4.4V10zm0 3.6h2.8v2.8H4.4v-2.8zM7.2 20H4.4v-2.8h2.8V20zm3.6 0H8v-2.8h2.8V20zm3.6 0h-2.8v-2.8h2.8V20zm3.6 0h-2.8v-2.8H18V20zm0-3.6h-2.8v-2.8H18v2.8zm0-6.4v2.8h-2.8V10H18z" />
  </svg>
)

export default Calendar
