import clsx from "clsx"
import { ComponentProps } from "react"
import { animated, useSpring } from "@react-spring/web"

type BackdropProps = {
  isOpen: boolean
} & ComponentProps<typeof animated.div>

const Backdrop = ({ isOpen, style, className, ...props }: BackdropProps) => {
  const spring = useSpring({ opacity: isOpen ? 0.5 : 0 })

  return (
    <animated.div
      className={clsx("fixed inset-0 z-40 bg-black backdrop", className)}
      style={{
        ...style,
        ...spring,
        pointerEvents: isOpen ? "all" : "none",
      }}
      {...props}
    />
  )
}

export default Backdrop
