import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps } from "react"

import InputGroup from "./InputGroup"

type InputProps = {
  icon?: JSX.Element
  trailer?: string
  inputClassName?: string
} & ComponentProps<"input">

const Input = ({
  placeholder,
  disabled,
  icon,
  trailer,
  className,
  inputClassName,
  ...props
}: InputProps) => (
  <InputGroup
    {...{
      icon,
      trailer,
      disabled,
      className,
    }}
  >
    <input
      className={clsx("input", inputClassName)}
      placeholder={placeholder}
      disabled={disabled}
      {...props}
    />
  </InputGroup>
)

export default observer(Input)
