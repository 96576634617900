import { observer } from "mobx-react"
import { IAudiogram } from "../models/Audiogram"
import { IMeasurementSession } from "../models/MeasurementSession"

import Card from "./Card"
import Link from "./Link"
import MeasurementSessionListItem from "./MeasurementSessionListItem"

export type MeasurementSessionListMode = "joint" | "split"

export type MeasurementSessionListProps = {
  measurementSessions: IMeasurementSession[]
  onRequestAudiogram: (measurementSession: IMeasurementSession) => IAudiogram
  mode?: MeasurementSessionListMode
}

const MeasurementSessionList = ({
  measurementSessions = [],
  onRequestAudiogram,
  mode = "joint",
}: MeasurementSessionListProps) => {
  if (measurementSessions.length > 0) {
    return (
      <Card className="flex flex-col divide-y">
        {measurementSessions.map((measurementSession) => (
          <MeasurementSessionListItem
            key={measurementSession.uuid}
            measurementSession={measurementSession}
            onRequestAudiogram={onRequestAudiogram}
            mode={mode}
          />
        ))}
      </Card>
    )
  }

  return (
    <Card className="p-8">
      <p className="italic text-gray">
        No results found. Adjust filter, perform a test using{" "}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://itunes.apple.com/us/app/jacoti-hearing-center/id1001095418"
        >
          Jacoti Hearing Center
        </Link>{" "}
        or manually enter an audiogram using{" "}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://itunes.apple.com/us/app/jacoti-listenapp/id720203800"
        >
          ListenApp
        </Link>
        .
      </p>
    </Card>
  )
}

export default observer(MeasurementSessionList)
