import { ComponentProps } from "react"
import Tip from "./Tip"

const CompositeAudiogramTip = ({ className }: ComponentProps<typeof Tip>) => (
  <Tip className={className}>
    <p>
      <strong>Jacoti DuoTone®</strong> hearing test presents tones in a random
      order. Users can select a short test, or a full test. When a user goes
      through the test session several times, for whatever reason, results from
      each session –even if partial– are stored in Jacoti earCloud®.
    </p>
    When Jacoti earCloud® finds there are test results from different sessions
    by the same user, it creates a “composite” PTA audiogram, where measures
    from each session are taken into account, showing values from all sessions
  </Tip>
)

export default CompositeAudiogramTip
