import clsx from "clsx"
import { observer } from "mobx-react"
import { forwardRef, ComponentProps } from "react"

type AvatarProps = {
  size?: number
  color?: string
} & ComponentProps<"div">

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ size = 36, color = "picton", className, style, ...props }, ref) => (
    <div
      ref={ref}
      className={clsx(
        `flex items-center justify-center font-semibold text-white bg-${color} rounded-full avatar`,
        className,
      )}
      style={{ ...style, width: size, height: size }}
      {...props}
    />
  ),
)

export default observer(Avatar)
