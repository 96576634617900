import { ComponentProps } from "react"

const Gender = (props: ComponentProps<"svg">) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 24"
    fill="currentColor"
    {...props}
  >
    <path d="M16.9.5c0-.3-.2-.5-.5-.5h-1.9c-.3 0-.5.2-.5.5 0 .2.2.5.5.5h.8l-2.2 2.3c-.9-.8-2.1-1.2-3.3-1.2-1.4 0-2.7.6-3.6 1.6C5.1 4.8 4.5 6.4 4.6 8 2 8.3 0 10.6 0 13.5s2.1 5.2 4.7 5.5v2.5h-.9c-.3 0-.5.2-.5.5s.2.5.5.5h.9v.9c0 .3.1.5.4.6.3 0 .5-.2.5-.4v-1h.9c.3 0 .5-.2.5-.5s-.2-.5-.4-.5h-1V19c2.6-.3 4.7-2.6 4.7-5.5V13c1.2-.1 2.3-.7 3.1-1.6 1.9-2 2-5.2.3-7.4L16 1.7v.8c0 .3.2.5.4.5.3 0 .5-.2.5-.5v-2zM8.2 16.7c-.8.8-1.9 1.3-3 1.3s-2.2-.5-3-1.3c-.8-.8-1.2-2-1.2-3.2 0-1.2.4-2.3 1.2-3.2.7-.7 1.6-1.2 2.6-1.3.2.9.7 1.7 1.3 2.4.9.9 2 1.5 3.2 1.6.2 1.4-.3 2.7-1.1 3.7zM5.8 9c.9.1 1.7.6 2.4 1.3.5.5.8 1.1 1 1.7-.9-.1-1.7-.6-2.4-1.3-.4-.4-.8-1-1-1.7zM14 7.6c0 1.2-.4 2.3-1.2 3.2-.7.8-1.6 1.2-2.6 1.3C9.6 9.8 7.7 8.2 5.6 8c-.1-1.3.3-2.7 1.2-3.6.8-.8 1.9-1.3 3-1.3s2.2.5 3 1.3c.8.8 1.2 2 1.2 3.2z" />
  </svg>
)

export default Gender
