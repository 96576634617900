import ImgExpert from "../../assets/img/img_login-screen-expert.jpg"
import ImgUpgrade from "../../assets/img/img_upgrade.png"
import FeatureList from "../../scenes/BecomeAnExpertScene/FeatureList"

const ExpertJumbotron = () => (
  <div
    className="flex flex-auto flex-col overflow-y-auto items-center xxl:justify-center bg-bondi"
    style={{
      backgroundImage: `url(${ImgExpert})`,
      backgroundSize: "cover",
    }}
  >
    <h1 className="m-12 mb-0 text-center lg:text-left lg:text-4xl">
      What you can do as a Hearing Expert
    </h1>

    <div className="flex flex-col items-center m-12 xl:w-2/3 xxl:w-1/2">
      <FeatureList className="mb-4 leading-normal bordered-list" />
      <img src={ImgUpgrade} />
    </div>
  </div>
)

export default ExpertJumbotron
