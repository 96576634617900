import { ReactNode } from "react"

import Card from "./Card"

const Metric = ({
  name,
  value,
  suffix,
}: {
  name: ReactNode
  value: ReactNode
  suffix?: ReactNode
}) => (
  <Card className="flex flex-auto flex-col items-center justify-center px-8 py-4 leading-none text-center">
    <div className="mb-2 text-sm">{name}</div>
    <div className="font-semibold text-2xl">{value}</div>
    {suffix && <div className="ml-2 text-gray text-xs">{suffix}</div>}
  </Card>
)

export default Metric
