import { useState, useCallback } from "react"

export function useForceUpdate() {
  // eslint-disable-next-line no-unused-vars
  const [_, forceUpdate] = useState(false)

  return useCallback(() => {
    forceUpdate((s) => !s)
  }, [])
}
