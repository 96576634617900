import {
  Subheading,
  ExportableAudiogram,
  Expandable,
  CompositeAudiogramTip,
} from "../../components"
import { IAudiogram } from "../../models/Audiogram"
import { IUserProfile } from "../../models/UserProfile"

const CompositeAudiogramCard = ({
  audiogram,
  userProfile,
}: {
  audiogram: IAudiogram
  userProfile: IUserProfile
}) => (
  <Expandable
    className="p-4"
    expanded
    title={
      <Subheading className="mb-0">
        Composite Audiogram
        <CompositeAudiogramTip className="ml-2" />
      </Subheading>
    }
  >
    <ExportableAudiogram
      className="flex flex-auto my-4"
      mode="split"
      audiogram={audiogram}
      userProfile={userProfile}
    />
  </Expandable>
)

export default CompositeAudiogramCard
