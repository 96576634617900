import { ComponentProps } from "react"

const Id = (props: ComponentProps<"svg">) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 18"
    fill="currentColor"
    {...props}
  >
    <path d="M21.292 0H1.712C.781 0 0 .746 0 1.74v14.52C0 17.207.734 18 1.712 18h19.576c.931 0 1.712-.793 1.712-1.788V1.74C23.005.798 22.27 0 21.292 0zM22 16.21c0 .447-.341.79-.776.79H1.776A.773.773 0 011 16.21V1.79c0-.447.341-.79.776-.79H21.22c.439 0 .775.348.775.79v14.42H22zM4 11v1h10v-1H4zm0 3v1h10v-1H4zM9 4H4v5h5V4zM8 8H5V5h3v3z" />
  </svg>
)

export default Id
