import { observer } from "mobx-react"
import { colors } from "../support/constants"

const Spinner = ({
  size = 50,
  color = "gray",
}: {
  size?: number
  color?: string
}) => (
  <svg
    className="spinner"
    width={size}
    height={size}
    viewBox="0 0 100 100"
    style={{ verticalAlign: "middle" }}
  >
    <g transform="translate(10 10)">
      <circle
        fill="none"
        stroke={colors[color as keyof typeof colors]}
        strokeDasharray={`${(2 * Math.PI * 40) / 4} ${2 * Math.PI * 40 * 3}`}
        strokeWidth="20"
        cx="40"
        cy="40"
        r="40"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 40 40"
          to="360 40 40"
          dur="0.9s"
          repeatCount="indefinite"
        />
      </circle>
      <circle
        fill="none"
        opacity="0.3"
        stroke={colors[color as keyof typeof colors]}
        strokeWidth="20"
        cx="40"
        cy="40"
        r="40"
      />
    </g>
  </svg>
)

export default observer(Spinner)
