import { SVGIcon } from "../../support/types"

export const RightUp: SVGIcon = (props) => (
  <svg width="1em" height="1em" {...props}>
    <path d="M.012 0v9l2-2V3.338l2.705 2.71a7.996 7.996 0 0011.898 10.64A7.996 7.996 0 006.141 4.646L3.5 2h3.512l2-2zM5 11a6 6 0 1012 0 6 6 0 00-12 0z" />
  </svg>
)

export const Left: SVGIcon = (props) => (
  <svg width="1em" height="1em" {...props}>
    <path d="M5.293 5.293a1 1 0 011.414 0L11 9.586l4.293-4.293a1 1 0 111.414 1.414L12.414 11l4.293 4.293a1 1 0 01-1.414 1.414L11 12.414l-4.293 4.293a1 1 0 01-1.414-1.414L9.586 11 5.293 6.707a1 1 0 010-1.414z" />
  </svg>
)

export const LeftDown: SVGIcon = (props) => (
  <svg width="1em" height="1em" {...props}>
    <path d="M18.5 20h-3.511l-2 2h9v-9l-2 2v3.661L12.414 11l4.293-4.293a1 1 0 00-1.414-1.414L11 9.586 6.707 5.293a1 1 0 00-1.414 1.414L9.586 11l-4.293 4.293a1 1 0 101.414 1.414L11 12.414 18.5 20z" />
  </svg>
)

export const LeftUp: SVGIcon = (props) => (
  <svg width="1em" height="1em" {...props}>
    <path d="M18.5 2h-3.511l-2-2h9v9l-2-2V3.339L12.414 11l4.293 4.293a1 1 0 01-1.414 1.414L11 12.414l-4.293 4.293a1 1 0 01-1.414-1.414L9.586 11 5.293 6.707a1 1 0 111.414-1.414L11 9.586 18.5 2z" />
  </svg>
)

export const Right: SVGIcon = (props) => (
  <svg width="1em" height="1em" {...props}>
    <path d="M11 3a8 8 0 100 16 8 8 0 000-16zm-6 8a6 6 0 1012 0 6 6 0 00-12 0z" />
  </svg>
)

export const RightDown: SVGIcon = (props) => (
  <svg width="1em" height="1em" {...props}>
    <path d="M10.957 3.002c-6.658.037-10.36 7.718-6.24 12.95l-2.705 2.71V15l-2-2v9h9l-2-2H3.5l2.64-2.645c4.358 3.334 10.7 1.393 12.446-3.808 1.745-5.201-2.143-10.576-7.629-10.545zM5 11a6 6 0 1012 0 6 6 0 00-12 0z" />
  </svg>
)
