import { useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { useNavigate, useParams } from "react-router"

import { LoadingPlaceholder } from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const ActivateScene = () => {
  const { isLoggedIn, logout, users, toasts } = useContext(RootStoreContext)!
  const params = useParams()
  const navigate = useNavigate()
  const activate = async () => {
    try {
      await users.activate(params)
      toasts.show("Your account was successfully activated, you can login now.")
    } catch (err: any) {
      if (err.response) {
        toasts.show("There was an error activating your account", {
          accent: "danger",
        })
      } else {
        throw err
      }
    } finally {
      navigate("/login", { replace: true })
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      logout()
    }
    activate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <LoadingPlaceholder size={64} />
}

export default observer(ActivateScene)
