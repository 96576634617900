import { FormEvent, useContext } from "react"
import { useLocalObservable, observer } from "mobx-react"

import { IconEmail } from "../../icons"
import { useFormRequest } from "../../hooks"
import ImgLogo from "../../assets/img/img_logo_earcloud.svg"
import {
  Input,
  Link,
  LoadingButton,
  ModelForm,
  ModelFormField,
} from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const ForgotPasswordScene = () => {
  const { users, toasts } = useContext(RootStoreContext)!

  const model = useLocalObservable(() => ({
    email: "",
  }))

  const request = useFormRequest(
    () => {
      toasts.show("We’ve sent you instructions on how to reset your password.")
    },
    () => {
      toasts.show("You have entered an invalid email.", { accent: "danger" })
    },
  )

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    request.perform(users.resetPassword(model))
  }

  return (
    <div className="flex flex-auto flex-col items-center justify-center">
      <div className="flex flex-auto items-end">
        <img className="my-8" src={ImgLogo} />
      </div>

      <div>
        <h3 className="mb-2">Forgot your password?</h3>
        <p className="mb-8">We will send you an email with instructions</p>
        <ModelForm
          model={model}
          errors={request.errors}
          onSubmit={onSubmit}
          noValidate
        >
          <ModelFormField name="email">
            <Input type="email" icon={<IconEmail />} placeholder="Email" />
          </ModelFormField>

          <div className="flex justify-center mb-8 mt-8">
            <LoadingButton isLoading={request.isLoading} className="w-full">
              REQUEST RESET LINK
            </LoadingButton>
          </div>

          <div className="mb-8 text-center">
            <Link to="/login">Sign in</Link>
          </div>
        </ModelForm>
      </div>

      <p className="flex flex-auto flex-col justify-end p-8 text-center">
        <span className="mb-4">Don't have an account?</span>
        <Link to="/register">Sign up</Link>
      </p>
    </div>
  )
}

export default observer(ForgotPasswordScene)
