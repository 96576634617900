import { Line } from "@visx/shape"
import { ComponentProps } from "react"

const ReferenceLine = ({
  stroke = "#b9bbbd",
  strokeWidth = 1,
  vectorEffect = "non-scaling-stroke",
  shapeRendering = "geometricprecision",
  ...props
}: ComponentProps<typeof Line>) => (
  <Line {...{ stroke, strokeWidth, vectorEffect, shapeRendering, ...props }} />
)

export default ReferenceLine
