import { FormEvent, useContext } from "react"
import { action } from "mobx"
import { validate } from "isemail"
import { observer, useLocalObservable } from "mobx-react"

import { IconEmail } from "../../icons"
import { useFormRequest } from "../../hooks"
import { LoadingButton, Form } from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const InviteForm = () => {
  const { expertLinks, toasts } = useContext(RootStoreContext)!

  const store = useLocalObservable(() => ({
    email: "",
    get isValid() {
      return validate(store.email, { minDomainAtoms: 2 })
    },
  }))

  const request = useFormRequest(
    action(() => {
      toasts.show(
        "Invitation successfully sent. You will receive a notification once the user accepts it.",
      )
      store.email = ""
    }),
    (err: any) => {
      if (err && err.message) {
        toasts.show(err.message, {
          accent: "danger",
        })
      } else {
        toasts.show("An error occurred while sending invitation.", {
          accent: "danger",
        })
      }
    },
  )

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    request.perform(expertLinks.invite(store.email))
  }

  return (
    <Form
      className="flex flex-col md:flex-row overflow-x-auto"
      onSubmit={onSubmit}
    >
      <label className="flex flex-auto items-center mb-4 md:mb-0 px-3 py-2 bg-white rounded-lg md:rounded-r-none border border-gray-light">
        <IconEmail className="mr-4 text-picton" />
        <input
          value={store.email}
          onChange={action((e) => {
            store.email = e.target.value
          })}
          className="px-2 py-1 w-64 appearance-none"
          placeholder="Insert your user's email"
        />
      </label>

      <LoadingButton
        className="rounded-lg md:rounded-l-none"
        isLoading={request.isLoading}
        disabled={!store.isValid}
      >
        SEND INVITATION
      </LoadingButton>
    </Form>
  )
}

export default observer(InviteForm)
