import { FormEvent } from "react"
import { observer } from "mobx-react"

import {
  AGC,
  FormField,
  Input,
  Select,
  Subheading,
  Range,
  Expandable,
  Tip,
} from "../../components"
import {
  MIN_ATTACK_RELEASE_FAST_TIME_CONSTANT,
  MAX_ATTACK_RELEASE_FAST_TIME_CONSTANT,
  MIN_ATTACK_RELEASE_SLOW_TIME_CONSTANT,
  MAX_ATTACK_RELEASE_SLOW_TIME_CONSTANT,
  MIN_WEIGHTED_FAST_TIME_CONSTANT,
  MAX_WEIGHTED_FAST_TIME_CONSTANT,
  MIN_WEIGHTED_SLOW_TIME_CONSTANT,
  MAX_WEIGHTED_SLOW_TIME_CONSTANT,
  MIN_SIMPLE_TIME_CONSTANT,
  MAX_SIMPLE_TIME_CONSTANT,
} from "../../models/LevelDetector"
import { IAGC } from "../../models/AGC"

function from(value: number) {
  return Math.round(value * 1000)
}

function to(value: number) {
  return value / 1000
}

const AGCCard = ({ agc }: { agc: IAGC }) => {
  const handlers = {
    kneepoint1: agc.gainFunction.setKneepoint1,
    kneepoint2: agc.gainFunction.setKneepoint2,
  }

  return (
    <Expandable
      className="p-4"
      title={
        <Subheading className="mb-0">
          Automatic Gain Control
          <Tip className="ml-2">
            <p>
              End users can use different listening programs for each hearing
              situation. Compression, expansion ratios and time constants will
              be used only in "Default" program, where "Music", "Movie" and
              "Speech" programs have those parameters adjusted for their
              expected use case (e.g. reduced compression in "Music" program).{" "}
            </p>
            Knee points and level detector type will be applied to all programs.
          </Tip>
        </Subheading>
      }
    >
      <div className="grid-cols-2 lg:grid gap-8 mt-4">
        <div>
          <AGC
            compressionRatio={agc.gainFunction.compressionRatio}
            expansionRatio={agc.gainFunction.expansionRatio}
            kneepoint1={agc.gainFunction.kneepoint1}
            kneepoint2={agc.gainFunction.kneepoint2}
            onChange={(prop, value) => {
              handlers[prop as keyof typeof handlers](value)
            }}
          />
        </div>
        <div>
          <FormField label="Compression Ratio">
            <Range
              value={agc.gainFunction.compressionRatio}
              onChange={(value) => {
                agc.gainFunction.setCompressionRatio(value)
              }}
            />
          </FormField>
          <FormField label="Expansion Ratio">
            <Range
              value={agc.gainFunction.expansionRatio}
              onChange={(value) => {
                agc.gainFunction.setExpansionRatio(value)
              }}
            />
          </FormField>
          <div className="flex flex-auto justify-between">
            <FormField label="Knee Point 1" className="flex-auto mr-2">
              <Input
                type="number"
                inputClassName="text-right"
                trailer="dB"
                value={Math.round(agc.gainFunction.kneepoint1)}
                onChange={(e) => {
                  agc.gainFunction.setKneepoint1(Number(e.target.value))
                }}
              />
            </FormField>
            <FormField label="Knee Point 2" className="flex-auto ml-2">
              <Input
                inputClassName="text-right"
                type="number"
                trailer="dB"
                value={Math.round(agc.gainFunction.kneepoint2)}
                onChange={(e) => {
                  agc.gainFunction.setKneepoint2(Number(e.target.value))
                }}
              />
            </FormField>
          </div>

          <FormField label="Level Detector">
            <Select
              className="mb-2"
              value={agc.levelDetector.current}
              onChange={(e) => {
                agc.levelDetector.setCurrent(e.target.value)
              }}
            >
              <option value="simple">Simple</option>
              <option value="weighted">Weighted</option>
              <option value="attack / release">Attack/Release</option>
            </Select>
          </FormField>

          {agc.levelDetector.current === "simple" && (
            <FormField label="Time Constant">
              <Input
                type="number"
                step={from(MIN_SIMPLE_TIME_CONSTANT)}
                min={from(MIN_SIMPLE_TIME_CONSTANT)}
                max={from(MAX_SIMPLE_TIME_CONSTANT)}
                inputClassName="text-right"
                trailer="ms"
                value={from(agc.levelDetector.parameters.simple.timeConstant)}
                onChange={(e) => {
                  agc.levelDetector.setSimpleTimeConstant(
                    to(Number(e.target.value)),
                  )
                }}
              />
            </FormField>
          )}

          {agc.levelDetector.current === "weighted" && (
            <div className="flex flex-auto justify-between space-x-2">
              <FormField label="Slow Time Constant" className="flex-auto">
                <Input
                  className="mb-2"
                  type="number"
                  min={from(MIN_WEIGHTED_SLOW_TIME_CONSTANT)}
                  step={from(MIN_WEIGHTED_SLOW_TIME_CONSTANT)}
                  max={from(MAX_WEIGHTED_SLOW_TIME_CONSTANT)}
                  inputClassName="text-right"
                  trailer="ms"
                  value={from(
                    agc.levelDetector.parameters.weighted.slowTimeConstant,
                  )}
                  onChange={(e: FormEvent) => {
                    agc.levelDetector.setWeightedSlowTimeConstant(
                      to(Number((e.target as HTMLInputElement).value)),
                    )
                  }}
                />
              </FormField>
              <FormField label="Fast Time Constant" className="flex-auto">
                <Input
                  type="number"
                  inputClassName="text-right"
                  step={from(MIN_WEIGHTED_FAST_TIME_CONSTANT)}
                  min={from(MIN_WEIGHTED_FAST_TIME_CONSTANT)}
                  max={from(MAX_WEIGHTED_FAST_TIME_CONSTANT)}
                  trailer="ms"
                  value={from(
                    agc.levelDetector.parameters.weighted.fastTimeConstant,
                  )}
                  onChange={(e) => {
                    agc.levelDetector.setWeightedFastTimeConstant(
                      to(Number(e.target.value)),
                    )
                  }}
                />
              </FormField>
            </div>
          )}

          {agc.levelDetector.current === "attack / release" && (
            <div className="flex flex-auto space-x-2">
              <FormField label="Slow Time Constant" className="flex-auto">
                <Input
                  className="mb-2"
                  type="number"
                  step={from(MIN_ATTACK_RELEASE_SLOW_TIME_CONSTANT)}
                  min={from(MIN_ATTACK_RELEASE_SLOW_TIME_CONSTANT)}
                  max={from(MAX_ATTACK_RELEASE_SLOW_TIME_CONSTANT)}
                  inputClassName="text-right"
                  trailer="ms"
                  value={from(
                    agc.levelDetector.parameters["attack / release"]
                      .slowTimeConstant,
                  )}
                  onChange={(e) => {
                    agc.levelDetector.setAttackReleaseSlowTimeConstant(
                      to(Number(e.target.value)),
                    )
                  }}
                />
              </FormField>
              <FormField label="Fast Time Constant" className="flex-auto">
                <Input
                  type="number"
                  inputClassName="text-right"
                  step={from(MIN_ATTACK_RELEASE_FAST_TIME_CONSTANT)}
                  min={from(MIN_ATTACK_RELEASE_FAST_TIME_CONSTANT)}
                  max={from(MAX_ATTACK_RELEASE_FAST_TIME_CONSTANT)}
                  trailer="ms"
                  value={from(
                    agc.levelDetector.parameters["attack / release"]
                      .fastTimeConstant,
                  )}
                  onChange={(e) => {
                    agc.levelDetector.setAttackReleaseFastTimeConstant(
                      to(Number(e.target.value)),
                    )
                  }}
                />
              </FormField>
            </div>
          )}
        </div>
      </div>
    </Expandable>
  )
}

export default observer(AGCCard)
