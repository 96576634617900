import { useState, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import { sumBy, meanBy, groupBy } from "lodash"
import { toJS } from "mobx"

import {
  get as getMeasurementSessionType,
  MeasurementSessionTypes,
} from "../../models/MeasurementSessionType"

import { AgeRanges, findAgeRange } from "../../models/AgeRange"
import {
  get as getHearingLoss,
  getHearingLossRankKey,
  HearingLossRankProps,
} from "../../models/HearingLossRank"
import {
  Heading,
  Card,
  Legend,
  PieChart,
  Metric,
  LoadingPlaceholder,
} from "../../components"

import AgeDistribution from "./AgeDistribution"
import BarChart, { IBarChartDatum } from "./BarChart"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { IExpertLink } from "../../models/ExpertLink"
import { GenderProps, get as getGender, getName } from "../../models/Gender"
import { IPieChartDatum } from "../../components/PieChart"
import { ILegend } from "../../components/Legend"
import { IExpertLinkStore } from "../../stores/ExpertLinkStore"
import { IUser } from "../../models/User"

function histogram<T>(data: []): Map<T, number> {
  return data.reduce((result, key) => {
    if (result.has(key)) {
      const value = result.get(key)!
      result.set(key, value + 1)
    } else {
      result.set(key, 1)
    }
    return result
  }, new Map())
}

function histogramToPieDatum(
  data: Map<string, number>,
  lookup: (key: string) => Partial<IPieChartDatum> = () => ({}),
): IPieChartDatum[] {
  return Array.from(data.keys()).reduce<IPieChartDatum[]>(
    (result, key) => [
      ...result,
      {
        name: key,
        color: "#c8c8c8",
        value: data.get(key)!,
        ...lookup(key),
      },
    ],
    [],
  )
}

function getMeasurementTypeDistribution(
  expertLinks: IExpertLinkStore,
): IPieChartDatum[] {
  const data = expertLinks.patientLinks.reduce(
    (result, x) => ({
      audiometry: result.audiometry + x.user_profile.total_audiometry,
      manualinput: result.manualinput + x.user_profile.total_manual_input,
      screening: result.screening + x.user_profile.total_screening,
    }),
    {
      audiometry: 0,
      manualinput: 0,
      screening: 0,
    },
  )

  return Object.keys(data).map((key) => ({
    ...getMeasurementSessionType(key),
    value: data[key as keyof typeof data],
  }))
}

function getHearingLossByGender(
  expertLinks: IExpertLinkStore,
): IBarChartDatum[] {
  const groups = groupBy(expertLinks.patientLinks, "user_profile.gender")

  return Object.keys(groups).map((key) => {
    const value = meanBy(groups[key], "user_profile.hearing_loss")
    const rank = getHearingLoss(getHearingLossRankKey(value))

    return {
      name: getName(key)!,
      label: rank.name,
      value,
      color: rank.color,
    }
  })
}

function getHearingLossByAge(expertLinks: IExpertLinkStore): IBarChartDatum[] {
  const groups = groupBy(toJS(expertLinks.patientLinks), (x: IUser) =>
    findAgeRange(AgeRanges, x.user_profile.age ?? 0),
  )

  return Object.keys(groups).map((key) => {
    const value = meanBy(groups[key], "user_profile.hearing_loss")
    const rank = getHearingLoss(getHearingLossRankKey(value))

    return {
      name: key,
      value,
      label: rank.name,
      color: rank.color,
    }
  })
}

const ExpertDashboardScene = () => {
  const { expertLinks } = useContext(RootStoreContext)!
  const [isFetching, setFetching] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        setFetching(true)
        await expertLinks.fetchAll()
      } finally {
        setFetching(false)
      }
    }
    fetch()
  }, [expertLinks])

  const averageAge =
    expertLinks.patientLinks.length > 0
      ? Math.round(meanBy(expertLinks.patientLinks, "user_profile.age"))
      : "N/A"

  const ageDistribution = expertLinks.patientLinks.map((x: IExpertLink) => ({
    age: x.user_profile.age,
    gender: x.user_profile.gender,
  }))

  const hearingLossByAge = getHearingLossByAge(expertLinks)

  const totalMeasurements = sumBy(
    expertLinks.patientLinks,
    "user_profile.total_measurement_session",
  )
  const measurementTypeDistribution =
    getMeasurementTypeDistribution(expertLinks)

  const genderDistribution = histogramToPieDatum(
    histogram(
      expertLinks.patientLinks.map((x: IExpertLink) => x.user_profile.gender),
    ),
    (key) => getGender(key),
  )
  const hearingLossByGender = getHearingLossByGender(expertLinks)
  const hearingLossDistribution = histogramToPieDatum(
    histogram(
      expertLinks.patientLinks.map((x: IExpertLink) =>
        getHearingLossRankKey(x.user_profile.hearing_loss),
      ),
    ),
    (key) => HearingLossRankProps[key],
  )

  return (
    <>
      <Heading>Demographics</Heading>
      <div className="grid md:grid-cols-[0.25fr,1fr] gap-4">
        <div className="grid gap-4">
          <div className="grid grid-cols-2 gap-4">
            <Metric
              name="Total Users"
              value={
                <LoadingPlaceholder isLoading={isFetching}>
                  {expertLinks.patientLinks.length}
                </LoadingPlaceholder>
              }
            />
            <Metric
              name="Average Age"
              value={
                <LoadingPlaceholder isLoading={isFetching}>
                  {averageAge}
                </LoadingPlaceholder>
              }
            />
          </div>

          <Card className="flex flex-auto flex-col items-center justify-center p-8">
            <div className="font-medium text-sm">Gender Distribution</div>
            <LoadingPlaceholder isLoading={isFetching}>
              <PieChart
                className="my-8"
                data={genderDistribution as IPieChartDatum[]}
              />
            </LoadingPlaceholder>
            <Legend items={genderDistribution as ILegend[]} />
          </Card>
        </div>

        <Card className="flex flex-auto flex-col items-center justify-center p-4">
          <div className="font-medium text-sm">Age Distribution</div>
          <LoadingPlaceholder isLoading={isFetching}>
            <AgeDistribution data={ageDistribution} />
          </LoadingPlaceholder>
          <Legend items={Object.values(GenderProps)} />
        </Card>
      </div>

      <Heading className="mt-8">Performance</Heading>

      <div className="grid md:grid-cols-[0.25fr,1fr] gap-4">
        <div className="grid gap-4">
          <Metric
            name="Tests Performed"
            value={
              <LoadingPlaceholder isLoading={isFetching}>
                {totalMeasurements}
              </LoadingPlaceholder>
            }
          />

          <Card className="flex flex-auto flex-col items-center justify-center p-8">
            <div className="font-medium text-sm">Hearing Loss Distribution</div>
            <LoadingPlaceholder isLoading={isFetching}>
              <PieChart
                className="my-8"
                data={hearingLossDistribution as IPieChartDatum[]}
              />
            </LoadingPlaceholder>
            <Legend items={Object.values(HearingLossRankProps)} />
          </Card>

          <Card className="flex flex-auto flex-col items-center justify-center p-8">
            <div className="font-medium text-sm">Test Type Distribution</div>
            <LoadingPlaceholder isLoading={isFetching}>
              <PieChart className="my-8" data={measurementTypeDistribution} />
            </LoadingPlaceholder>
            <Legend items={Object.values(MeasurementSessionTypes)} />
          </Card>
        </div>

        <div className="grid gap-4">
          <Card className="flex flex-auto flex-col items-center justify-center p-4">
            <div className="font-medium text-sm">Hearing Loss By Age</div>
            <LoadingPlaceholder isLoading={isFetching}>
              <BarChart data={hearingLossByAge as IBarChartDatum[]} />
            </LoadingPlaceholder>
            <Legend items={Object.values(HearingLossRankProps)} />
          </Card>

          <Card className="flex flex-auto flex-col items-center justify-center p-8">
            <div className="font-medium text-sm">Hearing Loss By Gender</div>
            <LoadingPlaceholder isLoading={isFetching}>
              <BarChart
                className="my-8"
                data={hearingLossByGender as IBarChartDatum[]}
              />
            </LoadingPlaceholder>
            <Legend items={Object.values(HearingLossRankProps)} />
          </Card>
        </div>
      </div>
    </>
  )
}

export default observer(ExpertDashboardScene)
