import { useContext } from "react"
import { observer } from "mobx-react"
import { Outlet } from "react-router"

import Switch from "../../components/Switch"
import { Transition } from "../../components"

import UserJumbotron from "./UserJumbotron"
import ExpertJumbotron from "./ExpertJumbotron"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const LoginLayout = () => {
  const { isSigningUpAsExpert } = useContext(RootStoreContext)!

  return (
    <div className="grid flex-auto lg:grid-cols-2">
      <div className="flex flex-auto min-h-screen">
        <Switch>
          <Outlet />
        </Switch>
      </div>
      <div className="flex flex-auto flex-col min-h-screen text-white">
        <Transition>
          {isSigningUpAsExpert ? (
            <ExpertJumbotron key="expert" />
          ) : (
            <UserJumbotron key="user" />
          )}
        </Transition>
      </div>
    </div>
  )
}

export default observer(LoginLayout)
