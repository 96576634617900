import { FormEvent, MouseEvent, useContext } from "react"
import { observer, useLocalObservable } from "mobx-react"
import { isEqual } from "lodash"

import { IconPassword, IconRepeatPassword } from "../../icons"
import { useFormRequest } from "../../hooks"
import {
  ModelForm,
  ModelFormField,
  Input,
  LoadingButton,
  PasswordModelFormField,
  FloatingToolbar,
  Button,
} from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const emptyModel = () => ({
  old_password: "",
  new_password1: "",
  new_password2: "",
})

const ChangePasswordForm = () => {
  const { toasts, users } = useContext(RootStoreContext)!

  const store = useLocalObservable(() => ({
    model: emptyModel(),
    get doPasswordsMatch() {
      return store.model.new_password1 === store.model.new_password2
    },
    get isDirty() {
      return !isEqual(store.model, emptyModel())
    },
    get isValid() {
      return (
        store.model.old_password.length > 0 &&
        store.model.new_password1.length > 0 &&
        store.model.new_password2.length > 0 &&
        store.doPasswordsMatch
      )
    },
    reset() {
      store.model = emptyModel()
    },
  }))

  const request = useFormRequest(
    () => {
      store.reset()
      toasts.show("Password successfully updated.")
    },
    () => {
      toasts.show("An error occured while updating your password.", {
        accent: "danger",
      })
    },
  )

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    request.perform(users.changePassword(store.model))
  }

  return (
    <>
      <ModelForm model={store.model} errors={request.errors}>
        <ModelFormField name="old_password" label="Old Password">
          <Input
            type="password"
            autoComplete="current-password"
            icon={<IconPassword />}
          />
        </ModelFormField>

        <PasswordModelFormField name="new_password1" label="New Password">
          <Input
            type="password"
            icon={<IconPassword />}
            autoComplete="new-password"
          />
        </PasswordModelFormField>

        <ModelFormField name="new_password2" label="Confirm new password">
          <Input
            type="password"
            icon={<IconRepeatPassword />}
            autoComplete="new-password"
          />
        </ModelFormField>
      </ModelForm>

      <FloatingToolbar>
        <div className="flex space-x-4" />
        <div className="flex space-x-4">
          <Button
            outline
            shape="rect"
            className="px-16"
            disabled={request.isLoading || !store.isDirty}
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              store.reset()
            }}
          >
            Reset
          </Button>
          <LoadingButton
            shape="rect"
            className="px-16"
            isLoading={request.isLoading}
            disabled={!store.isValid}
            onClick={onSubmit}
          >
            Save
          </LoadingButton>
        </div>
      </FloatingToolbar>
    </>
  )
}

export default observer(ChangePasswordForm)
