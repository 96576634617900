import {
  types as t,
  flow,
  Instance,
  getRoot,
  IAnyModelType,
} from "mobx-state-tree"

import { User, IUser } from "../models/User"
import { baseClient } from "./baseClient"
import { RootStore } from "./RootStore"

export type ChangePasswordParams = {
  old_password: string
  new_password1: string
  new_password2: string
}

export type ConfirmPasswordResetParams = {
  new_password1: string
  new_password2: string
}

export enum UserType {
  User = "user",
  Tester = "tester",
  Expert = "expert",
}

export type RegisterParams = {
  email: string
  password: string
  password_confirm: string
  first_name: string
  last_name: string
  gender: string
  birth_year: string
  hl_since: string
  title: string
  professional_id: string
  work_place: string
  city: string
  agreement: string
  type_user: UserType
}

export type ResendActivationEmailParams = {
  email: string
}

export type ResetPasswordParams = {
  email: string
}

export type UpgradeParams = {
  title: string
  first_name: string
  last_name: string
  professional_id: string
  work_place: string
  city: string
  agreement: string
}

export const UserStore = t
  .model("UserStore", {
    userMap: t.optional(t.map(User), {}),
  })
  .views((self) => ({
    get(id: string) {
      return self.userMap.get(id)
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      put(user: IUser) {
        return self.userMap.put(user)
      },
      fetch: flow(function* (id) {
        const res = yield rootStore.client.get(`/users/${id}/`)
        self.userMap.put(res.data)
      }),
      deleteMe() {
        return rootStore.client.delete("/user/")
      },
      register(props: RegisterParams, token: string) {
        if (token) {
          return baseClient.post("/user/register/", props, {
            headers: { Authorization: `Bearer ${token}` },
          })
        }
        return baseClient.post("/user/register/", props)
      },
      resendActivationEmail(props: ResendActivationEmailParams) {
        return rootStore.client.post("/user/reactivate/", props)
      },
      activate(props: {}) {
        return rootStore.client.post("/user/activate/", props)
      },
      upgrade(id: number | string, props: UpgradeParams) {
        return rootStore.client.put(`/user/${id}/upgrade/`, props)
      },
      resetPassword(props: ResetPasswordParams) {
        return rootStore.client.post("/user/password/reset/", props)
      },
      confirmPasswordReset(props: ConfirmPasswordResetParams) {
        return rootStore.client.post("/user/password/reset/confirm/", props)
      },
      changePassword(props: ChangePasswordParams) {
        return rootStore.client.post("/user/password/change/", props)
      },
    }
  })

export interface IUserStore extends Instance<typeof UserStore> {}
