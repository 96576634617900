import { ComponentProps } from "react"

const RepeatPassword = (props: ComponentProps<"svg">) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 27 23"
    fill="currentColor"
    {...props}
  >
    <path d="M5.2 3.976L6.22 5.88l1.51-4.434L3.075.008l1.011 1.89C1.706 2.708 0 4.868 0 7.413c0 2.62 1.806 4.837 4.298 5.586-1.501-.902-2.501-2.49-2.501-4.303 0-2.155 1.415-3.98 3.403-4.72m2.584 5.057l-1.02-1.905-1.51 4.433L9.91 13l-1.012-1.89c2.381-.809 4.086-2.97 4.086-5.516 0-2.62-1.805-4.837-4.297-5.586 1.5.902 2.502 2.49 2.502 4.303 0 2.156-1.416 3.98-3.404 4.722m12.851 7.89V15.5a.63.63 0 00-.636-.625.63.63 0 00-.636.625v1.423A1.242 1.242 0 0018.727 18c0 .69.57 1.25 1.273 1.25s1.273-.56 1.273-1.25c0-.461-.257-.86-.637-1.077zm5.091 4.202a.631.631 0 01-.636.625H14.909a.631.631 0 01-.636-.625v-7.5a.63.63 0 01.636-.625h10.182c.35 0 .636.28.636.625v7.5zM16.182 8c0-2.068 1.712-3.75 3.818-3.75S23.818 5.932 23.818 8v3.75h-7.636V8zm8.909 3.75V8c0-2.757-2.284-5-5.091-5-2.807 0-5.09 2.243-5.09 5v3.75c-1.053 0-1.91.841-1.91 1.875v7.5c0 1.034.857 1.875 1.91 1.875h10.18c1.053 0 1.91-.841 1.91-1.875v-7.5c0-1.034-.857-1.875-1.91-1.875z" />
  </svg>
)

export default RepeatPassword
