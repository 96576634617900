import { ComponentProps } from "react"

import { Card, Table } from "../../components"
import { IUserProfile } from "../../models/UserProfile"

import PatientRow from "./PatientRow"

const Tr = (props: ComponentProps<"th">) => (
  <th
    className="p-4 font-semibold text-left whitespace-nowrap border-b-2"
    {...props}
  />
)

type PatientsTableProps = {
  patients: IUserProfile[]
} & ComponentProps<typeof Card>

const PatientsTable = ({ patients }: PatientsTableProps) => (
  <Card className="mb-10">
    <Table>
      <thead>
        <tr>
          <Tr>Type</Tr>
          <Tr>Name</Tr>
          <Tr>Gender</Tr>
          <Tr>Age</Tr>
          <Tr>Tests Taken</Tr>
          <Tr>Fittings</Tr>
        </tr>
      </thead>
      <tbody className="divide-y">
        {patients.map((patient) => (
          <PatientRow key={patient.id} patient={patient} />
        ))}
      </tbody>
    </Table>
  </Card>
)

export default PatientsTable
