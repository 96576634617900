import { ComponentProps } from "react"
import * as CSS from "csstype"

export type Color = CSS.DataType.Color
export type Size = CSS.Property.Width
export type SVGIcon = (props: ComponentProps<"svg">) => any

export const ChannelSides = ["right", "left"] as const
export type ChannelSide = typeof ChannelSides[number]

export type Margin = {
  left: number
  right: number
  top: number
  bottom: number
}

export type Range = [number, number]

export type Rect = {
  x0: number
  x1: number
  y0: number
  y1: number
}

export const notImplemented = () => {
  throw new Error("Not implemented")
}
