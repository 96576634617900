import { useEffect } from "react"

export function useKeyPress(
  targetKey: string,
  handler: (e: KeyboardEvent) => void,
) {
  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === targetKey) {
        handler(e)
      }
    }

    window.addEventListener("keydown", keydownHandler)

    return () => {
      window.removeEventListener("keydown", keydownHandler)
    }
  }, [handler, targetKey])
}
