import { observer } from "mobx-react"

import { Card, CardHeading } from "../../components"

import ChangePasswordForm from "./ChangePasswordForm"

const AccountPasswordScene = () => {
  return (
    <div className="grid md:grid-cols-2 gap-8">
      <Card className="p-8">
        <CardHeading className="-mt-8 -mx-8 mb-8 px-8 py-4 border-b">
          Change Password
        </CardHeading>
        <ChangePasswordForm />
      </Card>
    </div>
  )
}

export default observer(AccountPasswordScene)
