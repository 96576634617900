import { createContext } from "react"

export interface IModelFormContext<M> {
  model: M
  errors: { [key: string]: string[] }
}

const ModelFormContext = createContext<IModelFormContext<any>>({
  model: {},
  errors: {},
})

export default ModelFormContext
