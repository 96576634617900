import { FormEvent, useContext } from "react"
import { observer } from "mobx-react"
import { flatten } from "lodash"

import { useFormRequest } from "../../hooks"
import { ModelForm, Link } from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { RegisterParams } from "../../stores/UserStore"

type Step = {
  name: string
  component: any
  fields: string[]
}

export type StepProps = {
  isLoading: boolean
  onRequestBack: () => void
}

type WizardProps = {
  token: string | null
  model: RegisterParams
  activeStepNumber: number
  steps: Step[]
  onAdvanceStep: (step: number) => void
  onComplete: () => void
  onStepError?: (errors: {}) => void
}

const Wizard = ({
  token,
  model,
  activeStepNumber,
  steps,
  onAdvanceStep,
  onComplete,
  onStepError = () => {},
}: WizardProps) => {
  const { users, toasts } = useContext(RootStoreContext)!
  const activeStep = steps[activeStepNumber]

  const advanceStep = (increment = 1) => {
    onAdvanceStep(activeStepNumber + increment)
  }

  const request = useFormRequest(onComplete, () => {
    if (activeStepNumber < steps.length - 1) {
      const isActiveStepValid = activeStep.fields.every(
        (field) => !request.errors[field] || request.errors[field].length === 0,
      )

      if (isActiveStepValid) {
        request.clearErrors()
        advanceStep()
      }
    } else {
      onStepError(request.errors)
    }
  })

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const data = flatten(
      steps.filter((_, i) => i <= activeStepNumber).map((s) => s.fields),
    ).reduce(
      (result: {}, key: string) => ({
        ...result,
        [key]: model[key as keyof RegisterParams],
      }),
      {},
    )

    try {
      await request.perform(users.register(data, token))
    } catch (error: any) {
      if (error.response?.status === 401) {
        toasts.show(
          "You are not allowed to complete sign up. Your registration token might have expired.",
          {
            accent: "danger",
          },
        )
      } else {
        throw error
      }
    }
  }

  const onRequestBack = (e: MouseEvent) => {
    e.preventDefault()
    advanceStep(-1)
  }

  const { component: Step } = activeStep

  return (
    <>
      <div className="mb-6">{activeStep.name}</div>
      <ModelForm
        model={model}
        errors={request.errors}
        onSubmit={onSubmit}
        noValidate
      >
        <Step isLoading={request.isLoading} onRequestBack={onRequestBack} />
      </ModelForm>

      <p className="text-sm">
        By creating an account, you agree to the following{" "}
        <Link
          href="https://www.jacoti.com/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms
        </Link>
      </p>
    </>
  )
}

export default observer(Wizard)
