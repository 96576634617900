import { useContext } from "react"
import { observer } from "mobx-react"

import { formatDate } from "../../support/formatting"
import { CaretLink } from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const AppItem = ({
  icon,
  name,
  description,
  appKey,
  appLink,
}: {
  icon: string
  name: string
  description: string
  appKey: string
  appLink: string
}) => {
  const { applications } = useContext(RootStoreContext)!

  return (
    <div className="grid grid-cols-[auto,1fr] gap-4 px-4 py-4 border-b app-item">
      <div>
        <img width={64} height={64} src={icon} />
      </div>
      <div className="flex flex-col justify-between">
        <div>
          <div className="mb-1 font-semibold">{name}</div>
          <div className="text-sm">{description}</div>
        </div>
        <div className="text-sm">
          {applications.linkDate(appKey) ? (
            <span className="italic text-gray">
              Linked on {formatDate(applications.linkDate(appKey))}
            </span>
          ) : (
            <CaretLink href={appLink} target="_blank" rel="noopener noreferrer">
              Download
            </CaretLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(AppItem)
