import clsx from "clsx"
import ResponsiveSVG from "../ResponsiveSVG"
import {
  forwardRef,
  ElementType,
  useImperativeHandle,
  useRef,
  useState,
  ComponentProps,
} from "react"
import { AxisBottom, AxisLeft } from "@visx/axis"
import { Group } from "@visx/group"
import { Line, LinePath } from "@visx/shape"
import { Point } from "@visx/point"
import { scaleLinear } from "@visx/scale"
import { Text } from "@visx/text"
import { difference } from "lodash"
import { nanoid } from "nanoid"

import { formatFrequency } from "../../support/formatting"
import { closest } from "../../support/plotting"
import { colors } from "../../support/constants"

import Circle from "./Circle"
import Cross from "./Cross"
import ReferenceLine from "./ReferenceLine"
import audiogramScale from "./audiogramScale"
import { Range, Margin, ChannelSide, Color } from "../../support/types"
import { IAudiogram } from "../../models/Audiogram"
import { IMeasurement, MeasurementType } from "../../models/Measurement"
import Marker from "./Marker"
import { observer } from "mobx-react"
import { toJS } from "mobx"

export type Selection = {
  frequency?: number | null
  channel?: ChannelSide | null
} | null

export type OnRemoveEvent = (arg0: { frequency: number }) => void

export type OnSelectEvent = OnRemoveEvent

export type OnChangeEvent = (arg0: {
  frequency: number
  value: number
  type?: MeasurementType
}) => void

type AudiogramProps = {
  title?: string
  width?: number
  height?: number
  data: IAudiogram
  lineColor?: Color
  leftColor?: Color
  rightColor?: Color
  xDomain?: Range
  yDomain?: Range
  editable?: boolean
  selection?: Selection
  onSelect?: OnSelectEvent
  onChange?: OnChangeEvent
  onRemove?: OnRemoveEvent
  xTickLines?: number[]
  xTickLabels?: number[]
  yTickValues?: number[]
  editableXTickValues?: number[]
  ySnapping?: number
  margin?: Margin
  markerOutlineWidth?: number
} & Omit<ComponentProps<typeof ResponsiveSVG>, "onSelect" | "onChange">

const Audiogram = ({
  title,
  data,
  width = 400,
  height = 260,
  lineColor = "#b9bbbd",
  leftColor = colors["left-channel"],
  rightColor = colors["right-channel"],
  xDomain = [64, 16000],
  yDomain = [-20, 120],
  editable = false,
  selection = null,
  onSelect = () => {},
  onChange = () => {},
  onRemove = () => {},
  xTickLines = [125, 250, 500, 1000, 2000, 4000, 8000, 16000],
  xTickLabels = [125, 250, 500, 1000, 2000, 4000, 8000, 12000, 16000],
  yTickValues = [0, 20, 40, 60, 80, 100, 120],
  editableXTickValues = [
    125, 250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000, 8000, 12000,
  ],
  ySnapping = 5,
  margin: _margin = {
    top: 10,
    left: 60,
    right: 40,
    bottom: 40,
  },
  markerOutlineWidth = 3,
  className,
}: AudiogramProps) => {
  const nodeRef = useRef<SVGGElement>(null)

  const [uuid] = useState(nanoid)

  if (!data) return null

  const namespace = (r: string) => `${r}-${uuid}`
  const ref = (r: string) => `url("#${r}-${uuid}")`
  const xref = (r: string) => `#${r}-${uuid}`

  const margin = title ? { ..._margin, top: 30 } : _margin
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const x = (d: IMeasurement) => d.frequency
  const y = (d: IMeasurement) => d.value

  const xScale = audiogramScale({
    domain: xDomain,
    range: [0, xMax],
  })

  const yScale = scaleLinear({
    range: [0, yMax],
    domain: yDomain,
    nice: true,
  })

  const onClickAnywhere = (e: React.PointerEvent<SVGGElement>) => {
    if (editable && e.currentTarget.contains(e.target as Node)) {
      e.stopPropagation()
      e.preventDefault()

      const ctm = e.currentTarget.getScreenCTM()
      if (!ctm) return

      const position = {
        x: (e.clientX - ctm.e) / ctm.a,
        y: (e.clientY - ctm.f) / ctm.d,
      }

      const frequency = closest(xScale.invert(position.x), editableXTickValues)

      const value =
        Math.round(yScale.invert(position.y) / ySnapping) * ySnapping

      onChange({ frequency, value })
      onSelect({ frequency })
    }
  }

  const renderTicks = () => (
    <>
      {/*  Major X Ticks  */}
      {xTickLines.map((tick) => (
        <ReferenceLine
          key={tick}
          from={
            new Point({
              x: xScale(tick),
              y: 0,
            })
          }
          to={
            new Point({
              x: xScale(tick),
              y: yMax,
            })
          }
        />
      ))}
      {/*  Minor X Ticks  */}
      {difference(editableXTickValues, xTickLines).map((tick) => (
        <ReferenceLine
          strokeDasharray="3,2"
          key={tick}
          from={
            new Point({
              x: xScale(tick),
              y: 0,
            })
          }
          to={
            new Point({
              x: xScale(tick),
              y: yMax,
            })
          }
        />
      ))}

      {/*  Major Y Ticks  */}
      {yScale.ticks().map((tick) => (
        <ReferenceLine
          key={tick}
          from={
            new Point({
              x: 0,
              y: yScale(tick),
            })
          }
          to={
            new Point({
              x: xMax,
              y: yScale(tick),
            })
          }
          {...(!yTickValues.includes(tick) && { strokeDasharray: "3,2" })}
        />
      ))}

      {/* 0 base line */}
      <Line
        from={
          new Point({
            x: 0,
            y: yScale(0),
          })
        }
        to={
          new Point({
            x: xMax,
            y: yScale(0),
          })
        }
        stroke={lineColor}
        strokeWidth={3}
        shapeRendering="geometricprecision"
        vectorEffect="non-scaling-stroke"
      />
    </>
  )

  const renderAxis = () => (
    <g mask={ref("markers-mask")}>
      <Group left={margin.left} top={margin.top}>
        <AxisLeft
          scale={yScale}
          hideTicks
          label="Hearing Loss [dB HL]"
          labelProps={{
            fill: lineColor,
            textAnchor: "middle",
            fontFamily: "inherit",
            fontSize: 10,
          }}
          stroke={lineColor}
          tickValues={yTickValues}
          tickStroke={lineColor}
          tickLabelProps={() => ({
            fontFamily: "inherit",
            fontSize: 8,
            textAnchor: "end",
            verticalAnchor: "middle",
            fill: lineColor,
          })}
        />

        <AxisBottom
          scale={xScale}
          top={height - margin.bottom - margin.top}
          label="Frequency [Hz]"
          labelProps={{
            fill: lineColor,
            textAnchor: "middle",
            fontFamily: "inherit",
            fontSize: 10,
          }}
          stroke={lineColor}
          hideTicks
          tickValues={xTickLabels}
          tickFormat={formatFrequency}
          tickStroke={lineColor}
          tickLabelProps={() => ({
            fontFamily: "inherit",
            fontSize: 8,
            textAnchor: "middle",
            fill: lineColor,
          })}
        />
      </Group>
    </g>
  )

  const Markers = observer(
    ({
      symbol,
      channel,
      color,
    }: {
      symbol: ElementType
      channel: ChannelSide
      color: Color
    }) => {
      return (
        <g>
          {data[channel].map((d: IMeasurement, i: number) => (
            <Marker
              key={`marker-${i}`}
              channel={channel}
              value={y(d)}
              symbol={symbol}
              color={color}
              editable={editable}
              x={xScale(x(d))}
              y={yScale(y(d))}
              type={d.type as MeasurementType}
              selected={
                selection?.frequency === x(d) && selection?.channel === channel
              }
              onSelect={() => {
                onSelect(d)
              }}
              onCycle={() => {
                const types = Object.values(MeasurementType)
                const current = types.indexOf(d.type)
                const next = types[
                  current + 1 >= types.length ? 0 : current + 1
                ] as MeasurementType

                onChange({ ...d, type: next })
              }}
              onChange={(e: MouseEvent) => {
                const ctm = nodeRef.current?.getScreenCTM()
                if (!ctm) return
                const position = (e.clientY - ctm.f) / ctm.d

                onChange({
                  frequency: x(d),
                  type: d.type as MeasurementType,
                  value:
                    Math.round(
                      yScale.clamp(true).invert(position) / ySnapping,
                    ) * ySnapping,
                })
              }}
              onRemove={() => {
                onRemove(d)
              }}
            />
          ))}
        </g>
      )
    },
  )

  return (
    <ResponsiveSVG
      className={clsx("audiogram", className)}
      width={width}
      height={height}
    >
      <defs>
        <g id={namespace("right-markers")}>
          {data.right.map((d, i) => (
            <Circle
              key={i}
              x={xScale(x(d))}
              y={yScale(y(d))}
              type={d.type as MeasurementType}
            />
          ))}
        </g>

        <g id={namespace("left-markers")}>
          {data.left.map((d, i) => (
            <Cross
              key={i}
              x={xScale(x(d))}
              y={yScale(y(d))}
              type={d.type as MeasurementType}
            />
          ))}
        </g>

        <mask id={namespace("markers-mask")}>
          <rect
            x={0}
            y={0}
            width="100%"
            height="100%"
            stroke="white"
            fill="white"
          />
          <Group left={margin.left} top={margin.top}>
            <use
              xlinkHref={xref("left-markers")}
              fill="black"
              fillRule="nonzero"
              stroke="black"
              strokeWidth={markerOutlineWidth}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <use
              xlinkHref={xref("right-markers")}
              fill="black"
              fillRule="nonzero"
              stroke="black"
              strokeWidth={markerOutlineWidth}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Group>
        </mask>
      </defs>

      {renderAxis()}

      {title && (
        <Text
          x={margin.left + xMax / 2}
          y="0.8em"
          textAnchor="middle"
          verticalAnchor="start"
          width={xMax}
          fontSize={12}
          style={{
            fill: lineColor,
          }}
        >
          {title}
        </Text>
      )}

      <g mask={ref("markers-mask")}>
        <Group top={margin.top} left={margin.left}>
          <g ref={nodeRef} onPointerDown={onClickAnywhere}>
            <rect
              x={0}
              y={0}
              width={xMax}
              height={yMax}
              stroke={lineColor}
              fill="transparent"
            />

            {renderTicks()}

            <LinePath
              x={(d) => xScale(x(d))}
              y={(d) => yScale(y(d))}
              data={data.right}
              stroke={rightColor}
              strokeWidth={2}
            />
            <LinePath
              x={(d) => xScale(x(d))}
              y={(d) => yScale(y(d))}
              data={data.left}
              stroke={leftColor}
              strokeWidth={2}
            />
          </g>
        </Group>
      </g>

      <Group top={margin.top} left={margin.left}>
        <Markers symbol={Cross} channel="left" color={leftColor} />
        <Markers symbol={Circle} channel="right" color={rightColor} />
      </Group>
    </ResponsiveSVG>
  )
}

export default observer(Audiogram)
