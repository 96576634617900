import { types as t, flow, Instance, getRoot } from "mobx-state-tree"
import { Application } from "../models/Application"
import { RootStore } from "./RootStore"

export const ApplicationStore = t
  .model("ApplicationStore", {
    results: t.optional(t.array(Application), []),
  })
  .views((self) => ({
    linkDate(appKey: string) {
      const match = self.results.find((app) => app.app_name === appKey)

      if (match) {
        return match.creation_time
      }

      return null
    },
  }))
  .actions((self) => {
    return {
      fetch: flow(function* () {
        const rootStore = getRoot<Instance<typeof RootStore>>(self)
        const res = yield rootStore.client.get("/applications/")

        self.results = res.data
      }),
      clear() {
        self.results.clear()
      },
    }
  })

export interface IApplicationStore extends Instance<typeof ApplicationStore> {}
