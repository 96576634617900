import clsx from "clsx"
import { observer } from "mobx-react"
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { NavLink, NavLinkProps } from "react-router-dom"

const MenuItem = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ className, ...props }, forwardedRef) => {
    const ref = useRef<HTMLAnchorElement>(null)

    useImperativeHandle(forwardedRef, () => ref.current!)

    useEffect(() => {
      ref.current?.scrollIntoView(false)
    }, [])

    return (
      <NavLink
        ref={ref}
        className={({ isActive, isPending }) =>
          clsx(
            "menu-item",
            "px-6 py-2 text-gray hover:text-gray-darker no-underline whitespace-nowrap cursor-pointer",
            typeof className === "function"
              ? className({ isActive, isPending })
              : className,
            { "text-glacier": isActive },
          )
        }
        {...props}
      />
    )
  },
)

export default observer(MenuItem)
