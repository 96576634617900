import { Subheading } from "../../components"

const RightColumn = () => (
  <div className="leading-normal">
    <Subheading className="text-xl">
      How to connect with a Hearing Expert (it’s free!):
    </Subheading>
    <ol className="mb-8 ml-10 list-decimal">
      <li>
        Ask your hearing professional to create a Hearing Expert account at
        earCloud®
      </li>
      <li>
        Have your hearing professional send an invitation to the email account
        you’ve used in order to sign up for Jacoti’s technologies
      </li>
      <li>Accept the invitation.</li>
    </ol>
    <Subheading className="text-xl">
      Your earCloud® Hearing Expert can help you:
    </Subheading>
    <ol className="mb-8 ml-10 list-decimal">
      <li>
        Track your hearing with Jacoti Hearing Center and better assess the
        state of your hearing between appointments
      </li>
      <li>
        Remotely adjust your Jacoti ListenApp fitting, even in real-life
        situations.
      </li>
    </ol>
    Your Hearing Expert will be able to manage your hearing information but you
    will remain the sole owner of your data
  </div>
)

export default RightColumn
