import { observer } from "mobx-react"
import { ComponentProps } from "react"

import ImgEarcloud from "../assets/img/img_earcloud.svg"
import ImgHPro from "../assets/img/img_hpro.svg"
import { IUserProfile } from "../models/UserProfile"

const UserTypeImage = ({
  userProfile,
  ...props
}: {
  userProfile: IUserProfile
} & ComponentProps<"img">) =>
  userProfile.isProAccount ? (
    <img src={ImgHPro} {...props} />
  ) : (
    <img src={ImgEarcloud} {...props} />
  )
export default observer(UserTypeImage)
