import { types as t } from "mobx-state-tree"

export const Application = t.model("Application", {
  id: t.identifierNumber,
  creation_time: t.string,
  user_agent: t.string,
  app_name: t.maybeNull(t.string),
  app_version: t.maybeNull(t.string),
  device_name: t.maybeNull(t.string),
  device_version: t.maybeNull(t.string),
  os_version: t.maybeNull(t.string),
  last_access_time: t.string,
})
