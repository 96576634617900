import { observer } from "mobx-react"
import { ComponentProps } from "react"

import TabBar from "./TabBar"

const Menu = (props: Omit<ComponentProps<typeof TabBar>, "active">) => {
  return (
    <TabBar
      {...props}
      active={(x) => {
        return location.pathname === x.props.to
      }}
    />
  )
}

export default observer(Menu)
