import { ComponentProps } from "react"

import { IconCalendar } from "../icons"

import RangeSelect from "./RangeSelect"

export default (
  props: Omit<
    ComponentProps<typeof RangeSelect>,
    "min" | "max" | "icon" | "includeBlank"
  >,
) => (
  <RangeSelect
    {...props}
    includeBlank
    icon={<IconCalendar />}
    max={1900}
    min={new Date().getFullYear() - 1}
  />
)
