import { useRef, useState } from "react"
import { FaTrash } from "react-icons/fa"

import Popover from "../Popover"
import Draggable from "../Draggable"

import { Left, LeftUp, LeftDown, Right, RightUp, RightDown } from "./icons"
import { ChannelSide, Color } from "../../support/types"
import { MeasurementType } from "../../models/Measurement"
import { observer } from "mobx-react"

export type IMarker = {
  x: number
  y: number
  type: MeasurementType
}

const getCycleIcon = ({
  channel,
  type,
}: {
  channel: ChannelSide
  type: MeasurementType
}) => {
  if (channel === "left") {
    if (type === "max") {
      return Left
    }

    if (type === "min") {
      return LeftUp
    }

    return LeftDown
  }
  if (type === "max") {
    return Right
  }

  if (type === "min") {
    return RightUp
  }

  return RightDown
}

const CycleIcon = observer(
  ({ channel, type }: { channel: ChannelSide; type: MeasurementType }) => {
    const Icon = getCycleIcon({ channel, type })
    return (
      <Icon
        style={{ marginTop: 1 }}
        fillRule="evenodd"
        viewBox="0 0 22 22"
        width={16}
        height={16}
        fill="currentColor"
      />
    )
  },
)

export type EditableMarkerProps = {
  symbol: any
  channel: ChannelSide
  color: Color
  type: MeasurementType
  value: number
  x: number
  y: number
  selected: boolean
  onSelect: () => void
  onChange: (e: MouseEvent) => void
  onCycle: (e: React.MouseEvent) => void
  onRemove: (e: React.MouseEvent) => void
}

const EditableMarker = ({
  symbol: Component,
  color,
  x,
  y,
  type,
  channel,
  value,
  selected,
  onSelect,
  onChange,
  onCycle,
  onRemove,
}: EditableMarkerProps) => {
  const [popoverRef, setPopoverRef] = useState(null)

  return (
    <>
      <Draggable
        onDragStart={onSelect}
        onDragMove={onChange}
        ref={(e) => {
          setPopoverRef(e)
        }}
      >
        <Component
          cursor="row-resize"
          fillRule="evenodd"
          fill={color}
          x={x}
          y={y}
          type={type}
        />
      </Draggable>

      <Popover
        open={selected}
        key={[x, y].join()}
        referenceElement={popoverRef}
        placement="top"
        className="p-2"
      >
        <div className="flex items-center">
          <span className="mr-3">
            {Math.round(value)} <span className="text-gray">dB</span>
          </span>
          <a
            className="flex mr-2 hover:text-picton whitespace-nowrap cursor-pointer"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onCycle(e)
            }}
          >
            <CycleIcon channel={channel} type={type} />
          </a>
          <a
            className="flex hover:text-red-500 whitespace-nowrap cursor-pointer"
            style={{ marginTop: 1 }}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onRemove(e)
            }}
          >
            <FaTrash size={14} />
          </a>
        </div>
      </Popover>
    </>
  )
}

type MarkerProps = EditableMarkerProps & {
  editable: boolean
}

const Marker = ({
  editable,
  symbol: Component,
  color,
  x,
  y,
  type,
  ...props
}: MarkerProps) => {
  if (editable) {
    return (
      <EditableMarker {...{ symbol: Component, color, x, y, type, ...props }} />
    )
  }

  return <Component fillRule="evenodd" fill={color} x={x} y={y} type={type} />
}

export default observer(Marker)
