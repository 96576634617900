import { useState } from "react"

import Card from "./Card"
import Spinner from "./Spinner"
import Subheading from "./Subheading"
import MeasurementSessionList, {
  MeasurementSessionListProps,
} from "./MeasurementSessionList"
import {
  getName,
  MeasurementSessionTypes,
} from "../models/MeasurementSessionType"
import { observer } from "mobx-react"

type FilteredMeasurementSessionListProps = {
  isLoading?: boolean
  defaultQuery?: string
} & MeasurementSessionListProps

const FilteredMeasurementSessionList = ({
  isLoading = false,
  defaultQuery = "",
  measurementSessions,
  ...props
}: FilteredMeasurementSessionListProps) => {
  const [query, setQuery] = useState(defaultQuery)
  const matches = measurementSessions.filter(({ data: { labels } }) =>
    query.length > 0
      ? labels.map((l: string) => l.toLowerCase()).includes(query)
      : true,
  )

  return (
    <>
      <Subheading>
        Audiogram History
        <select
          className="ml-4 text-gray text-sm bg-transparent"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        >
          <option value="">All</option>
          {Object.keys(MeasurementSessionTypes).map((key) => (
            <option key={key} value={key}>
              {getName(key)}
            </option>
          ))}
        </select>
      </Subheading>
      {isLoading ? (
        <Card className="flex flex-auto items-center justify-center p-8">
          <Spinner size={32} />
        </Card>
      ) : (
        <MeasurementSessionList measurementSessions={matches} {...props} />
      )}
    </>
  )
}

export default observer(FilteredMeasurementSessionList)
