import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps, KeyboardEvent, FocusEvent } from "react"
import { StepUpButton, StepDownButton } from "./Stepper"

type NumberInputProps = {
  value: number
  onChange?: (value: number) => void
  onFocus?: (e: FocusEvent) => void
} & Omit<ComponentProps<"input">, "value" | "onChange" | "onFocus">

const NumberInput = ({
  className,
  disabled,
  value,
  onChange = () => {},
  onFocus = () => {},
}: NumberInputProps) => {
  const onStepUp = () => {
    onChange && onChange(value + 1)
  }

  const onStepDown = () => {
    onChange && onChange(value - 1)
  }

  const onKeyDown = (e: KeyboardEvent) => {
    // eslint-disable-next-line default-case
    switch (e.key) {
      case "ArrowUp":
        onStepUp()
        e.preventDefault()
        break
      case "ArrowDown":
        onStepDown()
        e.preventDefault()
        break
    }
  }

  return (
    <div className={clsx("flex", className)}>
      <input
        className={clsx(
          "flex-auto z-10 m-0 pr-1 h-6 w-6 text-right bg-transparent appearance-none pd-1",
          {
            "text-gray focus:outline-none": disabled,
          },
        )}
        {...{ value, disabled }}
        onKeyDown={onKeyDown}
        onChange={(e) => {
          onChange(Number(e.target.value))
        }}
        onFocus={(e) => {
          onFocus(e)
          e.target.select()
        }}
      />

      <div className="flex flex-col leading-none">
        <StepUpButton disabled={disabled} onClick={onStepUp} />
        <StepDownButton disabled={disabled} onClick={onStepDown} />
      </div>
    </div>
  )
}

export default observer(NumberInput)
