import { ComponentProps } from "react"
import { scaleLinear } from "@visx/scale"
import { animated, useSpring } from "@react-spring/web"

import {
  HearingLossRankProp,
  HearingLossRankProps,
} from "../../models/HearingLossRank"
import {
  textAnchorFromPosition,
  polarToCartesian,
} from "../../support/plotting"
import ResponsiveSVG from "../ResponsiveSVG"

import Arc from "./Arc"
import clsx from "clsx"
import { Color } from "../../support/types"

const HearingLossGauge = ({
  className,
  width = 400,
  value,
  blankStateRangeColor = "#ececec",
  needleColor = "#505050",
  ranks = Object.values(HearingLossRankProps),
}: {
  value: number
  width?: number
  blankStateRangeColor?: Color
  needleColor?: Color
  ranks?: HearingLossRankProp[]
} & ComponentProps<typeof ResponsiveSVG>) => {
  const scale = scaleLinear({
    clamp: true,
    range: [-90, 90],
    domain: [-20, 120],
  })
  const paddingLeft = width * 0.1
  const paddingRight = width * 0.1
  const paddingTop = width * 0.2

  const radius = width / 2 - paddingLeft - paddingRight
  const strokeWidth = radius * 0.5
  const needleRadius = radius * 0.075
  const paddingBottom = radius * 0.2
  const needleLength = radius * 1.975
  const needleThickness = radius * 0.03
  const labelDistance = radius * 0.1
  const fontSize = radius * 0.075

  const spring = useSpring({
    from: {
      transform: `rotate(${scale(0) - 90} ${radius} ${radius})`,
    },
    to: {
      transform: `rotate(${scale(value || 50) - 90} ${radius} ${radius})`,
    },
  })

  return (
    <ResponsiveSVG
      className={clsx("hearing-loss-gauge", className)}
      width={width}
      height={width / 2 + paddingBottom}
    >
      <g transform={`translate(${paddingLeft + paddingRight} ${paddingTop})`}>
        {value ? (
          ranks.map(({ start, end, color }, i) => (
            <Arc
              key={i}
              radius={radius}
              strokeWidth={strokeWidth}
              color={color}
              start={scale(start)}
              end={scale(end)}
            />
          ))
        ) : (
          <Arc
            radius={radius}
            strokeWidth={strokeWidth}
            color={blankStateRangeColor}
            start={-90}
            end={90}
          />
        )}

        {value &&
          ranks.map(({ start, end, short }, i) => {
            const position = scale(start) + (scale(end) - scale(start)) / 2

            const textAnchor = textAnchorFromPosition(position)
            const textPosition = polarToCartesian(
              radius,
              radius,
              radius + strokeWidth / 2 + labelDistance,
              position,
            )

            return (
              <text
                key={i}
                fontSize={fontSize}
                textAnchor={textAnchor}
                x={textPosition.x}
                y={textPosition.y}
                fill="#b9bbbd"
              >
                {short}
              </text>
            )
          })}

        <animated.g key="needle" transform={spring.transform}>
          <circle cx={radius} cy={radius} r={needleRadius} fill={needleColor} />
          <line
            x1={radius * 0.9}
            y1={radius}
            x2={needleLength}
            y2={radius}
            stroke={needleColor}
            strokeWidth={needleThickness}
            strokeLinecap="round"
          />
        </animated.g>
      </g>
    </ResponsiveSVG>
  )
}

export default HearingLossGauge
