import { observer } from "mobx-react"
import { ComponentProps, ReactNode, useRef } from "react"

import { useHover } from "../hooks"

import Popover from "./Popover"

/* istanbul ignore next */

type TooltipProps = {
  content: ReactNode
} & Omit<ComponentProps<typeof Popover>, "referenceElement">

const Tooltip = ({ children, content, ...props }: TooltipProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const isHovered = useHover(ref)

  return (
    <>
      <div ref={ref}>{children as ReactNode}</div>
      <Popover {...props} open={isHovered} referenceElement={ref.current}>
        {content}
      </Popover>
    </>
  )
}

export default observer(Tooltip)
