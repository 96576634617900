import Draggable, { DraggableProps } from "../Draggable"

type HandleProps = {
  x: number
  y: number
  width?: number
  height?: number
} & DraggableProps

const Handle = ({ x, y, width = 4, height = 20, onDragMove }: HandleProps) => (
  <Draggable onDragMove={onDragMove}>
    <rect
      cursor="col-resize"
      x={x - width / 2}
      y={y - height / 2}
      width={width}
      height={height}
    />
  </Draggable>
)

export default Handle
