import { Instance, types as t } from "mobx-state-tree"

export const MeasurementLabels = t.array(t.string)

export interface IMeasurementLabels
  extends Instance<typeof MeasurementLabels> {}

export const MeasurementData = t.model("MeasurementData", {
  labels: MeasurementLabels,
  source: t.string,
  uuid: t.string,
})

export interface IMeasurementData extends Instance<typeof MeasurementData> {}
