import { observer } from "mobx-react"
import { ComponentProps } from "react"

import InputGroup from "./InputGroup"

type TextAreaProps = ComponentProps<"textarea">

const TextArea = ({ disabled, className, ...props }: TextAreaProps) => (
  <InputGroup
    {...{
      disabled,
      className,
    }}
  >
    <textarea disabled={disabled} className="input input-textarea" {...props} />
  </InputGroup>
)

export default observer(TextArea)
