import { types as t, Instance, getRoot } from "mobx-state-tree"
import { RootStore } from "../stores/RootStore"

import { User } from "./User"
import { UserProfile } from "./UserProfile"

export const ExpertLink = t
  .model("ExpertLink", {
    id: t.identifierNumber,
    expert: t.reference(User, {
      get(id, self) {
        return getRoot<Instance<typeof RootStore>>(self).users.get(
          id.toString(),
        )
      },
      set(obj, self) {
        return getRoot<Instance<typeof RootStore>>(self).users.put(obj)
      },
    }),
    creation_time: t.string,
    accepted: t.boolean,
    user_profile: t.reference(UserProfile),
  })
  .actions((self) => ({
    remove() {
      const rootStore = getRoot<Instance<typeof RootStore>>(self)
      rootStore.expertLinks.remove(self)
    },
  }))

export interface IExpertLink extends Instance<typeof ExpertLink> {}
