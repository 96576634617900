import clsx from "clsx"
import { animated, useSpring } from "@react-spring/web"
import { ComponentProps } from "react"
import { MdArrowDropDown } from "react-icons/md"

export const AnimatedArrowIcon = animated(MdArrowDropDown)

type AnimatedArrowProps = {
  isExpanded: boolean
} & ComponentProps<typeof AnimatedArrowIcon>

const AnimatedArrow = ({
  size = 18,
  isExpanded,
  className,
  style,
  ...props
}: AnimatedArrowProps) => {
  const spring = useSpring({
    transform: `rotate(${isExpanded ? -180 : 0}deg)`,
  })

  return (
    <AnimatedArrowIcon
      className={clsx("inline", className)}
      style={{ ...style, ...spring }}
      size={size}
      {...props}
    />
  )
}

export default AnimatedArrow
