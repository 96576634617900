import {
  types as t,
  applySnapshot,
  getSnapshot,
  onSnapshot,
  Instance,
  getRoot,
  IAnyModelType,
} from "mobx-state-tree"

import { ApplicationStore } from "./ApplicationStore"
import { ExpertLinkStore } from "./ExpertLinkStore"
import { MeasurementSessionStore } from "./MeasurementSessionStore"
import { NotificationStore } from "./NotificationStore"
import { ProgramProfileStore } from "./ProgramProfileStore"
import { ToastStore } from "./ToastStore"
import { UserProfileStore } from "./UserProfileStore"
import { UserStore } from "./UserStore"

import { User, IUser } from "../models/User"
import { autorun, flow } from "mobx"

import Axios, { AxiosRequestConfig } from "axios"

export const clientSettings: AxiosRequestConfig = {
  baseURL: "/api/5/",
  headers: {
    "Content-Type": "application/json",
  },
}

const LateUserStore = t.late((): IAnyModelType => UserStore)

export const RootStore = t.snapshotProcessor(
  t
    .model("RootStore", {
      authToken: t.maybeNull(t.string),
      user: t.maybeNull(
        t.reference(User, {
          get(id: number, self) {
            return self?.users.get(id)
          },
          set(obj: IUser, self) {
            return self?.users.put(obj)
          },
        }),
      ),
      isSigningUpAsExpert: t.optional(t.boolean, false),
      isNotificationDrawerVisible: t.optional(t.boolean, false),
      toasts: t.optional(ToastStore, {}),
      applications: t.optional(ApplicationStore, {}),
      users: t.optional(LateUserStore, {}),
      userProfiles: t.optional(UserProfileStore, {}),
      notifications: t.optional(NotificationStore, {}),
      measurementSessions: t.optional(MeasurementSessionStore, {}),
      programProfiles: t.optional(ProgramProfileStore, {}),
      expertLinks: t.optional(ExpertLinkStore, {}),
    })
    .views((self) => {
      const rootStore = getRoot<Instance<typeof RootStore>>(self)
      return {
        get client() {
          if (self.authToken) {
            clientSettings.headers!.Authorization = `Bearer ${self.authToken}`
          }

          const client = Axios.create(clientSettings)

          client.interceptors.response.use(
            (response) => response,
            (error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  rootStore.logout()
                }
              }

              return Promise.reject(error)
            },
          )

          return client
        },
        get isLoggedIn() {
          return self.authToken
        },
        get isLoading() {
          return (
            (this.isLoggedIn && !self.user) ||
            (self.user && !self.user.user_profile)
          )
        },
      }
    })
    .actions((self) => ({
      reset() {
        applySnapshot(self, {})
      },
      setAuthToken(token: string) {
        self.authToken = token
      },
      setUser(user: IUser) {
        self.user = user.id
      },
    }))
    .actions((self) => {
      return {
        fetchMe: flow(function* () {
          const res = yield self.client.get("/user/")
          self.setUser(self.users.put(res.data))
        }),
        afterCreate() {
          autorun(() => {
            if (self.authToken && !self.user) {
              this.fetchMe()
            }
          })
        },
        setSigningUpAsExpert(value: boolean) {
          self.isSigningUpAsExpert = value
        },
        setNotificationDrawerVisible(value: boolean) {
          self.isNotificationDrawerVisible = value
        },
        toggleNotificationDrawer() {
          this.setNotificationDrawerVisible(!self.isNotificationDrawerVisible)
        },
        fetch() {
          self.notifications.fetch()
          self.applications.fetch()
        },
        login: flow(function* (props) {
          const res = yield self.client.post("/session/", props)
          self.setAuthToken(res.data.token)
          self.setUser(self.users.put(res.data.user))
        }),
        logout: flow(function* (deleteSession = true) {
          try {
            if (deleteSession) {
              yield self.client.delete("/session/")
            }
          } finally {
            self.reset()
          }
        }),
      }
    }),
  {
    preProcessor({ authToken }: any) {
      return { authToken }
    },
  },
)

export interface IRootStore extends Instance<typeof RootStore> {}

export function createRootStore() {
  let snapshot: Partial<IRootStore> = {}

  const localSnapshot = localStorage.getItem("rootStore")

  if (localSnapshot) {
    try {
      snapshot = JSON.parse(localSnapshot)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }

  const rootStore = RootStore.create(snapshot)

  onSnapshot(rootStore, ({ authToken }: IRootStore) => {
    localStorage.setItem("rootStore", JSON.stringify({ authToken }))
  })

  if (import.meta.hot) {
    if (import.meta.hot.data && import.meta.hot.data.rootStore) {
      applySnapshot(rootStore, import.meta.hot.data.rootStore)
    }

    import.meta.hot.dispose((data) => {
      data.rootStore = getSnapshot(rootStore)
    })
  }

  return rootStore
}
