import { ComponentProps } from "react"

import Draggable, { DraggableProps } from "../Draggable"
import { Color } from "../../support/types"

type HandleProps = {
  color: Color
} & DraggableProps &
  ComponentProps<"circle">

const Handle = ({ onDragMove, x, y, color, ...props }: HandleProps) => (
  <Draggable onDragMove={onDragMove}>
    <circle
      cursor="col-resize"
      cx={x}
      cy={y}
      fill="white"
      r={6}
      stroke={color}
      strokeWidth={3}
      {...props}
    />
  </Draggable>
)

export default Handle
