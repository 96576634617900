import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps, ReactNode } from "react"

import InputGroupContext from "./InputGroupContext"

type InputGroupProps = {
  icon?: ReactNode
  trailer?: ReactNode
  disabled?: boolean
} & ComponentProps<"div">

const InputGroup = ({
  className,
  icon,
  trailer,
  disabled,
  children,
}: InputGroupProps) => (
  <InputGroupContext.Consumer>
    {({ hasError }) => (
      <div
        className={clsx("input-group", className, {
          "with-icon": !!icon,
          "with-trailer": !!trailer,
          "has-error": hasError,
        })}
      >
        {icon && (
          <span
            className={clsx("input-group-icon", {
              "text-gray-light": disabled,
            })}
          >
            {icon}
          </span>
        )}
        {children}
        {trailer && <span className="input-group-trailer">{trailer}</span>}
      </div>
    )}
  </InputGroupContext.Consumer>
)

export default observer(InputGroup)
