import { useState, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import { useParams } from "react-router"

import { LoadingPlaceholder } from "../../components"

import Fitting from "./Fitting"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const PatientFittingScene = () => {
  const { userProfiles, programProfiles } = useContext(RootStoreContext)!

  const [isLoading, setLoading] = useState(true)
  const { id } = useParams()
  const userProfile = userProfiles.get(id)

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const prof = await userProfiles.fetch(id)
        await programProfiles.fetchByUserProfileId(id)
        await prof.fetchHistory()
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, [id, programProfiles, userProfiles])

  return (
    <LoadingPlaceholder isLoading={isLoading}>
      {() => <Fitting userProfile={userProfile} />}
    </LoadingPlaceholder>
  )
}

export default observer(PatientFittingScene)
