import { scaleLinear } from "d3-scale"
import { AxisBottom } from "@visx/axis"
import { useRef } from "react"
import { Text } from "@visx/text"
import { Group } from "@visx/group"

import { getRelativeSVGPosition } from "../../support/plotting"
import ResponsiveSVG from "../ResponsiveSVG"

import Handle from "./Handle"
import { Color, Margin } from "../../support/types"

function formatValue(value: number): string {
  if (value >= 100) return "∞"
  if (value < 5) return value.toFixed(2)
  return Math.round(value).toString()
}

type RangeProps = {
  width?: number
  height?: number
  handleWidth?: number
  handleHeight?: number
  value?: number
  format?: (datum: any) => string
  primaryColor?: Color
  tickLength?: number
  tickValues?: number[]
  barHeight?: number
  lineColor?: Color
  onChange?: (value: number) => void
  margin?: Margin
}

const Range = ({
  width = 500,
  height = 80,
  handleWidth = 5,
  handleHeight = 25,
  value = 0,
  format = formatValue,
  primaryColor = "#333",
  tickLength = 30,
  tickValues = [1, 2, 20, 100],
  barHeight = 4,
  lineColor = "#b9bbbd",
  onChange = () => {},
  margin = {
    top: 15,
    bottom: 10,
    left: 15,
    right: 15,
  },
}: RangeProps) => {
  const groupRef = useRef(null)
  const totalWidth = width - margin.right - margin.left
  const scale = scaleLinear()
    .domain([1, 2, 20, 100])
    .range([0, totalWidth * 0.5, totalWidth * 0.9, totalWidth])

  return (
    <ResponsiveSVG width={width} height={height}>
      <Group innerRef={groupRef} top={margin.top} left={margin.left}>
        <AxisBottom
          top={margin.top + handleHeight / 2 - tickLength / 2}
          scale={scale}
          stroke={primaryColor}
          strokeWidth={0}
          tickLength={tickLength}
          tickStroke={lineColor}
          tickFormat={format}
          tickLabelProps={() => ({
            dy: ".5em",
            fontFamily: "inherit",
            fontSize: 10,
            textAnchor: "middle",
            fill: lineColor,
          })}
          tickValues={tickValues}
        />

        <rect
          fill={lineColor}
          x={0}
          y={margin.top + handleHeight / 2 - barHeight / 2}
          width={totalWidth}
          height={barHeight}
        />

        <Text
          y={0}
          x={scale(value)}
          textAnchor="middle"
          verticalAnchor="middle"
          fontSize={12}
        >
          {format(value)}
        </Text>

        <Handle
          x={scale(value)}
          y={margin.top + handleHeight / 2}
          width={handleWidth}
          height={handleHeight}
          onDragMove={(e) => {
            if (!groupRef.current) return
            const position = getRelativeSVGPosition(groupRef.current, e)
            onChange(scale.clamp(true).invert(position.x))
          }}
        />
      </Group>
    </ResponsiveSVG>
  )
}

export default Range
