import { IconEmpty } from "../../icons"

const NoResultsPlaceholder = () => (
  <div className="flex flex-auto flex-col items-center justify-center py-8 text-center text-gray">
    <div className="mb-6">
      <IconEmpty width={100} />
    </div>
    <h3 className="mb-1 font-medium">NO RESULTS FOUND.</h3>
    <div>Try adjusting your search terms.</div>
  </div>
)

export default NoResultsPlaceholder
