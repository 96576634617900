import { get as _get } from "lodash"

import { colors } from "../support/constants"
import { Color } from "../support/types"

export type MeasurementSessionType = {
  name: string
  color: Color
}

export const MeasurementSessionTypes = Object.freeze({
  audiometry: { name: "Audiometry", color: "#44bba4" },
  manualinput: { name: "Manual Input", color: "#f9ef74" },
  screening: { name: "Screening", color: "#f5a624" },
})

export function get(key: any, defaultValue = {}) {
  return _get(MeasurementSessionTypes, key, defaultValue)
}

export function getName(key: any, defaultValue = "Unknown") {
  if (!key) return defaultValue
  return _get([key.toLowerCase(), "name"], defaultValue)
}

export function getColor(key: any, defaultValue = colors["gray-light"]) {
  if (!key) return defaultValue
  return _get([key.toLowerCase(), "color"], defaultValue)
}
