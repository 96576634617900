import { ReactNode, useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { Navigate, Outlet } from "react-router"

import { LoadingPlaceholder, Container, Switch } from "../../components"
import { useInterval } from "../../hooks"

import NotificationDrawer from "./NotificationDrawer"
import Header from "./Header"
import { RootStoreContext } from "../../contexts/RootStoreContext"

type RootLayoutProps = {
  subheader?: ReactNode
  subnavigation?: ReactNode
  footer?: ReactNode
  children?: ReactNode
}

const RootLayout = ({ subheader, subnavigation, footer }: RootLayoutProps) => {
  const rootStore = useContext(RootStoreContext)!


  useEffect(() => {
    rootStore.fetch()
  }, [rootStore])

  useInterval(
    () => {
      rootStore.notifications.fetch()
    },
    { immediate: false, delay: 5000 },
    [],
  )

  const onCloseNotificationDrawer = () => {
    rootStore.setNotificationDrawerVisible(false)
  }

  if (!rootStore.isLoggedIn) {
    return <Navigate to="/login" />
  }

  return (
    <LoadingPlaceholder size={64} isLoading={rootStore.isLoading}>
      {() => (
        <>
          <NotificationDrawer
            isOpen={rootStore.isNotificationDrawerVisible}
            onClose={onCloseNotificationDrawer}
          />
          <Header>{subheader}</Header>
          <div className="flex flex-auto bg-gray-lightest">
            <Container className="py-12">
              {subnavigation}
              <Switch>
                <Outlet />
              </Switch>
            </Container>
          </div>
          {footer}
        </>
      )}
    </LoadingPlaceholder>
  )
}

export default observer(RootLayout)
