import { values } from "mobx"
import { types as t, detach } from "mobx-state-tree"

import { Toast, IToast } from "../models/Toast"

export const ToastStore = t
  .model("ToastStore", {
    map: t.optional(t.map(Toast), {}),
  })
  .views((self) => ({
    get items() {
      return values(self.map) as []
    },
  }))
  .actions((self) => ({
    show(message: string, options = {}) {
      const { accent, timeout } = {
        accent: "primary",
        timeout: 6000,
        ...options,
      }
      const obj = self.map.put({
        key: Date.now().toString(),
        message,
        accent,
      })

      if (timeout) {
        setTimeout(() => {
          this.remove(obj)
        }, timeout)
      }
    },
    remove(obj: IToast) {
      detach(obj)
    },
  }))
