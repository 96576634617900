import { useContext } from "react"
import { observer } from "mobx-react"
import { MdClose } from "react-icons/md"

import { Drawer, DrawerPosition } from "../../components/Drawer"

import Notification from "./Notification"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { INotification } from "../../models/Notification"

type NotificationDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

const NotificationDrawer = ({ isOpen, onClose }: NotificationDrawerProps) => {
  const {
    notifications: { results: notifications },
  } = useContext(RootStoreContext)!

  return (
    <Drawer
      position={DrawerPosition.Left}
      className="flex flex-col bg-gray-lightest border-l shadow notification-drawer"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex items-center px-8 py-6 font-medium bg-white border-b">
        NOTIFICATIONS
        <a
          className="flex items-center ml-auto cursor-pointer"
          onClick={onClose}
        >
          <MdClose size="2rem" />
        </a>
      </div>

      {notifications.length > 0 ? (
        <div className="flex-auto overflow-hidden overflow-y-auto pb-4">
          {notifications.map((notification: INotification) => (
            <Notification key={notification.id} notification={notification} />
          ))}
        </div>
      ) : (
        <div className="flex flex-auto items-center justify-center p-4 italic text-gray">
          You currently have no new notifications.
        </div>
      )}
    </Drawer>
  )
}

export default observer(NotificationDrawer)
