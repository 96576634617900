import { ComponentProps, MouseEventHandler, useContext, useRef } from "react"
import { observer } from "mobx-react"
import { Link, useNavigate } from "react-router-dom"
import { MdArrowDropDown } from "react-icons/md"

import { useToggle } from "../../../hooks"
import {
  Avatar,
  Popover,
  Container,
  Menu,
  MenuItem,
  DropdownMenuItem,
} from "../../../components"
import ImgLogo from "../../../assets/img/img_logo_earcloud.svg"

import NavBar from "./NavBar"
import NotificationIcon from "./NotificationIcon"
import { RootStoreContext } from "../../../contexts/RootStoreContext"

const Header = ({ children }: ComponentProps<typeof NavBar>) => {
  const rootStore = useContext(RootStoreContext)!
  const navigate = useNavigate()
  const avatarRef = useRef<HTMLDivElement>(null)
  const [isDropdownVisible, toggleDropdown, setDropdownVisible] =
    useToggle(false)

  const logout = async () => {
    await rootStore.logout()
    navigate("/login", { replace: true })
  }

  return (
    <NavBar>
      <Container>
        <div className="flex overflow-hidden overflow-x-auto items-center py-3 space-between">
          <div className="hidden flex-auto sm:block">
            <Link to="/dashboard">
              <img style={{ width: 100, height: "auto" }} src={ImgLogo} />
            </Link>
          </div>
          <Menu>
            <MenuItem to="/dashboard">My Area</MenuItem>
            <MenuItem to="/expert">My Hearing Expert</MenuItem>
            {rootStore.user.isExpertOrTester && (
              <MenuItem to="/patients">My Users</MenuItem>
            )}
          </Menu>
          <div>
            <NotificationIcon
              onClick={rootStore.toggleNotificationDrawer}
              count={rootStore.notifications.results.length}
            />
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleDropdown as MouseEventHandler<HTMLDivElement>}
          >
            <Avatar ref={avatarRef}>
              {rootStore.user.user_profile.initials}
            </Avatar>
            <MdArrowDropDown className="text-gray" size={24} />
          </div>
          <Popover
            open={isDropdownVisible}
            placement="bottom"
            className="flex flex-col p-0"
            style={{ maxWidth: 300 }}
            referenceElement={avatarRef.current}
            onDismiss={() => setDropdownVisible(false)}
          >
            <div className="px-6 py-4 truncate border-b">
              <span className="font-semibold">
                {rootStore.user.user_profile.full_name}
              </span>
              <br />
              <small>{rootStore.user.email}</small>
            </div>
            <div className="flex flex-col">
              <DropdownMenuItem
                tagName={Link}
                className="mt-2 text-current hover:text-white hover:bg-picton"
                to="/account"
                onClick={toggleDropdown}
              >
                My Account
              </DropdownMenuItem>
              {rootStore.user.isExpertOrTester && (
                <DropdownMenuItem
                  target="_blank"
                  className="text-current hover:text-white hover:bg-picton"
                  rel="noopener noreferrer"
                  href="https://www.jacoti.com/support/listenapp/Jacoti_ListenApp_ExpertManual.pdf"
                >
                  Expert Guide
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                target="_blank"
                className="text-current hover:text-white hover:bg-picton"
                rel="noopener noreferrer"
                href="https://www.jacoti.com/contact/"
              >
                Support
              </DropdownMenuItem>
              <DropdownMenuItem
                className="mb-2 text-red-500 hover:text-white hover:bg-red-500"
                onClick={logout}
              >
                Log out
              </DropdownMenuItem>
            </div>
          </Popover>
        </div>
        {children}
      </Container>
    </NavBar>
  )
}

export default observer(Header)
