import clsx from "clsx"
import { ComponentProps } from "react"

const Badge = ({ className, style, ...props }: ComponentProps<"span">) => (
  <span
    className={clsx(
      "inline-flex items-center justify-center rounded-full",
      className,
    )}
    style={{
      lineHeight: 1,
      paddingLeft: 6,
      paddingRight: 6,
      paddingBottom: 3,
      paddingTop: 4,
      ...style,
    }}
    {...props}
  />
)

export default Badge
