export const colors = {
  "imperial": "#025670",
  "glacier": "#478a9e",
  "bondi": "#008ab5",
  "picton": "#36bfe8",
  "pale-glacier": "#d4e7eb",

  "left-channel": "#268ab5",
  "right-channel": "#e94f37",

  "black": "#22292f",

  "gray-darkest": "#292929",
  "gray-darker": "#424242",
  "gray-dark": "#696969", // #fakeblack
  "gray": "#aaaaaa", // #silver
  "gray-light": "#c8c8c8", // #cleargray
  "gray-lighter": "#ececec", // #notgray
  "gray-lightest": "#f9f9f9", // #notwhite
  "white": "#ffffff",
}
