import { FaQuestion } from "react-icons/fa"

import { Avatar } from "../../components"

const EmptyExpertLinksPlaceholder = () => (
  <div className="flex flex-auto items-center p-4 italic text-gray border-b">
    <Avatar color="gray" size={48} className="flex-none mr-4">
      <FaQuestion size={32} />
    </Avatar>
    You are currently not connected to a Hearing Expert.
  </div>
)

export default EmptyExpertLinksPlaceholder
