import { ComponentProps } from "react"

const Empty = (props: ComponentProps<"svg">) => (
  <svg viewBox="0 0 21 13" width="1em" height="1em" {...props}>
    <path
      d="M20.809 3.16l-.003-.005L18.526.28a.544.544 0 00-.086-.085.53.53 0 00-.408-.194H2.968a.529.529 0 00-.408.194.544.544 0 00-.086.085L.194 3.155.19 3.16a.541.541 0 00-.19.411v8.887c0 .3.24.542.535.542h19.93a.538.538 0 00.535-.542V3.571a.541.541 0 00-.191-.412zm-2.242-1.097l.767.966h-.767v-.966zm-1.07-.98V3.03h-4.005a.53.53 0 00-.318.106L10.5 5.128 7.826 3.135a.532.532 0 00-.318-.106H3.502V1.084h13.996zM2.434 3.03h-.767l.767-.966v.966zm17.497 8.887H1.07V4.113h6.263l2.85 2.124a.53.53 0 00.634 0l2.85-2.124h6.263v7.803z"
      fill="currentColor"
    />
  </svg>
)

export default Empty
