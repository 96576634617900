import { useContext } from "react"
import { inject, observer } from "mobx-react"
import { MdClear } from "react-icons/md"

import { formatDate } from "../../support/formatting"
import { Avatar, Button } from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { IExpertLink } from "../../models/ExpertLink"

const ExpertLink = ({ link }: { link: IExpertLink }) => {
  const rootStore = useContext(RootStoreContext)!

  const onDisconnect = async () => {
    if (
      confirm(
        `Are you sure you want to disconnect from ${link.expert.user_profile.full_name}?`,
      )
    ) {
      try {
        await link.remove()
        rootStore.toasts.show("Successfully disconnected.")
      } catch (err) {
        rootStore.toasts.show("An error occurred while disconnecting.", {
          accent: "danger",
        })
        console.error(err)
      }
    }
  }

  return (
    <div className="flex flex-auto items-center justify-between p-4 expert-link">
      <div className="flex items-center">
        <Avatar size={48} className="flex-none mr-4">
          {link.expert.user_profile.initials}
        </Avatar>
        <div className="flex-auto mr-4">
          <div className="font-semibold">
            {link.expert.user_profile.full_name}
          </div>
          <div>
            <div className="mb-2">{link.expert.user_profile.city}</div>
            <div className="text-gray text-sm">
              Connected {formatDate(link.creation_time)}
            </div>
          </div>
        </div>
      </div>
      <div>
        {link.accepted ? (
          <Button outline accent="danger" onClick={onDisconnect}>
            <MdClear className="sm:hidden" />
            <span className="hidden sm:inline">Disconnect</span>
          </Button>
        ) : (
          <Button outline disabled accent="secondary">
            Pending
          </Button>
        )}
      </div>
    </div>
  )
}

export default observer(ExpertLink)
