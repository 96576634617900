import { useContext } from "react"
import { observer, useLocalObservable } from "mobx-react"
import { useLocation } from "react-router"

import { useAutorun, useFormRequest } from "../../hooks"
import ImgEarcloud from "../../assets/img/img_logo_earcloud.svg"
import ImgHPro from "../../assets/img/img_footer_hpro-logo.svg"
import { Link, Button } from "../../components"

import STEPS from "./Steps"
import Wizard from "./Wizard"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { RegisterParams, UserType } from "../../stores/UserStore"

export type RegisterSceneModel = RegisterParams & {
  update(props: Partial<RegisterParams>): void
}

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const RegisterScene = () => {
  const { users, toasts, setSigningUpAsExpert } = useContext(RootStoreContext)!
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const as = capitalize(params.get("as")?? UserType.User)
  const userType = UserType[as as keyof typeof UserType] ?? UserType.User

  const store = useLocalObservable(() => ({
    activeStepNumber: 0,
    isSignupComplete: false,
    token: params.get("token"),
    model: {
      email: params.get("email") || "",
      password: "",
      password_confirm: "",
      first_name: "",
      last_name: "",
      gender: "",
      birth_year: "",
      hl_since: "",
      title: "",
      professional_id: "",
      work_place: "",
      city: "",
      agreement: "",
      type_user: userType,
      update(props: RegisterParams) {
        Object.assign(store.model, props)
      },
    },
    setActiveStepNumber(number: number) {
      store.activeStepNumber = number
    },
    setSignupComplete(value: boolean) {
      store.isSignupComplete = value
    },
    get steps() {
      return STEPS[store.model.type_user as keyof typeof STEPS]
    },
  }))

  useAutorun(() => {
    setSigningUpAsExpert(store.model.type_user === "expert")
  })

  const request = useFormRequest(
    () => {
      toasts.show(`Account activation email sent to ${store.model.email}`)
    },
    (err: any) => {
      if (err.message) {
        toasts.show(err.message, {
          accent: "danger",
        })
      } else {
        toasts.show("An error occurred while trying to send the email", {
          accent: "danger",
        })
      }
    },
  )

  const onRequestResend = async () => {
    request.perform(users.resendActivationEmail({ email: store.model.email }))
  }

  return (
    <div className="flex flex-auto flex-col items-center justify-center px-8">
      <div className="flex flex-auto flex-col items-center justify-end">
        <img className="mt-8" src={ImgEarcloud} />

        {store.model.type_user === "tester" && (
          <img className="mt-4 h-5" src={ImgHPro} />
        )}
      </div>

      <div className="mt-10 w-full md:w-3/4 sm:w-2/3 xxl:w-1/3">
        <h3>SIGN UP</h3>

        {store.isSignupComplete && (
          <div className="mt-8 leading-normal">
            <p className="mb-4 font-medium text-lg">
              {store.model.first_name},<br />
              Thanks so much for joining us.
            </p>
            <p className="mb-4">
              We have sent a confirmation email to {store.model.email}
            </p>
            <p className="mb-16">
              Click the link in that email to finish registering. If you don’t
              receive an email from us soon, please check your spam mailbox to
              make sure it wasn’t filtered.
            </p>

            <div className="flex justify-center">
              <Button outline onClick={onRequestResend}>
                RESEND ACTIVATION EMAIL
              </Button>
            </div>
          </div>
        )}

        {!store.isSignupComplete && (
          <Wizard
            token={store.token}
            model={store.model}
            steps={store.steps}
            activeStepNumber={store.activeStepNumber}
            onAdvanceStep={(stepNumber) => {
              store.setActiveStepNumber(stepNumber)
            }}
            onComplete={() => {
              store.setSignupComplete(true)
            }}
          />
        )}
      </div>

      <p className="flex flex-auto flex-col justify-end p-8 text-center">
        <span className="mb-4">Already have an account?</span>
        <Link to="/login">Sign in</Link>
      </p>
    </div>
  )
}

export default observer(RegisterScene)
