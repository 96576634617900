import { useRef, useMemo, ComponentProps } from "react"
import { scaleBand, scaleLinear } from "@visx/scale"
import { AxisLeft, AxisBottom } from "@visx/axis"
import { Group } from "@visx/group"
import { GridRows } from "@visx/grid"

import { useHover } from "../../hooks"
import { getSVGPointAsFloatingRef } from "../../support/plotting"
import { Popover, ResponsiveSVG } from "../../components"
import { LegendItem } from "../../components/Legend"
import { Color, Margin } from "../../support/types"

export type IBarChartDatum = {
  name: string
  value: number
  label: string
  color: Color
}

const Bar = ({
  label,
  color,
  width,
  height,
  x,
  y,
}: {
  label: string
  color?: Color
  width: number
  height: number
  x: number
  y: number
}) => {
  const ref = useRef<SVGRectElement>(null)
  const isHovered = useHover(ref)
  const popoverRef = getSVGPointAsFloatingRef(ref.current, x + width / 2, y)

  return (
    <>
      <rect ref={ref} x={x} y={y} height={height} width={width} fill={color} />
      <Popover
        open={isHovered}
        placement="top"
        className="flex flex-col p-2 text-sm pointer-events-none"
        referenceElement={popoverRef}
      >
        <LegendItem color={color}>{label}</LegendItem>
      </Popover>
    </>
  )
}

const BarChart = ({
  width = 600,
  height = 300,
  lineColor = "#b9bbbd",
  data = [],
  margin = {
    top: 40,
    left: 40,
    bottom: 40,
    right: 0,
  },
  ...props
}: {
  width?: number
  height?: number
  margin?: Margin
  lineColor?: Color
  data?: IBarChartDatum[]
} & ComponentProps<typeof ResponsiveSVG>) => {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const x = (d: IBarChartDatum) => d.name
  const y = (d: IBarChartDatum) => d.value

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        domain: data.map(x),
        round: true,
        padding: 0.2,
      }),
    [data, xMax],
  )

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [0, yMax],
        domain: [Math.max(...data.map(y)), 0],
        round: true,
        nice: true,
        clamp: true,
      }),
    [data, yMax],
  )

  return (
    <ResponsiveSVG {...props} width={width} height={height}>
      <GridRows
        top={margin.top}
        left={margin.left}
        scale={yScale}
        width={xMax}
        stroke={lineColor}
        strokeOpacity={0.4}
      />

      <Group left={margin.left} top={margin.top}>
        {data.map((d, i) => {
          const xVal = xScale(x(d))
          const barHeight = yMax - (yScale(y(d)) ?? 0)

          if (!xVal) return null

          return (
            <Bar
              {...d}
              key={`bar-${i}`}
              label={d.name}
              width={xScale.bandwidth()}
              height={barHeight}
              x={xVal}
              y={yMax - barHeight}
            />
          )
        })}
      </Group>

      <AxisLeft
        scale={yScale}
        top={margin.top}
        left={margin.left}
        hideTicks
        labelProps={{
          fill: lineColor,
          textAnchor: "middle",
          fontFamily: "inherit",
          fontSize: 10,
        }}
        stroke="none"
        tickStroke={lineColor}
        tickLabelProps={() => ({
          fontFamily: "inherit",
          fontSize: 10,
          textAnchor: "end",
          verticalAnchor: "middle",
          fill: lineColor,
        })}
      />

      <AxisBottom
        left={margin.left}
        top={yMax + margin.top}
        scale={xScale}
        hideTicks
        labelProps={{
          fill: lineColor,
          textAnchor: "middle",
          fontFamily: "inherit",
          fontSize: 10,
        }}
        tickStroke={lineColor}
        tickLabelProps={() => ({
          fontFamily: "inherit",
          fontSize: 10,
          textAnchor: "middle",
          verticalAnchor: "middle",
          fill: lineColor,
        })}
        stroke={lineColor}
      />
    </ResponsiveSVG>
  )
}

export default BarChart
