import { ComponentProps } from "react"

const Title = (props: ComponentProps<"svg">) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 18"
    fill="currentColor"
    {...props}
  >
    <path d="M4.967 3.925c0-.011.004-.022.004-.03C5.098 1.273 7.065.991 7.907.991h.046c1.044.022 2.818.424 2.938 2.904 0 .011 0 .022.004.03.003.025.274 2.513-.955 3.822-.487.52-1.136.776-1.99.783h-.039c-.85-.007-1.503-.263-1.986-.783-1.225-1.302-.962-3.8-.958-3.822zm3.025 5.593c1.133-.018 2.05-.395 2.725-1.116 1.488-1.587 1.24-4.31 1.213-4.569-.096-1.95-1.07-2.882-1.874-3.318C9.457.19 8.758.015 7.977 0h-.066c-.429 0-1.27.065-2.079.5-.81.436-1.8 1.369-1.897 3.333-.027.26-.274 2.982 1.213 4.57.673.72 1.59 1.097 2.72 1.115h.124z" />
    <path d="M15.872 14.032v-.011c0-.03-.004-.059-.004-.092-.023-.724-.073-2.418-1.75-2.959l-.04-.01c-1.742-.422-3.19-1.373-3.207-1.384a.54.54 0 00-.726.121.476.476 0 00.127.688c.066.044 1.604 1.057 3.53 1.525.9.304 1 1.215 1.026 2.049 0 .033 0 .062.004.091.004.33-.019.838-.08 1.13-.627.337-3.08 1.5-6.814 1.5-3.717 0-6.186-1.167-6.816-1.503a5.764 5.764 0 01-.08-1.13l.002-.092c.027-.834.128-1.745 1.028-2.048 1.925-.469 3.463-1.486 3.528-1.526a.477.477 0 00.128-.688.54.54 0 00-.727-.12c-.015.01-1.456.962-3.207 1.382a.481.481 0 00-.039.011c-1.677.545-1.727 2.24-1.75 2.96 0 .033 0 .062-.004.091v.011c-.004.19-.008 1.167.197 1.657a.47.47 0 00.201.23c.116.074 2.894 1.75 7.543 1.75 4.65 0 7.427-1.68 7.543-1.75a.497.497 0 00.201-.23c.194-.486.19-1.463.186-1.653zm.33-8.165L21.734.322l.628.63-6.07 6.083-.092-.092-.09.092L13 3.918l.627-.63L16.2 5.867z" />
  </svg>
)

export default Title
