export const AgeRanges = [
  "<18",
  "18-24",
  "25-30",
  "31-40",
  "41-50",
  "51-60",
  "61-70",
  "71-80",
  ">80",
]

export type AgeRange = keyof typeof AgeRanges

const evaluateRange = (expr: string) => {
  if (expr.includes("-")) {
    const [min, max] = expr.split("-")

    return (x: number) => x >= Number(min) && x <= Number(max)
  }
  if (expr.startsWith(">")) {
    return (x: number) => x > Number(expr.slice(1))
  }
  if (expr.startsWith("<")) {
    return (x: number) => x < Number(expr.slice(1))
  }
  throw new Error(`Unable to parse expression '${expr}'`)
}

export const findAgeRange = (ranges: string[], value: number) => {
  const range = ranges.find((b) => evaluateRange(b)(value))
  if (range) return range as AgeRange
  return null
}
