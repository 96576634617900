import { useState, useEffect, useContext } from "react"
import { observer } from "mobx-react"
import { useParams } from "react-router-dom"
import { Outlet } from "react-router"

import { LoadingPlaceholder, Switch } from "../../components"
import RootLayout from "../RootLayout"

import SubHeader from "./SubHeader"
import SubNavigation from "./SubNavigation"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const PatientLayout = ({ ...props }) => {
  const { userProfiles } = useContext(RootStoreContext)!

  const { id } = useParams()
  const userProfile = userProfiles.get(id)

  const [isFetching, setFetching] = useState(true)

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setFetching(true)
        await userProfiles.fetch(id)
      } finally {
        setFetching(false)
      }
    }

    fetchUserProfile()
  }, [id, userProfiles])

  return (
    <RootLayout
      subheader={isFetching ? null : <SubHeader userProfile={userProfile} />}
      subnavigation={
        isFetching ? null : <SubNavigation userProfile={userProfile} />
      }
      {...props}
    >
      <Switch>
        <LoadingPlaceholder isLoading={isFetching}>
          {() => <Outlet />}
        </LoadingPlaceholder>
      </Switch>
    </RootLayout>
  )
}

export default observer(PatientLayout)
