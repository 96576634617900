import clsx from "clsx"
import { isFunction } from "lodash"
import { observer, Observer } from "mobx-react"
import { ComponentProps, ReactElement, ReactNode } from "react"

import Spinner from "./Spinner"

type LoadingPlaceholderProps = {
  isLoading?: boolean
  size?: number
  children?: ReactNode | (() => ReactElement)
} & Omit<ComponentProps<"div">, "children">

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({
  isLoading = true,
  size = 48,
  children,
  className,
}) => {
  if (isLoading)
    return (
      <div
        className={clsx(
          "flex flex-auto items-center justify-center p-8",
          className,
        )}
      >
        <Spinner size={size} />
      </div>
    )

  if (isFunction(children)) {
    return <Observer>{children}</Observer>
  }

  return <>{children}</>
}

export default observer(LoadingPlaceholder)
