import { ComponentProps } from "react"

const Notification = (props: ComponentProps<"svg">) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="currentColor" cx={12} cy={12} r={12} />
      <path
        d="M11.6 19c.77 0 1.4-.63 1.4-1.4h-2.8c0 .77.623 1.4 1.4 1.4zm4.2-4.2v-3.5c0-2.149-1.148-3.948-3.15-4.424V6.4c0-.581-.469-1.05-1.05-1.05-.581 0-1.05.469-1.05 1.05v.476C8.541 7.352 7.4 9.144 7.4 11.3v3.5L6 16.2v.7h11.2v-.7l-1.4-1.4z"
        fillRule="nonzero"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default Notification
