import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps } from "react"
import {
  getColor,
  getHearingLossRankKey,
  getName,
} from "../models/HearingLossRank"

import Indicator from "./Indicator"

const HearingLossRank = ({
  hearingLoss,
  style,
  className,
  ...props
}: {
  hearingLoss: number
} & ComponentProps<"span">) => {
  const rank = getHearingLossRankKey(hearingLoss)

  return (
    <span className={clsx("flex items-center", className)} {...props}>
      <Indicator className="mr-2" style={{ backgroundColor: getColor(rank) }} />
      <span>{getName(rank)}</span>
    </span>
  )
}

export default observer(HearingLossRank)
