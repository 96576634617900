import { ReactNode } from "react"

const AppItem = ({ icon, name }: { icon: string; name: ReactNode }) => (
  <div className="flex flex-col items-center p-2 w-1/3 text-center">
    <img className="mb-4" width={64} height={64} src={icon} />
    <span className="font-semibold">{name}</span>
  </div>
)

export default AppItem
