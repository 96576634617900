import { Menu, MenuItem } from "../../components"

export default () => (
  <div className="flex">
    <Menu
      className="overflow-hidden overflow-x-auto -mt-12 mb-8 uppercase subnavigation"
      position="top"
    >
      <MenuItem to="/account">Account Details</MenuItem>
      <MenuItem to="/account/password">Password</MenuItem>
    </Menu>
  </div>
)
