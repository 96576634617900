import { observer } from "mobx-react"
import { ComponentProps } from "react"
import { createPortal } from "react-dom"

import Card from "./Card"

const FloatingToolbar = ({ ...props }: ComponentProps<typeof Card>) =>
  createPortal(
    <div className="fixed bottom-4 left-0 right-0 z-30">
      <div className="container mx-auto">
        <Card
          className="flex justify-between px-8 py-4 space-x-8 h-auto shadow-xl"
          {...props}
        />
      </div>
    </div>,
    document.querySelector("#root")!,
  )

export default observer(FloatingToolbar)
