import { map, max } from "lodash"
import { types as t, getSnapshot, Instance } from "mobx-state-tree"

import {
  Measurement,
  IMeasurement,
  MeasurementType,
  MeasurementMap,
} from "./Measurement"

export type HKLabel = "ManualInput" | "Test" | "Unknown"
export type HKThresholdValue = string | number
export type HKThreshold =
  | ["-inf", HKThresholdValue]
  | [HKThresholdValue, "inf"]
  | HKThresholdValue

export type HKMeasurement = {
  frequency: number
  threshold: HKThreshold
  // datetime: string
  // ear: string
  // type: "hearingthreshold"
}

export type HKAudiogram = {
  left: MeasurementMap
  right: MeasurementMap
}

function fromHKMeasurement({
  frequency,
  threshold: valueOrThreshold,
}: HKMeasurement): IMeasurement {
  if (Array.isArray(valueOrThreshold)) {
    const [minValue, maxValue] = valueOrThreshold

    if (minValue === "-inf") {
      return { frequency, value: Number(maxValue), type: MeasurementType.Max }
    }
    if (maxValue === "inf") {
      return { frequency, value: Number(minValue), type: MeasurementType.Min }
    }
    throw new Error("Unable to handle threshold value")
  }

  return {
    frequency,
    value: Number(valueOrThreshold),
    type: MeasurementType.Value,
  }
}

type HKMeasurementCallback = (x: HKMeasurement) => IMeasurement
export function fromHKAudiogram(data: HKAudiogram): IAudiogram {
  const map = (
    measurements: MeasurementMap,
    handler: HKMeasurementCallback,
  ) => {
    return Object.keys(measurements).map((key) => {
      return handler({
        frequency: Number(key),
        threshold: measurements[key as keyof MeasurementMap],
      })
    })
  }

  return {
    left: map(data.left, fromHKMeasurement),
    right: map(data.right, fromHKMeasurement),
  } as IAudiogram
}

const AudiogramChannel = t.array(Measurement)
export interface IAudiogramChannel extends Instance<typeof AudiogramChannel> {}

export enum Ear {
  Left = "left",
  Right = "right",
  Equal = "equal",
  Unknown = "unknown",
}

export const Audiogram = t
  .model("Audiogram", {
    right: AudiogramChannel,
    left: AudiogramChannel,
  })
  .views((self) => ({
    get isEmpty() {
      return self.left.length === 0 && self.right.length === 0
    },
    get bestEar() {
      if (this.worstEar === Ear.Right) {
        return Ear.Left
      }
      if (this.worstEar === Ear.Left) {
        return Ear.Right
      }

      return this.worstEar
    },
    get worstEar(): Ear {
      if (self.left.length === 0 && self.right.length === 0) {
        return Ear.Unknown
      }

      const left = max(map(self.left, "value"))
      const right = max(map(self.right, "value"))

      if (!left || !right) return Ear.Unknown

      if (left > right) {
        return Ear.Left
      }
      if (right > left) {
        return Ear.Right
      }

      return Ear.Equal
    },
    get hearingLoss(): number | undefined {
      if (!this.worstEar) return
      switch (this.worstEar) {
        case Ear.Left:
        case Ear.Right:
          return max(map(self[this.worstEar], "value"))
        default:
          return
      }
    },
    get leftHearingLoss(): number | undefined {
      return max(map(self.left, "value"))
    },
    get rightHearingLoss(): number | undefined {
      return max(map(self.right, "value"))
    },
    get leftOnly(): IAudiogram {
      return {
        left: getSnapshot(self.left),
        right: [] as unknown,
      } as IAudiogram
    },
    get rightOnly(): IAudiogram {
      return {
        left: [] as unknown,
        right: getSnapshot(self.right),
      } as IAudiogram
    },
  }))

export interface IAudiogram extends Instance<typeof Audiogram> {}
