import { Instance, types as t } from "mobx-state-tree"

import { GainFunction } from "./GainFunction"
import { LevelDetector } from "./LevelDetector"

export const AGC = t.model("AGC", {
  levelDetector: LevelDetector,
  gainFunction: GainFunction,
})

export interface IAGC extends Instance<typeof AGC> { }
