import formatDistanceToNow from "date-fns/formatDistanceToNow"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"

type DateLike = number | string | Date

function ensureDate(dateOrString: DateLike) {
  let date = dateOrString
  if (typeof date === "string") {
    date = parseISO(dateOrString as string)
  }
  return date
}

export function formatDate(date: DateLike) {
  return format(ensureDate(date), "MM/dd/yyyy")
}

export function formatDateWithTime(date: DateLike) {
  return format(ensureDate(date), "MM/dd/yyyy HH:mm")
}

export function toHKDate(date: DateLike): string {
  return format(ensureDate(date), "yyyy-MM-dd'T'HH:mm:ssXX")
}

export function formatRelativeDate(date: DateLike, opts = { addSuffix: true }) {
  return formatDistanceToNow(ensureDate(date), opts)
}

export function formatFrequency(input: number): string {
  if (input >= 1000) {
    const result = (Number(input) / 1000).toFixed(1)
    const [value, rest] = result.split(".")

    if (Number(rest) > 0) {
      return result
    }

    return `${value}k`
  }

  return input.toString()
}
