import { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"

import { LoadingPlaceholder } from "../../components"

import NewAudiogram from "./NewAudiogram"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const PatientNewAudiogramsScene = () => {
  const { userProfiles } = useContext(RootStoreContext)!

  const { id } = useParams()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true)
        await userProfiles.fetch(id)
        await userProfiles.audiograms.fetch(id)
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, [id, userProfiles])

  return (
    <LoadingPlaceholder isLoading={isLoading}>
      {() => <NewAudiogram userProfile={userProfiles.get(id)} />}
    </LoadingPlaceholder>
  )
}

export default observer(PatientNewAudiogramsScene)
