import { useEffect } from "react"

export function useOnClickOutside(
  node: Element | null | undefined,
  handler: (event: MouseEvent) => void,
  bypassSelector?: string,
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (node && event.target) {
        const target = event.target as Element
        if (
          !node.contains(target) ||
          (bypassSelector && target.closest(bypassSelector))
        ) {
          handler(event)
        }
      }
    }
    document.addEventListener("mousedown", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
    }
  }, [node, handler, bypassSelector])
}
