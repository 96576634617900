import { ComponentProps, createRef, useRef, useState } from "react"
import { Pie } from "@visx/shape"
import { Group } from "@visx/group"

import { useHover } from "../hooks"
import { getSVGPointAsFloatingRef } from "../support/plotting"

import Popover from "./Popover"
import ResponsiveSVG from "./ResponsiveSVG"
import { LegendItem } from "./Legend"
import { PieArcDatum, ProvidedProps } from "@visx/shape/lib/shapes/Pie"
import { Color } from "../support/types"
import { observer } from "mobx-react"

export type IPieChartDatum = {
  name: string
  value: number
  color: Color
}

type ArcProps = {
  pie: ProvidedProps<IPieChartDatum>
  arc: PieArcDatum<IPieChartDatum>
}

const Arc = observer(({ pie, arc }: ArcProps) => {
  const ref = useRef<SVGPathElement | null>(null)
  const isHovered = useHover(ref)
  const [x, y] = pie.path.centroid(arc)
  const popoverRef = getSVGPointAsFloatingRef(ref.current, x, y)

  return (
    <>
      <path ref={ref} fill={arc.data.color} d={pie.path(arc) ?? undefined} />
      <Popover
        open={isHovered}
        className="flex p-2 text-sm pointer-events-none"
        placement="top"
        referenceElement={popoverRef}
      >
        <LegendItem color={arc.data.color}>
          <div className="mr-2">{arc.data.name}</div>
          <div className="font-medium">{arc.data.value}</div>
        </LegendItem>
      </Popover>
    </>
  )
})

type PieChartProps = {
  width?: number
  height?: number
  data: IPieChartDatum[]
} & ComponentProps<typeof ResponsiveSVG>

const PieChart = ({
  width = 200,
  height = 200,
  data,
  ...props
}: PieChartProps) => {
  const radius = Math.min(width, height) / 2
  const centerY = height / 2
  const centerX = width / 2

  return (
    <ResponsiveSVG width={width} height={height} {...props}>
      <Group top={centerY} left={centerX}>
        <Pie
          data={data}
          pieValue={(x) => x.value}
          outerRadius={radius}
          innerRadius={radius - 40}
        >
          {(pie) =>
            pie.arcs.map((arc, i) => (
              <Arc key={`arc-${i}`} pie={pie} arc={arc} />
            ))
          }
        </Pie>
      </Group>
    </ResponsiveSVG>
  )
}

export default observer(PieChart)
