import clsx from "clsx"
import { ComponentProps } from "react"
import { animated } from "@react-spring/web"
import { MdClose } from "react-icons/md"
import { observer } from "mobx-react"

export const accents = {
  primary: "bg-green-500 text-white",
  danger: "bg-red-500 text-white",
}

export const ToastAccents = Object.keys(accents)

export type ToastAccent = keyof typeof accents

type ToastProps = {
  accent: ToastAccent
  onDismiss: () => void
} & ComponentProps<typeof animated.div>

const Toast = ({
  className,
  accent = "primary",
  children,
  onDismiss = () => {},
  ...props
}: ToastProps) => (
  <animated.div
    className={clsx(
      "toast",
      "mx-4 my-1 px-4 py-2 md:max-w-prose font-semibold text-center rounded shadow-lg",
      accents[accent],
      className,
    )}
    {...props}
  >
    <>
      {children}
      <a
        className="ml-4 cursor-pointer"
        style={{ pointerEvents: "auto" }}
        onClick={onDismiss}
      >
        <MdClose className="inline" />
      </a>
    </>
  </animated.div>
)

export default observer(Toast)
