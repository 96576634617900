import clsx from "clsx"
import { ComponentProps } from "react"

const Container = ({ className, ...props }: ComponentProps<"div">) => (
  <div
    className={clsx(
      "container flex flex-auto flex-col mx-auto px-4",
      className,
    )}
    {...props}
  />
)

export default Container
