import { types as t } from "mobx-state-tree"

import { clamper } from "../support/plotting"

export const MIN_ATTACK_RELEASE_FAST_TIME_CONSTANT = 0.001
export const MAX_ATTACK_RELEASE_FAST_TIME_CONSTANT = 0.1
export const MIN_ATTACK_RELEASE_SLOW_TIME_CONSTANT = 0.01
export const MAX_ATTACK_RELEASE_SLOW_TIME_CONSTANT = 0.5

export const MIN_WEIGHTED_FAST_TIME_CONSTANT = 0.001
export const MAX_WEIGHTED_FAST_TIME_CONSTANT = 0.1
export const MIN_WEIGHTED_SLOW_TIME_CONSTANT = 0.01
export const MAX_WEIGHTED_SLOW_TIME_CONSTANT = 0.5

export const MIN_SIMPLE_TIME_CONSTANT = 0.001
export const MAX_SIMPLE_TIME_CONSTANT = 0.2

const simpleTimeConstantClamper = clamper(
  MIN_SIMPLE_TIME_CONSTANT,
  MAX_SIMPLE_TIME_CONSTANT,
)

const weightedSlowTimeConstantClamper = clamper(
  MIN_WEIGHTED_SLOW_TIME_CONSTANT,
  MAX_WEIGHTED_SLOW_TIME_CONSTANT,
)

const weightedFastTimeConstantClamper = clamper(
  MIN_WEIGHTED_FAST_TIME_CONSTANT,
  MAX_WEIGHTED_FAST_TIME_CONSTANT,
)

const attackReleaseSlowTimeConstantClamper = clamper(
  MIN_ATTACK_RELEASE_SLOW_TIME_CONSTANT,
  MAX_ATTACK_RELEASE_SLOW_TIME_CONSTANT,
)

const attackReleaseFastTimeConstantClamper = clamper(
  MIN_ATTACK_RELEASE_FAST_TIME_CONSTANT,
  MAX_ATTACK_RELEASE_FAST_TIME_CONSTANT,
)

export const LevelDetector = t
  .model({
    current: t.string,
    parameters: t.model({
      weighted: t.model({
        slowTimeConstant: t.number,
        fastTimeConstant: t.number,
      }),
      "attack / release": t.model({
        slowTimeConstant: t.number,
        fastTimeConstant: t.number,
      }),
      simple: t.model({
        timeConstant: t.number,
      }),
    }),
  })
  .actions((self) => ({
    setCurrent(value: string) {
      self.current = value
    },
    setSimpleTimeConstant(value: number) {
      self.parameters.simple.timeConstant = simpleTimeConstantClamper(value)
    },
    setWeightedSlowTimeConstant(value: number) {
      self.parameters.weighted.slowTimeConstant =
        weightedSlowTimeConstantClamper(value)
    },
    setWeightedFastTimeConstant(value: number) {
      self.parameters.weighted.fastTimeConstant =
        weightedFastTimeConstantClamper(value)
    },
    setAttackReleaseSlowTimeConstant(value: number) {
      self.parameters["attack / release"].slowTimeConstant =
        attackReleaseSlowTimeConstantClamper(value)
    },
    setAttackReleaseFastTimeConstant(value: number) {
      self.parameters["attack / release"].fastTimeConstant =
        attackReleaseFastTimeConstantClamper(value)
    },
  }))
