import { ComponentProps } from "react"

const Password = (props: ComponentProps<"svg">) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 20"
    fill="currentColor"
    {...props}
  >
    <path d="M7.636 13.923V12.5A.63.63 0 007 11.875a.63.63 0 00-.636.625v1.423A1.242 1.242 0 005.727 15c0 .69.57 1.25 1.273 1.25s1.273-.56 1.273-1.25c0-.461-.257-.86-.637-1.077zm5.091 4.202a.631.631 0 01-.636.625H1.909a.631.631 0 01-.636-.625v-7.5A.63.63 0 011.909 10h10.182c.35 0 .636.28.636.625v7.5zM3.182 5c0-2.068 1.712-3.75 3.818-3.75S10.818 2.932 10.818 5v3.75H3.182V5zm8.909 3.75V5c0-2.757-2.284-5-5.091-5-2.807 0-5.09 2.243-5.09 5v3.75C.856 8.75 0 9.591 0 10.625v7.5C0 19.159.857 20 1.91 20h10.18c1.053 0 1.91-.841 1.91-1.875v-7.5c0-1.034-.857-1.875-1.91-1.875z" />
  </svg>
)

export default Password
