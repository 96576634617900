import { useState, useRef, useEffect } from "react"
import { nanoid } from "nanoid"
import { AxisBottom, AxisLeft } from "@visx/axis"
import { Group } from "@visx/group"
import { AreaClosed, LinePath } from "@visx/shape"
import { Point } from "@visx/point"
import { scaleLinear } from "@visx/scale"
import { Text } from "@visx/text"
import { curveCatmullRom } from "@visx/curve"
import { observer } from "mobx-react"

import { formatFrequency } from "../../../support/formatting"
import { clamper, getRelativeSVGPosition } from "../../../support/plotting"
import ReferenceLine from "../../../components/Audiogram/ReferenceLine"
import audiogramScale from "../../../components/Audiogram/audiogramScale"
import ResponsiveSVG from "../../../components/ResponsiveSVG"
import Draggable from "../../../components/Draggable"
import {
  FREQS,
  FREQ_DOMAIN,
  displayedGainClamper,
  DISPLAYED_GAIN_DOMAIN,
} from "../../../models/Equalizer"

import Handle from "./Equalizer/Handle"
import { Margin, Rect } from "../../../support/types"
import { SelectionRect } from "./SelectionRect"

type EqualizerProps = {
  width?: number
  height?: number
  title?: string
  margin?: Margin
  primaryColor?: string
  lineColor?: string
  selection?: number[]
  onChange: (datum: number, gain: number) => void
  onSelection: (selection: number[]) => void
  onStepUp?: () => void
  onStepDown?: () => void
  x: (datum: number) => number
  y: (datum: number) => number
  y40: (datum: number) => number
  y90: (datum: number) => number
}

const Equalizer = ({
  title,
  width = 400,
  height = 300,
  margin = {
    top: 10,
    left: 60,
    right: 40,
    bottom: 40,
  },
  selection = [],
  primaryColor = "#292929",
  lineColor = "#b9bbbd",
  onSelection,
  onChange,
  onStepUp,
  onStepDown,
  x,
  y,
  y40,
  y90,
}: EqualizerProps) => {
  if (title) {
    margin.top = 30
  }

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const [selectionRect, setSelectionRect] = useState<Rect | null>(null)
  const [displaySelectionRect, setDisplaySelectionRect] = useState(false)

  const [isCtrlDown, setCtrlDown] = useState(false)

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      setCtrlDown(e.ctrlKey || e.metaKey)
    }
    window.addEventListener("keydown", handler)
    window.addEventListener("keyup", handler)
    return () => {
      window.removeEventListener("keydown", handler)
      window.removeEventListener("keyup", handler)
    }
  }, [])

  const xScale = audiogramScale({
    domain: FREQ_DOMAIN,
    range: [0, xMax],
  })

  const yScale = scaleLinear({
    domain: DISPLAYED_GAIN_DOMAIN,
    range: [yMax, 0],
  })

  const [id] = useState(nanoid)
  const groupRef = useRef<SVGSVGElement | null>(null)

  let dragSelection: null | number[]
  let selectionStartX: number
  let selectionStartY: number

  const xClamp = clamper(0, xMax)
  const yClamp = clamper(0, yMax)

  let initialSelection: number[] = []

  const handleSelection = (e: MouseEvent) => {
    if (!groupRef.current) return

    const position = getRelativeSVGPosition(groupRef.current, e)

    setSelectionRect({
      x0: xClamp(selectionStartX),
      x1: xClamp(position.x),
      y0: yClamp(selectionStartY),
      y1: yClamp(position.y),
    })

    const freqStart: number = xScale.invert(selectionStartX)
    const freqEnd: number = xScale.invert(position.x)
    const gainStart: number = yScale.invert(selectionStartY)
    const gainEnd: number = yScale.invert(position.y)

    const freqMin = Math.min(freqStart, freqEnd)
    const freqMax = Math.max(freqStart, freqEnd)
    const gainMin = Math.min(gainStart, gainEnd)
    const gainMax = Math.max(gainStart, gainEnd)

    const newSelection = FREQS.filter(
      (f) =>
        f >= freqMin &&
        f <= freqMax &&
        displayedGainClamper(y(f)) >= gainMin &&
        displayedGainClamper(y(f)) <= gainMax,
    )

    if (e.ctrlKey || e.metaKey) {
      const a = initialSelection.filter((s) => !newSelection.includes(s))
      const b = newSelection.filter((s) => !initialSelection.includes(s))
      onSelection && onSelection([...a, ...b])
    } else {
      onSelection && onSelection(newSelection)
    }
  }

  const onSelectionStart = (e: MouseEvent) => {
    if (!groupRef.current) return

    setDisplaySelectionRect(true)
    const position = getRelativeSVGPosition(groupRef.current, e)
    initialSelection = selection.slice(0)
    selectionStartX = position.x
    selectionStartY = position.y
    handleSelection(e)
  }

  const onSelectionMove = (e: MouseEvent) => {
    handleSelection(e)
  }

  const onSelectionEnd = (e: MouseEvent) => {
    handleSelection(e)
    setDisplaySelectionRect(false)
  }

  const onHandleDragStart = (initial: number[] = []) => {
    dragSelection = initial
  }

  const onHandleDragMove = (e: MouseEvent, d: number) => {
    if (!dragSelection || !groupRef.current) return
    const position = getRelativeSVGPosition(groupRef.current, e)
    const gain = yScale.invert(position.y)
    const deltaGain = gain - y(d)

    if (dragSelection.length) {
      dragSelection.forEach((f) => {
        onChange(f, y(f) + deltaGain)
      })
    } else {
      onChange(d, gain)
    }
  }

  const onHandleDragEnd = () => {
    dragSelection = []
  }

  const handleStepAllKeyPress = (e: KeyboardEvent) => {
    if (!selection.length) return
    if (e.key === "ArrowUp") {
      e.preventDefault()
      onStepUp && onStepUp()
    } else if (e.key === "ArrowDown") {
      e.preventDefault()
      onStepDown && onStepDown()
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleStepAllKeyPress)
    return () => {
      window.removeEventListener("keydown", handleStepAllKeyPress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ResponsiveSVG className="equalizer" width={width} height={height}>
      <defs>
        <linearGradient
          id={`gradient-${id}`}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          spreadMethod="repeat"
        >
          <stop offset="0%" stopColor={primaryColor} stopOpacity="0.2" />
          <stop offset="100%" stopColor={primaryColor} stopOpacity="0" />
        </linearGradient>
      </defs>

      <AxisLeft
        scale={yScale}
        top={margin.top}
        left={margin.left}
        hideTicks
        label="Gain [dB]"
        labelProps={{
          fill: lineColor,
          textAnchor: "middle",
          fontFamily: "inherit",
          fontSize: 10,
        }}
        stroke={lineColor}
        tickStroke={lineColor}
        tickLabelProps={() => ({
          fontFamily: "inherit",
          fontSize: 8,
          textAnchor: "end",
          verticalAnchor: "middle",
          fill: lineColor,
        })}
      />

      <AxisBottom
        scale={xScale}
        top={height - margin.bottom}
        left={margin.left}
        hideTicks
        label="Frequency [Hz]"
        labelProps={{
          fill: lineColor,
          textAnchor: "middle",
          fontFamily: "inherit",
          fontSize: 10,
        }}
        stroke={lineColor}
        tickValues={xScale.ticks()}
        tickFormat={(value) => formatFrequency(value)}
        tickStroke={lineColor}
        tickLabelProps={() => ({
          fontFamily: "inherit",
          fontSize: 8,
          textAnchor: "middle",
          fill: lineColor,
        })}
      />

      {title && (
        <Text
          x={margin.left + xMax / 2}
          y="0"
          textAnchor="middle"
          verticalAnchor="start"
          width={xMax}
          fontSize={12}
          style={{
            fill: lineColor,
          }}
        >
          {title}
        </Text>
      )}

      <Group top={margin.top} left={margin.left} innerRef={groupRef}>
        <clipPath id={`clip-${id}`}>
          <rect
            x={0}
            y={0}
            width={xMax}
            height={yMax}
            stroke={lineColor}
            fill="transparent"
          />
        </clipPath>

        {selectionRect && displaySelectionRect && (
          <SelectionRect {...selectionRect} />
        )}

        <AreaClosed
          x={(d) => xScale(x(d))}
          y={(d) => yScale(y(d))}
          yScale={yScale}
          data={FREQS}
          curve={curveCatmullRom}
          fill={`url(#gradient-${id})`}
          stroke="none"
        />

        {xScale.ticks().map((tick) => (
          <ReferenceLine
            key={tick}
            from={
              new Point({
                x: xScale(tick),
                y: 0,
              })
            }
            to={
              new Point({
                x: xScale(tick),
                y: yMax,
              })
            }
            strokeDasharray="3,2"
          />
        ))}

        {/* horizontal lines */}
        {yScale.ticks().map((tick) => (
          <ReferenceLine
            key={tick}
            from={
              new Point({
                x: 0,
                y: yScale(tick),
              })
            }
            to={
              new Point({
                x: xMax,
                y: yScale(tick),
              })
            }
            strokeDasharray="3,2"
          />
        ))}

        <LinePath
          x={(d) => xScale(x(d))}
          y={(d) => yScale(y40(d))}
          data={FREQS}
          curve={curveCatmullRom}
          stroke={primaryColor}
          strokeWidth={1}
          strokeDasharray="3,2"
          clipPath={`url(#clip-${id})`}
        />

        <LinePath
          x={(d) => xScale(x(d))}
          y={(d) => yScale(y90(d))}
          data={FREQS}
          curve={curveCatmullRom}
          stroke={primaryColor}
          strokeWidth={1}
          strokeDasharray="3,2"
          clipPath={`url(#clip-${id})`}
        />

        <LinePath
          x={(d) => xScale(x(d))}
          y={(d) => yScale(y(d))}
          data={FREQS}
          curve={curveCatmullRom}
          stroke={primaryColor}
          clipPath={`url(#clip-${id})`}
        />

        <Draggable
          onDragStart={onSelectionStart}
          onDragMove={onSelectionMove}
          onDragEnd={onSelectionEnd}
        >
          <rect
            x={0}
            y={0}
            width={xMax}
            height={yMax}
            stroke={lineColor}
            fill="transparent"
          />
        </Draggable>

        {/* Handles */}
        <g>
          {FREQS.map((d, i) => {
            return (
              <Handle
                key={i}
                color={primaryColor}
                selected={selection.includes(d)}
                x={xScale(x(d))}
                y={yScale(y(d))}
                cursor={isCtrlDown ? "pointer" : "row-resize"}
                onDragStart={(e) => {
                  if (e.ctrlKey || e.metaKey) {
                    dragSelection = null

                    if (selection.includes(x(d))) {
                      onSelection(selection.filter((f) => f !== x(d)))
                    } else {
                      onSelection([...selection, x(d)])
                    }
                  } else if (selection.includes(x(d))) {
                    onHandleDragStart(selection)
                    onSelection(selection)
                  } else {
                    onSelection([x(d)])
                    onHandleDragStart([x(d)])
                  }
                }}
                onDragMove={(e) => {
                  onHandleDragMove(e, d)
                }}
                onDragEnd={onHandleDragEnd}
              />
            )
          })}
        </g>
      </Group>
    </ResponsiveSVG>
  )
}

export default observer(Equalizer)
