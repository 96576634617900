import { FormEvent, useContext } from "react"
import { observer, useLocalObservable } from "mobx-react"
import { useNavigate, useParams } from "react-router"

import ImgLogo from "../../assets/img/img_logo_earcloud.svg"
import { useFormRequest } from "../../hooks"
import { IconRepeatPassword } from "../../icons"
import {
  Input,
  Link,
  LoadingButton,
  ModelForm,
  ModelFormField,
  PasswordModelFormField,
} from "../../components"
import { RootStoreContext } from "../../contexts/RootStoreContext"

const ResetPasswordScene = () => {
  const { users, toasts } = useContext(RootStoreContext)!
  const params = useParams()
  const navigate = useNavigate()
  const model = useLocalObservable(() => ({
    new_password1: "",
    new_password2: "",
  }))

  const request = useFormRequest(
    () => {
      navigate("/login", { replace: true })
      toasts.show("Your password has been updated. You can login now.")
    },
    () => {
      toasts.show("An error occurred while trying to reset your password.", {
        accent: "danger",
      })
    },
  )

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    request.perform(
      users.confirmPasswordReset({
        ...model,
        ...params,
      }),
    )
  }

  return (
    <div className="flex flex-auto flex-col items-center justify-center">
      <div className="flex flex-auto items-end">
        <img className="my-8" src={ImgLogo} />
      </div>

      <div className="flex-auto xl:w-1/4">
        <h3 className="mb-2">Reset your password</h3>
        <p className="mb-8">Enter your new password below</p>
        <ModelForm
          model={model}
          errors={request.errors}
          onSubmit={onSubmit}
          noValidate
        >
          <PasswordModelFormField name="new_password1" label="Password" />

          <ModelFormField name="new_password2" label="Password Confirmation">
            <Input type="password" icon={<IconRepeatPassword />} />
          </ModelFormField>

          <div className="flex justify-center mb-8 mt-8">
            <LoadingButton isLoading={request.isLoading} className="w-full">
              RESET PASSWORD
            </LoadingButton>
          </div>
        </ModelForm>
      </div>

      <p className="flex flex-auto flex-col justify-end p-8 text-center">
        <span className="mb-4">Already have an account?</span>
        <Link to="/login">Sign in</Link>
      </p>
    </div>
  )
}

export default observer(ResetPasswordScene)
