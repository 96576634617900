import {
  Children,
  cloneElement,
  FormEvent,
  ReactElement,
  useContext,
} from "react"
import { observer } from "mobx-react"
import { action } from "mobx"

import FormField, { FormFieldProps } from "./FormField"
import ModelFormContext from "./ModelFormContext"

export type ModelFormProps = {
  name: string
} & FormFieldProps

const ModelFormField = ({ name, children, ...props }: ModelFormProps) => {
  const { model, errors } = useContext(ModelFormContext)
  const child = Children.only(children) as ReactElement
  if (!child) return null

  return (
    <FormField {...props} errors={errors[name]}>
      {cloneElement(child, {
        name,
        value: model[name as keyof typeof model],
        onChange: action((e: FormEvent) => {
          const node = e.target as HTMLInputElement
          if (errors[node.name]) {
            errors[node.name] = []
          }
          model[node.name] = node.value
        }),
      })}
    </FormField>
  )
}

export default observer(ModelFormField)
