import { Heading } from "../../components"

import LeftColumn from "./LeftColumn"
import RightColumn from "./RightColumn"

const ExpertScene = () => (
  <>
    <Heading>My Hearing Expert</Heading>
    <div className="grid md:grid-cols-2 gap-12">
      <LeftColumn />
      <RightColumn />
    </div>
  </>
)

export default ExpertScene
