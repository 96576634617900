import { SVGIcon } from "../support/types"

const Email: SVGIcon = (props) => (
  <svg
    viewBox="0 0 24 16"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
  >
    <path d="M2.017 15.164c-.192 0-.37-.05-.531-.132l7.009-7.125 3.238 2.73a.413.413 0 00.534 0l3.238-2.73 7.01 7.125c-.161.082-.34.132-.532.132H2.017zM.92 14.42a1.189 1.189 0 01-.089-.447V2.027c0-.175.04-.34.108-.49l6.917 5.832-6.936 7.05zM21.983.835c.174 0 .338.04.488.109L12 9.773 1.53.944a1.17 1.17 0 01.487-.109h19.966zM16.144 7.37l6.917-5.832c.067.15.108.315.108.49v11.946c0 .158-.033.308-.089.447L16.144 7.37zM24 2.027c0-.521-.203-.993-.526-1.352-.005-.007-.006-.015-.012-.023-.005-.006-.013-.009-.019-.014A2 2 0 0021.983 0H2.017A2 2 0 00.556.638C.55.643.543.646.537.653.531.66.53.668.525.676.202 1.035 0 1.506 0 2.026v11.947c0 .534.21 1.016.548 1.379.006.007.008.017.016.024l.008.005c.367.38.878.619 1.445.619h19.966a2 2 0 001.445-.62l.008-.004c.007-.007.01-.016.016-.023a2.02 2.02 0 00.548-1.38V2.027z" />
  </svg>
)

export default Email
