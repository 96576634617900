import { clamp } from "lodash"
import { types as t } from "mobx-state-tree"

import { clamper } from "../support/plotting"

export const MIN_KP1 = 0
export const MAX_KP1 = 120

export const MIN_KP2 = 0
export const MAX_KP2 = 120

export const MIN_EXPANSION_RATIO = 1
export const MAX_EXPANSION_RATIO = Infinity

export const MIN_COMPRESSION_RATIO = 1
export const MAX_COMPRESSION_RATIO = Infinity

const compressionRatioClamper = clamper(
  MIN_COMPRESSION_RATIO,
  MAX_COMPRESSION_RATIO,
)

const expansionRationClamper = clamper(MIN_EXPANSION_RATIO, MAX_EXPANSION_RATIO)

export const GainFunction = t
  .model("GainFunction", {
    compressionRatio: t.number,
    expansionRatio: t.number,
    kneepoint1: t.number,
    kneepoint2: t.number,
  })
  .actions((self) => ({
    setCompressionRatio(value: number) {
      self.compressionRatio = compressionRatioClamper(value)
    },
    setExpansionRatio(value: number) {
      self.expansionRatio = expansionRationClamper(value)
    },
    setKneepoint1(value: number) {
      self.kneepoint1 = clamp(value, MIN_KP1, self.kneepoint2)
    },
    setKneepoint2(value: number) {
      self.kneepoint2 = clamp(value, self.kneepoint1, MAX_KP2)
    },
  }))
