import { observer } from "mobx-react"

import { IconFirstName, IconLastName } from "../../icons"
import {
  Input,
  BirthYearSelect,
  Button,
  ModelFormField,
  LoadingButton,
} from "../../components"

import { GenderField, HearingLossSinceField } from "../../components/FormFields"
import { StepProps } from "./Wizard"

const StepTwo = ({ isLoading, onRequestBack }: StepProps) => {
  return (
    <>
      <ModelFormField name="first_name" label="First Name">
        <Input type="text" icon={<IconFirstName />} />
      </ModelFormField>

      <ModelFormField name="last_name" label="Last Name">
        <Input type="text" icon={<IconLastName />} />
      </ModelFormField>

      <ModelFormField name="gender" label="Gender">
        <GenderField />
      </ModelFormField>

      <ModelFormField name="birth_year" label="Birth year">
        <BirthYearSelect />
      </ModelFormField>

      <ModelFormField name="hl_since" label="Hearing Loss since">
        <HearingLossSinceField />
      </ModelFormField>

      <div className="flex justify-between mb-8 mt-8">
        <Button outline className="mr-4 w-1/2" onClick={onRequestBack}>
          STEP BACK
        </Button>

        <LoadingButton isLoading={isLoading} className="w-1/2">
          SIGN UP
        </LoadingButton>
      </div>
    </>
  )
}

export default observer(StepTwo)
