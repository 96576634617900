import { observer } from "mobx-react"
import { ComponentProps } from "react"
import { IoMdVideocam } from "react-icons/io"

import { IconEmail, IconCalendar } from "../../icons"
import { IUserProfile } from "../../models/UserProfile"

const Button = ({ href, children }: ComponentProps<"a">) => {
  return (
    <a
      href={href}
      className="flex items-center pl-4 font-medium text-bondi text-sm hover:underline"
    >
      {children}
    </a>
  )
}

const SubHeader = ({ userProfile }: { userProfile: IUserProfile }) => {
  return (
    <div className="flex items-center mb-6 mt-4 space-x-4 divide-x subheader">
      <h2>{userProfile.full_name}</h2>
      {userProfile.email && (
        <div className="flex space-x-4 divide-x">
          <Button
            className="flex items-center text-sm"
            href={`mailto:${userProfile.email}`}
          >
            <IconEmail height="1em" className="mr-2" />
            Email
          </Button>

          <Button
            className="flex items-center text-sm"
            href="https://www.google.com/calendar/render?action=TEMPLATE&vcon=meet"
          >
            <IconCalendar height="1em" className="mr-2" />
            Set an appointment
          </Button>

          <Button href="https://zoom.us/start/videomeeting">
            <IoMdVideocam height="1em" className="mr-2" />
            Zoom Call
          </Button>
        </div>
      )}
    </div>
  )
}

export default observer(SubHeader)
