import {
  get as getHearingLossRank,
  getHearingLossRankKey,
} from "../../models/HearingLossRank"

import HearingLossMessage from "./HearingLossMessage"

const HearingLossLevel = ({ value }: { value: number }) => {
  const rank = getHearingLossRank(getHearingLossRankKey(value))

  if (rank) {
    return (
      <HearingLossMessage
        title={
          <>
            Your test results show that you might have{" "}
            <strong>{rank.name}</strong>
          </>
        }
      >
        {rank.description}
      </HearingLossMessage>
    )
  }

  return (
    <HearingLossMessage title="No results yet.">
      Hearing loss is often an invisible problem. Did you know that it takes 7
      to 10 years before someone with a hearing loss seeks a solution.
    </HearingLossMessage>
  )
}

export default HearingLossLevel
