import clsx from "clsx"
import { ComponentProps } from "react"

export default ({ className, ...props }: ComponentProps<"ol">) => (
  <ol className={clsx("ml-10", className)} {...props}>
    <li>
      Invite Users of Jacoti technology to connect and stay closely in touch
      with you about their hearing needs
    </li>
    <li>Track your Users hearing within earCloud®</li>
    <li>
      Use earCloud®’s professional tools to precisely fit Jacoti ListenApp to
      your Users’ hearing
    </li>
    <li>
      Help Users track their own hearing with the Jacoti Hearing Center app
    </li>
  </ol>
)
