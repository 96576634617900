import clsx from "clsx"
import { observer } from "mobx-react"
import { HTMLProps, ReactNode, useMemo } from "react"

import InputGroupContext from "./InputGroupContext"

export type FormFieldProps = {
  label?: ReactNode
  hint?: ReactNode
  errors?: string[]
} & Omit<HTMLProps<HTMLElement>, "label">

const FormField = ({
  label,
  hint,
  errors = [],
  children,
  className,
}: FormFieldProps) => {
  const context = useMemo(
    () => ({ hasError: errors.length > 0 }),
    [errors.length],
  )

  return (
    <InputGroupContext.Provider value={context}>
      <div className={clsx("form-field", className)}>
        <label>
          {label && (
            <div
              className={clsx("mb-2 font-medium text-gray-dark", {
                "text-red-500": context.hasError,
              })}
            >
              {label}
            </div>
          )}
          {children}
          {hint && (
            <div
              className={clsx("mt-2 text-sm", {
                "text-red-500": context.hasError,
              })}
            >
              {hint}
            </div>
          )}
          {errors.map((error, i) => (
            <div key={i} className="mt-2 text-red-500 text-sm">
              {error}
            </div>
          ))}
        </label>
      </div>
    </InputGroupContext.Provider>
  )
}

export default observer(FormField)
