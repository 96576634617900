import { observer } from "mobx-react"

import {
  IconFirstName,
  IconLastName,
  IconId,
  IconLocation,
  IconWorkplace,
} from "../../icons"
import PdfConditionsOfUse from "../../assets/docs/expert-conditions-of-use.pdf"
import { TitleField } from "../../components/FormFields"
import {
  Input,
  Link,
  CardHeading,
  Button,
  ModelFormField,
  LoadingButton,
} from "../../components"
import { StepProps } from "./Wizard"

const StepTwo = ({ isLoading, onRequestBack }: StepProps) => {
  return (
    <>
      <CardHeading className="mb-4">Identification</CardHeading>

      <ModelFormField name="title" label="Title">
        <TitleField />
      </ModelFormField>
      <ModelFormField name="first_name" label="First Name">
        <Input type="text" icon={<IconFirstName />} />
      </ModelFormField>
      <ModelFormField name="last_name" label="Last Name">
        <Input type="text" icon={<IconLastName />} />
      </ModelFormField>
      <CardHeading className="mb-4">Professional ID Number</CardHeading>
      <ModelFormField name="professional_id">
        <Input icon={<IconId />} />
      </ModelFormField>
      <CardHeading className="mb-4">Workplace</CardHeading>
      <ModelFormField name="work_place">
        <Input
          autoComplete="organization"
          placeholder="Workplace"
          icon={<IconWorkplace />}
        />
      </ModelFormField>
      <ModelFormField name="city" className="mb-8">
        <Input type="text" placeholder="City" icon={<IconLocation />} />
      </ModelFormField>
      <ModelFormField
        name="agreement"
        className="mb-8"
        label={
          <span>
            If you agree to the{" "}
            <Link href={PdfConditionsOfUse} target="_blank">
              conditions of use
            </Link>{" "}
            and have read the{" "}
            <Link
              href="https://www.jacoti.com/support/listenapp/Jacoti_ListenApp_ExpertManual.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              expert manual
            </Link>
            , please type <span className="font-medium">"I AGREE"</span> in the
            box below
          </span>
        }
      >
        <Input type="text" />
      </ModelFormField>
      <div className="flex justify-between mb-8 mt-8">
        <Button outline className="mr-4 w-1/2" onClick={onRequestBack}>
          STEP BACK
        </Button>

        <LoadingButton isLoading={isLoading} className="w-1/2">
          SIGN UP
        </LoadingButton>
      </div>
    </>
  )
}

export default observer(StepTwo)
