import clsx from "clsx"
import { observer } from "mobx-react"
import { ComponentProps } from "react"

type SubheadingProps = {
  bordered?: boolean
} & ComponentProps<"span">

const Subheading = ({ className, bordered, ...props }: SubheadingProps) => (
  <span
    className={clsx(
      "block default:mb-4 font-medium text-gray-dark",
      className,
      {
        "border-b-2 pb-2": bordered,
      },
    )}
    {...props}
  />
)

export default observer(Subheading)
