import clsx from "clsx"
import { ComponentProps } from "react"

const NotFoundScene = ({ className }: ComponentProps<"div">) => (
  <div
    className={clsx(
      "flex flex-auto flex-col items-center justify-center",
      className,
    )}
  >
    <h1>Page not found</h1>
    <p>The page you requested is not available</p>
  </div>
)

export default NotFoundScene
