import { ComponentProps, MouseEvent, useContext, useState } from "react"
import { isEqual, pick, pickBy } from "lodash"
import { observer, useLocalObservable } from "mobx-react"

import {
  IconId,
  IconLocation,
  IconFirstName,
  IconLastName,
  IconWorkplace,
} from "../../icons"

import { useFormRequest } from "../../hooks"

import {
  ModelForm,
  ModelFormField,
  Input,
  Button,
  LoadingButton,
  CardHeading,
  BirthYearSelect,
  Card,
  FloatingToolbar,
  Subheading,
} from "../../components"

import {
  GenderField,
  HearingLossSinceField,
  TitleField,
} from "../../components/FormFields"

import DeleteAccountModal from "./DeleteAccountModal"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { IUser } from "../../models/User"
import { getSnapshot } from "mobx-state-tree"
import clsx from "clsx"

const getUserProfileSnapshot = (user: IUser) => {
  const snapshot = {
    first_name: "",
    last_name: "",
    gender: "",
    birth_year: "",
    hl_since: "",
    title: "",
    professional_id: "",
    work_place: "",
    city: "",
    ...pickBy(getSnapshot<IUser>(user.user_profile)),
  }

  if (user.isExpertOrTester) {
    return snapshot
  }

  return pick(snapshot, [
    "first_name",
    "last_name",
    "gender",
    "birth_year",
    "hl_since",
  ])
}

const Link = (props: ComponentProps<"a">) => (
  <a className="block text-bondi text-sm underline cursor-pointer" {...props} />
)

const AccountScene = () => {
  const { toasts, user } = useContext(RootStoreContext)!

  const { isExpert, user_profile } = user
  const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false)

  const store = useLocalObservable(() => ({
    model: getUserProfileSnapshot(user),
    reset() {
      store.model = getUserProfileSnapshot(user)
    },
    get isDirty() {
      return !isEqual(store.model, getUserProfileSnapshot(user))
    },
  }))

  const request = useFormRequest(
    () => {
      toasts.show("Your profile was successfully updated.")
    },
    () => {
      toasts.show("An error occurred while updating your profile.", {
        accent: "danger",
      })
    },
  )

  const toggleDeleteAccountModal = () => {
    setDeleteAccountModalOpen(!isDeleteAccountModalOpen)
  }

  const onSubmit = (e: MouseEvent) => {
    e.preventDefault()
    request.perform(user_profile.update(store.model))
  }

  return (
    <>
      <ModelForm
        className={clsx(
          "grid gap-8",
          { "grid-cols-2": !isExpert },
          { "grid-cols-3": isExpert },
        )}
        model={store.model}
        errors={request.errors}
      >
        <Card className="p-8">
          <CardHeading className="-mt-8 -mx-8 mb-8 px-8 py-4 border-b">
            Account details
          </CardHeading>

          <ModelFormField name="first_name" label="First name">
            <Input
              type="text"
              autoComplete="given-name"
              icon={<IconFirstName />}
            />
          </ModelFormField>

          <ModelFormField
            name="last_name"
            label="Last name"
            autoComplete="family-name"
          >
            <Input type="text" icon={<IconLastName />} />
          </ModelFormField>

          <ModelFormField name="gender" label="Gender">
            <GenderField />
          </ModelFormField>

          <ModelFormField name="birth_year" label="Birth year">
            <BirthYearSelect />
          </ModelFormField>

          <ModelFormField name="hl_since" label="Hearing Loss since">
            <HearingLossSinceField />
          </ModelFormField>
        </Card>

        {isExpert && (
          <Card className="p-8">
            <CardHeading className="-mt-8 -mx-8 mb-8 px-8 py-4 border-b">
              Expert details
            </CardHeading>

            <ModelFormField name="title" label="Title">
              <TitleField />
            </ModelFormField>

            <ModelFormField
              name="professional_id"
              label="Professional ID Number"
            >
              <Input autoComplete="organization" icon={<IconId />} />
            </ModelFormField>

            <ModelFormField name="work_place" label="Workplace">
              <Input autoComplete="organization" icon={<IconWorkplace />} />
            </ModelFormField>

            <ModelFormField name="city" label="City">
              <Input type="text" icon={<IconLocation />} />
            </ModelFormField>
          </Card>
        )}

        <div className="-mt-8 p-8">
          <Subheading>Help</Subheading>
          <ul className="space-y-2">
            <li>
              <Link href="https://jacoti.com/contact/">Support</Link>
            </li>
            <li>
              <Link href="https://jacoti.com/privacy/">Privacy Policy</Link>
            </li>
            <li>
              <Link onClick={toggleDeleteAccountModal}>Delete account</Link>
            </li>
          </ul>
        </div>
      </ModelForm>

      <FloatingToolbar>
        <div className="flex items-center space-x-4" />

        <div className="flex space-x-4">
          <Button
            outline
            shape="rect"
            className="px-16"
            disabled={request.isLoading || !store.isDirty}
            onClick={(e: MouseEvent) => {
              e.preventDefault()
              store.reset()
            }}
          >
            Reset
          </Button>
          <LoadingButton
            shape="rect"
            className="px-16"
            isLoading={request.isLoading}
            disabled={!store.isDirty}
            onClick={onSubmit}
          >
            Save
          </LoadingButton>
        </div>
      </FloatingToolbar>

      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        onClose={toggleDeleteAccountModal}
      />
    </>
  )
}

export default observer(AccountScene)
