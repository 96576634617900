import { ComponentProps } from "react"
import { MdClose } from "react-icons/md"
import { animated, useSpring } from "@react-spring/web"

import { useKeyPress } from "../hooks"

import Backdrop from "./Backdrop"
import clsx from "clsx"

export enum DrawerPosition {
  Left = "left",
  Right = "right",
}

type DrawerProps = {
  isOpen: boolean
  position: DrawerPosition
  onClose: () => void
} & ComponentProps<typeof animated.div>

export const Drawer = ({
  children,
  isOpen = false,
  onClose = () => {},
  position = DrawerPosition.Left,
  className,
  ...props
}: DrawerProps) => {
  useKeyPress("Escape", onClose)

  const translateX = isOpen ? 0 : 100
  const drawerSpring = useSpring({
    transform: `translateX(${
      position === DrawerPosition.Left ? -translateX : translateX
    }%)`,
  })

  return (
    <>
      <Backdrop onClick={onClose} isOpen={isOpen}>
        <a
          className="flex flex-col absolute right-0 top-0 items-center m-8 text-white cursor-pointer close"
          onClick={onClose}
        >
          <MdClose size={38} />
          <div className="mt-2 font-bold text-lg">ESC</div>
        </a>
      </Backdrop>
      <animated.div
        className={clsx(
          "fixed z-40 h-full w-screen sm:w-[400px]",
          {
            "left-0": position === DrawerPosition.Left,
            "right-0": position === DrawerPosition.Right,
          },
          className,
        )}
        style={drawerSpring}
        {...props}
      >
        {children}
      </animated.div>
    </>
  )
}
