import { ComponentProps } from "react"

const Info = (props: ComponentProps<"svg">) => (
  <svg
    viewBox="0 0 13 13"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
  >
    <path d="M6.498 12.416A5.921 5.921 0 01.583 6.502 5.922 5.922 0 016.498.587a5.922 5.922 0 015.915 5.915 5.921 5.921 0 01-5.915 5.914m0-12.412A6.505 6.505 0 000 6.502 6.506 6.506 0 006.498 13a6.506 6.506 0 006.498-6.498A6.505 6.505 0 006.498.004" />
    <path d="M6.019 4.82v4.938a.438.438 0 10.875 0V4.819a.438.438 0 00-.875 0zm.464-2.182a.532.532 0 110 1.063.532.532 0 010-1.063" />
  </svg>
)

export default Info
