import { forwardRef } from "react"
import { MeasurementType } from "../../models/Measurement"
import { IMarker } from "./Marker"

const IconMax = (
  <path d="M18.5 2h-3.511l-2-2h9v9l-2-2V3.339L12.414 11l4.293 4.293a1 1 0 0 1-1.414 1.414L11 12.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L9.586 11 5.293 6.707a1 1 0 1 1 1.414-1.414L11 9.586 18.5 2z" />
)
const IconMin = (
  <path d="M18.5 20h-3.511l-2 2h9v-9l-2 2v3.661L12.414 11l4.293-4.293a1 1 0 0 0-1.414-1.414L11 9.586 6.707 5.293a1 1 0 0 0-1.414 1.414L9.586 11l-4.293 4.293a1 1 0 1 0 1.414 1.414L11 12.414 18.5 20z" />
)
const IconValue = (
  <path d="M5.293 5.293a1 1 0 0 1 1.414 0L11 9.586l4.293-4.293a1 1 0 1 1 1.414 1.414L12.414 11l4.293 4.293a1 1 0 0 1-1.414 1.414L11 12.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L9.586 11 5.293 6.707a1 1 0 0 1 0-1.414z" />
)

const Icon = ({ type }: { type: MeasurementType }) => {
  if (type === "max") return IconMax
  if (type === "min") return IconMin
  return IconValue
}

type CrossProps = IMarker

const Cross = forwardRef<SVGGElement, CrossProps>(
  ({ x, y, type, ...props }, ref) => (
    <g
      {...props}
      ref={ref}
      transform={`translate(${x - 11},${y - 11})`}
      pointerEvents="bounding-box"
    >
      <Icon type={type} />
    </g>
  ),
)

export default Cross
