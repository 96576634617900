import { types as t, flow, Instance, getRoot } from "mobx-state-tree"

import { uniq } from "lodash"

import { Notification } from "../models/Notification"
import { RootStore } from "./RootStore"
import { IUserProfile } from "../models/UserProfile"

export const NotificationStore = t
  .model("NotificationStore", {
    results: t.optional(t.array(Notification), []),
  })
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      fetch: flow(function* () {
        const res = yield rootStore.client.get("/notifications/")

        const results = res.data.map(
          ({ sender, ...props }: { sender: IUserProfile }) => {
            rootStore.users.put(sender)
            return { sender: sender.id, ...props }
          },
        )

        yield Promise.all(
          uniq(
            results.map(({ sender }: { sender: IUserProfile }) =>
              rootStore.userProfiles.fetchByUserId(sender),
            ),
          ),
        )

        self.results = results
      }),
      remove: flow(function* (notification) {
        yield rootStore.client.delete(`/notifications/${notification.id}/`)
        self.results.remove(notification)
      }),
      clear() {
        self.results.clear()
      },
    }
  })

export interface INotificationStore
  extends Instance<typeof NotificationStore> {}
