import clsx from "clsx"
import { HTMLProps } from "react"
import { FaCaretRight } from "react-icons/fa"
import { Link } from "react-router-dom"

type CaretLinkProps = {
  to?: string
} & HTMLProps<HTMLElement>

const CaretLink = ({ children, className, to, ...props }: CaretLinkProps) => {
  let Component: any = "a"
  if (to) {
    Component = Link
  }

  return (
    <Component
      {...{ ...props, to }}
      className={clsx(
        "text-picton hover:text-imperial no-underline whitespace-nowrap cursor-pointer",
        className,
      )}
    >
      {children}&nbsp;
      <FaCaretRight className="inline text-current" />
    </Component>
  )
}
export default CaretLink
