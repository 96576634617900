import { useTransition, config } from "@react-spring/web"
import { observer } from "mobx-react"
import { isAlive } from "mobx-state-tree"
import { IToast } from "../models/Toast"

import Toast, { ToastAccent } from "./Toast"

type ToastListProps = {
  toasts: IToast[]
  onDismiss: (toast: IToast) => void
}

const ToastList = ({ toasts, onDismiss }: ToastListProps) => {
  const transition = useTransition(toasts, {
    keys: (toast) => toast.key,
    config: config.gentle,
    from: { opacity: 0, transform: "translateY(-100%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(100%)" },
  })

  return (
    <div
      className="flex flex-col-reverse fixed left-0 top-0 z-50 items-center justify-end h-full w-full"
      style={{ paddingTop: 80, pointerEvents: "none" }}
    >
      {transition((style, toast) => {
        if (!isAlive(toast)) return null
        return (
          <Toast
            key={toast.key}
            accent={toast.accent as ToastAccent}
            style={style}
            onDismiss={() => {
              onDismiss(toast)
            } }
          >
            {toast.message}
          </Toast>
        )
      })}
    </div>
  )
}

export default observer(ToastList)
