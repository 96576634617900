import { types as t, getRoot, Instance } from "mobx-state-tree"
import { RootStore } from "../stores/RootStore"
import { UserProfile } from "./UserProfile"

export const User = t
  .model("User", {
    id: t.identifierNumber,
    email: t.string,
    groups: t.array(t.string),
    is_active: t.boolean,
    user_profile: t.reference(UserProfile, {
      get(id, self) {
        const { userProfiles } = getRoot<Instance<typeof RootStore>>(self)
        const userProfile = userProfiles.get(id.toString())

        if (!userProfile) {
          userProfiles.fetch(id)
        }

        return userProfile
      },
      set(obj, self) {
        return getRoot<Instance<typeof RootStore>>(self).userProfiles.put(obj)
      },
    }),
  })
  .views((self) => ({
    get isExpert() {
      return self.groups.includes("expert")
    },
    get isTester() {
      return self.groups.includes("tester")
    },
    get isExpertOrTester() {
      return this.isExpert || this.isTester
    },
    get asSnapshot() {
      return {
        first_name: self.user_profile.first_name,
        last_name: self.user_profile.last_name,
        email: self.user_profile.email,
        phone_number: self.user_profile.phone_number,
      }
    },
  }))

export interface IUser extends Instance<typeof User> {}
