import { useRef, useState, ComponentProps, ReactNode } from "react"
import { animated, useSpring } from "@react-spring/web"

import { useScrollHeight } from "../hooks"

import AnimatedArrow from "./AnimatedArrow"
import clsx from "clsx"
import { observer } from "mobx-react"

type ExpandableProps = {
  title: ReactNode
  expanded?: boolean
  onExpand?: () => void
  onCollapse?: () => void
} & Omit<ComponentProps<"div">, "title">

const Expandable = ({
  title,
  expanded = false,
  children,
  onExpand = () => {},
  onCollapse = () => {},
  className,
  ...props
}: ExpandableProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isAnimating, setAnimating] = useState(false)
  const [isExpanded, setExpanded] = useState(expanded)
  const scrollHeight = useScrollHeight(ref.current, [children])

  // useEffect(() => {
  //   setAnimating(true)
  // }, [children])

  const spring = useSpring({
    onRest: () => {
      setAnimating(false)
    },
    to: {
      opacity: isExpanded ? 1 : 0,
      height: isExpanded ? scrollHeight : 0,
    },
  })

  const toggle = () => {
    setAnimating(true)
    if (isExpanded) {
      onCollapse()
    } else {
      onExpand()
    }
    setExpanded(!isExpanded)
  }

  return (
    <div {...props} className={clsx("expandable", className)}>
      <div
        className={clsx("flex items-center cursor-pointer")}
        onClick={toggle}
      >
        <div className="flex-auto expandable-title">{title}</div>
        <div className="self-start ml-8 text-gray whitespace-nowrap">
          <AnimatedArrow size="1.5em" isExpanded={isExpanded} />
        </div>
      </div>

      <animated.div
        ref={ref}
        className="expandable-content"
        style={{
          opacity: spring.opacity,
          ...(isAnimating && { height: spring.height, overflow: "hidden" }),
        }}
      >
        {(isAnimating || isExpanded) && children}
      </animated.div>
    </div>
  )
}

export default observer(Expandable)
