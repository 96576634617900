import { isEmpty } from "lodash"
import { types as t, getRoot, Instance } from "mobx-state-tree"
import { parseISO } from "date-fns"

import { MeasurementData } from "./MeasurementData"

import { UserProfile } from "./UserProfile"
import { getName } from "./MeasurementSessionType"
import { RootStore } from "../stores/RootStore"

export const MeasurementSession = t
  .model("MeasurementSession", {
    uuid: t.identifier,
    date_start: t.string,
    hk_version: t.maybeNull(t.string),
    user_profile: t.reference(UserProfile, {
      get(id, self) {
        const { userProfiles } = getRoot<Instance<typeof RootStore>>(self)
        const userProfile = userProfiles.get(id.toString())

        if (!userProfile) {
          userProfiles.fetch(id)
        }

        return userProfile
      },
      set(obj, self) {
        return getRoot<Instance<typeof RootStore>>(self).userProfiles.put(obj)
      },
    }),
    data: MeasurementData,
    isOpen: t.optional(t.boolean, false),
  })
  .views((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)
    return {
      get date() {
        return parseISO(self.date_start)
      },
      get labels() {
        if (!self.data || !self.data.labels) {
          return []
        }

        return self.data.labels
          .map((key) => getName(key))
          .filter((value) => !isEmpty(value))
      },
      get audiogram() {
        const audiogram = rootStore.measurementSessions.audiograms.get(
          self.uuid,
        )

        if (!audiogram) {
          rootStore.measurementSessions.audiograms.fetch(self.uuid)
        }

        return audiogram
      },
      get asSnapshot() {
        const { uuid, date_start, hk_version } = self

        return {
          uuid,
          date_start,
          hk_version,
          audiogram: this.audiogram,
        }
      },
    }
  })

export interface IMeasurementSession
  extends Instance<typeof MeasurementSession> {}
