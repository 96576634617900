import { observer } from "mobx-react"
import { ComponentProps, ReactNode } from "react"

import InputGroup from "./InputGroup"

type SelectProps = {
  disabled?: boolean
  placeholder?: ReactNode
  icon?: ReactNode
  trailer?: ReactNode
} & ComponentProps<"select">

const Select = ({
  placeholder,
  disabled,
  icon,
  trailer,
  className,
  ...props
}: SelectProps) => (
  <InputGroup
    {...{
      icon,
      trailer,
      disabled,
      className,
    }}
  >
    <select className="input input-select" disabled={disabled} {...props} />

    <div
      className="flex absolute right-0 items-center pointer-events-none pin-y"
      style={{
        paddingRight: 10,
        paddingLeft: 10,
      }}
    >
      <svg
        className="h-4 w-4 fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </InputGroup>
)

export default observer(Select)
