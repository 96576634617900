import { orderBy } from "lodash"
import { ObservableMap } from "mobx"
import { Instance, types as t } from "mobx-state-tree"
import { HKThreshold } from "./Audiogram"

export enum MeasurementType {
  Min = "min",
  Max = "max",
  Value = "value",
}

export const toHKThreshold = (m: IMeasurement) : HKThreshold => {
  if (m.type === MeasurementType.Max) {
    return ["-inf", m.value]
  }

  if (m.type === MeasurementType.Min) {
    return [m.value, "inf"]
  }

  return m.value
}

export type MeasurementMap = ObservableMap<
  number,
  Omit<IMeasurement, "frequency">
>

export type AudiogramMeasurementMap = {
  left: MeasurementMap
  right: MeasurementMap
}

export function toMeasurementArray(map: MeasurementMap): IMeasurement[] {
  return orderBy(
    Array.from(map.entries()).map(([frequency, { value, type }]) => ({
      frequency,
      value,
      type,
    })),
    "frequency",
  )
}

export function toMeasurementMap(array: IMeasurement[]): MeasurementMap {
  return new ObservableMap(
    array.map((x) => [x.frequency, { value: x.value, type: x.type }]),
  )
}

export const Measurement = t.model("Measurement", {
  frequency: t.number,
  value: t.number,
  type: t.enumeration(Object.values(MeasurementType)),
})

export interface IMeasurement extends Instance<typeof Measurement> {}
