import AccountInfoStep from "./AccountInfoStep"
import PersonalInfoStep from "./PersonalInfoStep"
import ProfessionalInfoStep from "./ProfessionalInfoStep"

const ACCOUNT_INFO_STEP = {
  name: "Account Information",
  component: AccountInfoStep,
  fields: ["email", "password", "password_confirm", "type_user"],
}

const PERSONAL_INFO_STEP = {
  name: "Personal Information",
  component: PersonalInfoStep,
  fields: ["first_name", "last_name", "gender", "birth_year", "hl_since"],
}

const PROFESSIONAL_INFO_STEP = {
  name: "Professional Information",
  component: ProfessionalInfoStep,
  fields: [
    "title",
    "first_name",
    "last_name",
    "professional_id",
    "work_place",
    "city",
    "agreement",
  ],
}

const STEPS = {
  user: [ACCOUNT_INFO_STEP, PERSONAL_INFO_STEP],
  tester: [ACCOUNT_INFO_STEP, PERSONAL_INFO_STEP],
  expert: [ACCOUNT_INFO_STEP, PROFESSIONAL_INFO_STEP],
}

export default STEPS
