import {
  Children,
  cloneElement,
  ComponentProps,
  ComponentPropsWithRef,
  forwardRef,
  useRef,
  useState,
} from "react"
import { animated, useSpring } from "@react-spring/web"

import { useForceUpdate, useResizeObserver } from "../hooks"
import clsx from "clsx"
import { observer } from "mobx-react"

const Bar = forwardRef<
  HTMLDivElement,
  ComponentPropsWithRef<typeof animated.div>
>((props, ref) => (
  <animated.div ref={ref} className="h-[2px] bg-picton" {...props} />
))

type TabBarProps = {
  active: (child: any) => boolean
  position?: "top" | "bottom"
} & ComponentProps<"nav">

const TabBar = ({
  active,
  children: items,
  position = "bottom",
  className,
  ...props
}: TabBarProps) => {
  const ref = useRef<HTMLElement | null>(null)
  const [node, setNode] = useState<HTMLElement>()
  const forceUpdate = useForceUpdate()
  useResizeObserver(ref.current, {}, forceUpdate)

  const spring = useSpring(
    node
      ? {
          opacity: 1,
          marginLeft: node.offsetLeft,
          marginRight:
            (node.offsetParent?.clientWidth ?? 0) -
            node.offsetLeft -
            node.offsetWidth,
        }
      : {
          marginLeft: 0,
          marginRight: 0,
          opacity: 0,
        },
  )

  return (
    <nav
      ref={ref}
      className={clsx("flex flex-col flex-none", className)}
      {...props}
    >
      {position === "top" && <Bar style={spring} />}

      <div className="flex relative">
        {Children.toArray(items).map(
          (child) =>
            child &&
            cloneElement(child as any, {
              ref: (x: any) => {
                if (active(child)) {
                  setNode(x)
                }
              },
            }),
        )}
      </div>

      {position === "bottom" && <Bar style={spring} />}
    </nav>
  )
}

export default observer(TabBar)
