import { ComponentProps } from "react"

const Location = (props: ComponentProps<"svg">) => (
  <svg width="1em" height="1em" viewBox="0 0 17 24" {...props}>
    <path
      d="M14.285 2.451c2.925 2.925 3.29 8.427.778 11.774l-6.695 9.669-6.704-9.682c-2.5-3.334-2.138-8.836.787-11.76A8.313 8.313 0 018.368 0a8.31 8.31 0 015.917 2.451zm.07 11.264c2.28-3.038 1.953-8.007-.687-10.647a7.448 7.448 0 00-5.3-2.195 7.45 7.45 0 00-5.3 2.195c-2.64 2.64-2.964 7.609-.696 10.634l5.996 8.659zm-5.91-8.479A3.058 3.058 0 0111.5 8.291a3.058 3.058 0 01-3.054 3.054 3.058 3.058 0 01-3.055-3.054 3.058 3.058 0 013.055-3.055zm0 5.237a2.184 2.184 0 002.182-2.182 2.184 2.184 0 00-2.18-2.182 2.184 2.184 0 00-2.183 2.182c0 1.203.98 2.182 2.182 2.182z"
      fill="currentColor"
    />
  </svg>
)

export default Location
