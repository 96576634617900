import { useContext, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { MdClear } from "react-icons/md"
import { useNavigate, useParams } from "react-router"

import { colors } from "../../support/constants"
import {
  Button,
  LoadingPlaceholder,
  Card,
  HearingLossGauge,
  Subheading,
  Legend,
} from "../../components"

import UserProfile from "./UserProfile"
import HearingLossDistribution from "./HearingLossDistribution"
import PatientAudiogram from "./PatientAudiogram"
import { RootStoreContext } from "../../contexts/RootStoreContext"
import { IExpertLink } from "../../models/ExpertLink"

const Metric = ({ name, value }: { name: string; value: any }) => (
  <div className="flex flex-col items-center justify-center px-6 py-2 leading-none text-center bg-neutral-50">
    <div className="mb-1 text-neutral-500 text-xs whitespace-nowrap">
      {name}
    </div>
    <div className="relative font-medium text-md whitespace-nowrap">
      {value}
    </div>
  </div>
)

const PatientScene = () => {
  const { userProfiles, toasts, expertLinks } = useContext(RootStoreContext)!
  const { id } = useParams()
  const navigate = useNavigate()
  const patientUserProfile = userProfiles.get(id)
  const [isFetching, setFetching] = useState(true)

  useEffect(() => {
    const fetch = async () => {
      try {
        setFetching(true)
        await userProfiles.fetch(id)
      } finally {
        setFetching(false)
      }
    }

    fetch()
  }, [id, userProfiles])

  const onDisconnect = async () => {
    await expertLinks.fetchAll()

    const link = expertLinks.links.find(
      ({ user_profile }: IExpertLink) => user_profile === patientUserProfile,
    )

    if (link) {
      if (
        confirm(
          `Are you sure you want to disconnect from ${link.user_profile.full_name}?`,
        )
      ) {
        try {
          await link.remove()
          toasts.show("Successfully disconnected.")
          navigate("/patients", { replace: true })
        } catch (err) {
          toasts.show("An error occurred while disconnecting.", {
            accent: "danger",
          })
          console.error(err)
        }
      }
    }
  }

  return (
    <LoadingPlaceholder isLoading={isFetching}>
      {() => (
        <div className="space-y-8">
          <Card className="lg:flex p-8 space-x-8 space-y-8">
            <div className="flex flex-auto flex-col space-y-12">
              <UserProfile userProfile={patientUserProfile} />
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                <Metric name="Age" value={`${patientUserProfile.age} years`} />
                <Metric
                  name="Best ear"
                  value={
                    patientUserProfile.audiogram &&
                    patientUserProfile.audiogram.bestEar
                  }
                />
                <Metric
                  name="Audiometry"
                  value={patientUserProfile.total_audiometry}
                />
                <Metric
                  name="Screening"
                  value={patientUserProfile.total_screening}
                />
                <Metric
                  name="Manual Input"
                  value={patientUserProfile.total_manual_input}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-none lg:grid-flow-col sm:grid-cols-2 gap-8 sm:gap-0">
              <div className="flex flex-col items-center justify-center">
                <Subheading className="mb-0 text-inherit whitespace-nowrap">
                  Hearing Loss Level
                </Subheading>
                <HearingLossGauge
                  className="flex h-48 w-auto sm:w-full"
                  value={
                    patientUserProfile.audiogram &&
                    patientUserProfile.audiogram.hearingLoss
                  }
                />
              </div>

              <div className="flex flex-col items-center justify-center">
                <Subheading className="mb-0 text-inherit whitespace-nowrap">
                  Hearing Loss By Ear
                </Subheading>
                <div className="space-y-1">
                  <LoadingPlaceholder isLoading={!patientUserProfile.audiogram}>
                    {() => (
                      <HearingLossDistribution
                        className="flex flex-col items-center justify-center h-40"
                        audiogram={patientUserProfile.audiogram}
                      />
                    )}
                  </LoadingPlaceholder>
                  <Legend
                    className="flex-nowrap"
                    items={[
                      { name: "Left", color: colors["left-channel"] },
                      { name: "Right", color: colors["right-channel"] },
                    ]}
                  />
                </div>
              </div>
            </div>
          </Card>

          <PatientAudiogram />

          <Button className="ml-auto" accent="danger" onClick={onDisconnect}>
            <MdClear className="mr-2" />
            <span>Disconnect</span>
          </Button>
        </div>
      )}
    </LoadingPlaceholder>
  )
}

export default observer(PatientScene)
