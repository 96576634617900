import { ComponentProps } from "react"

import { colors } from "../../support/constants"
import { PieChart } from "../../components"
import { IAudiogram } from "../../models/Audiogram"

type HearingLossDistributionProps = {
  audiogram: IAudiogram
} & Omit<ComponentProps<typeof PieChart>, "data">

const HearingLossDistribution = ({
  className,
  audiogram,
}: HearingLossDistributionProps) => {
  return (
    <PieChart
      className={className}
      data={[
        {
          name: "Right Ear",
          value: Math.round(audiogram.rightHearingLoss ?? 0),
          color: colors["right-channel"],
        },
        {
          name: "Left Ear",
          value: Math.round(audiogram.leftHearingLoss ?? 0),
          color: colors["left-channel"],
        },
      ]}
    />
  )
}

export default HearingLossDistribution
