import { types as t, Instance, getRoot } from "mobx-state-tree"
import { RootStore } from "../stores/RootStore"

import { User } from "./User"
import { UserProfile } from "./UserProfile"

export const Notification = t
  .model("Notification", {
    id: t.identifierNumber,
    data: t.model({ token: t.string, uid: t.string }),
    date_created: t.string,
    recipient: t.reference(UserProfile),
    sender: t.reference(User),
    type: t.string,
  })
  .views(() => ({
    get isNew() {
      // TODO
      return false
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStore>>(self)

    return {
      remove() {
        rootStore.notifications.remove(self)
      },
    }
  })

export interface INotification extends Instance<typeof Notification> {}
