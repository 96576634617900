import { forwardRef } from "react"
import { MeasurementType } from "../../models/Measurement"
import { IMarker } from "./Marker"

const IconMax = (
  <path d="M.012 0v9l2-2V3.338l2.705 2.71a7.996 7.996 0 0 0 11.898 10.64A7.996 7.996 0 0 0 6.141 4.646L3.5 2h3.512l2-2zM5 11a6 6 0 1 0 12 0 6 6 0 0 0-12 0z" />
)
const IconMin = (
  <path d="M10.957 3.002c-6.658.037-10.36 7.718-6.24 12.95l-2.705 2.71V15l-2-2v9h9l-2-2H3.5l2.64-2.645c4.358 3.334 10.7 1.393 12.446-3.808 1.745-5.201-2.143-10.576-7.629-10.545zM5 11a6 6 0 1 0 12 0 6 6 0 0 0-12 0z" />
)
const IconValue = (
  <path d="M11 3a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-6 8a6 6 0 1 0 12 0 6 6 0 0 0-12 0z" />
)

const Icon = ({ type }: { type: MeasurementType }) => {
  if (type === "max") return IconMax
  if (type === "min") return IconMin
  return IconValue
}

type CircleProps = IMarker

const Circle = forwardRef<SVGGElement, CircleProps>(
  ({ x, y, type, ...props }, ref) => (
    <g
      {...props}
      ref={ref}
      transform={`translate(${x - 11},${y - 11})`}
      pointerEvents="bounding-box"
    >
      <Icon type={type} />
    </g>
  ),
)

export default Circle
